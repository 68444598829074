import React, { useEffect, useState } from "react";
import style from "./Userlogin.module.css";
import forArr from "../../../assets/svg/common/forwardIcon.svg";
import showIcon from "../../../assets/svg/common/show-password.svg";
import hideIcon from "../../../assets/svg/common/hide-password.svg";
import alert from "../../../assets/svg/common/alert-circle.svg";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import { useAuth } from "../../../utils/authcontext/authContext";
import validateEmail from "../../../utils/validations/emailValidation";

function Userlogin() {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

  useEffect(() => {
    if (isAuthenticated) {
      const savedPath = localStorage.getItem("currentPath");
      if (savedPath) {
        navigate(savedPath);
      } else {
      
        const roleCode = localStorage.getItem("roleCode");
        if (roleCode === "GTOP") {
          navigate("/gate-entry");
        } else if (roleCode === "ADMN") {
          navigate("/user-management");
        } else if (roleCode === "QA") {
          navigate("/all-packets");
        } else if (roleCode === "RV") {
          navigate("/rm-inventory");
        }
      }
    }
  }, [isAuthenticated, navigate]);
  const handleEmail = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    let msg = validateEmail(emailValue);
    if (msg) {
      setEmailErrorMsg(msg);
    } else {
      setEmailErrorMsg("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value === "") {
      setPasswordErrorMsg("Required");
    } else {
      setPasswordErrorMsg("");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    let valid = true;
    let msg = validateEmail(email);
    if (msg) {
      setEmailErrorMsg(msg);
      valid = false;
    }
    if (password === "" || !password.trim()) {
      setPasswordErrorMsg("Required");
      valid = false;
    }

    if (!valid) return;
    setIsButtonDisabled(true);
    try {
      const response = await axios.post(`${apiKey}/log-in`, {
        email,
        password,
      });
      const {
        bearerToken,
        email: userEmail,
        roleCode,
        roleName,
        userName,
      } = response.data.data;

      localStorage.setItem("bearerToken", bearerToken);
      localStorage.setItem("userEmail", userEmail);
      localStorage.setItem("roleCode", roleCode);
      localStorage.setItem("roleName", roleName);
      localStorage.setItem("userName", userName);
      login(bearerToken);
      showSuccessToast(response.data.message);
      if (roleCode === "GTOP") {
        navigate("/gate-entry");
      } else if (roleCode === "ADMN") {
        navigate("/user-management");
      }
      window.location.reload();
    } catch (err) {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
      if (
        err?.response?.data?.message ===
        "Invalid credentials. Please check your email and password and try again."
      ) {
        setPasswordErrorMsg("Invalid email or password");
      }
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false); // Re-enable after 2 seconds
      }, 3000);
    }
  };

  return (
    <div className={style.loginCard}>
      <p className={style.heading}>User Login</p>
      <form onSubmit={handleLogin} className={style.form}>
        <div className={style.inputs}>
          <div className={style.email}>
            <label className={style.label}>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className={`${style.input} ${
                emailErrorMsg ? style.errorInput : ""
              }`}
              value={email}
              onChange={handleEmail}
            />
            <div className={style.errorContainer}>
              {emailErrorMsg ? (
                <p className={style.error}>
                  <img src={alert} alt="alert icon" /> {emailErrorMsg}
                </p>
              ) : (
                <p className={style.error}></p>
              )}
            </div>
          </div>
          <div className={style.password}>
            <label className={style.label}>Password</label>
            <div>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter Password"
                className={`${style.input} ${
                  passwordErrorMsg === "Required" ? style.errorInput : ""
                }`}
              />
              <img
                src={passwordVisible ? hideIcon : showIcon}
                alt={passwordVisible ? "Hide Password" : "Show Password"}
                onClick={togglePasswordVisibility}
                onKeyDown={togglePasswordVisibility}
                className={style.toggleIcon}
              />
            </div>
            <div className={style.errorContainer}>
              {passwordErrorMsg ? (
                <p className={style.error}>
                  <img src={alert} alt="alert icon" /> {passwordErrorMsg}
                </p>
              ) : (
                <p className={style.error}></p>
              )}
              <NavLink to="/recovery">
                <p className={style.forgotPassword}>Forgot Password?</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={style.buttons}>
          <button
            onClick={handleLogin}
            className={`${style.loginButton} ${
              isButtonDisabled ? style.disabled : ""
            }`}
            disabled={isButtonDisabled}
          >
            Login <img src={forArr} alt="forward arrow" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default Userlogin;
