import { path } from "../../routes/pathnames.js";
import * as Img from "../../assets/image.js";


// Define user roles and their accessible paths
const roleAccess = {
  ADMN: [path.ums_usermanagement, path.ums_gate_entry,path.all_packets,path.issued_rods,path.part_master,path.rm_master,path.rod_inventory,path.bom], // Admin can access all paths
  GTOP: [path.ums_gate_entry], // Gate Entry Operator can only access Gate Entry
  QA:[path.all_packets,path.issued_rods], // Quality Assurance can only access All Packets
  RV:[path.rod_inventory], // Report Viewer can only access All Packets
};


export const menuItems = [
  // {
  //   path: path.ums_dashboard,
  //   imgSrc: Img.dashboard_white,
  //   imgSrcSelected: Img.dashboard_white,
  //   heading: "Dashboard",
  //   validPaths: [path.ums_dashboard],
  // },
  {
    path: path.ums_usermanagement,
    imgSrc: Img.usermanagement_white,
    imgSrcSelected: Img.usermanagement_red,
    heading: "User Management",
    validPaths: [path.ums_usermanagement],
  },
  {
    path: path.ums_gate_entry,
    imgSrc: Img.gate_entry_white,
    imgSrcSelected: Img.gate_entry_red,
    heading: "Gate Entry",
    validPaths: [path.ums_gate_entry],
  },
  {
    path: path.all_packets,
    imgSrc: Img.allpackets_white,
    imgSrcSelected: Img.allpackets_red,
    heading: "All Packets",
    validPaths: [path.all_packets],
  },
  {
    path: path.issued_rods,
    imgSrc: Img.issuedrods_white,
    imgSrcSelected: Img.issuedrods_red,
    heading: "Issued Rods",
    validPaths: [path.issued_rods],
  },
  {
    path: path.part_master,
    imgSrc: Img.pm_white,
    imgSrcSelected: Img.pm_red,
    heading: "Part Master",
    validPaths: [path.part_master],
  },
  {
    path: path.rm_master,
    imgSrc: Img.rm_white,
    imgSrcSelected: Img.rm_red,
    heading: "RM Master",
    validPaths: [path.rm_master],
  },
  {
    path: path.rod_inventory,
    imgSrc: Img.rod_inventory_white,
    imgSrcSelected: Img.rod_inventory_red,
    heading: "RM Inventory",
    validPaths: [path.rod_inventory],
  },
  {
    path: path.bom,
    imgSrc: Img.bom_white,
    imgSrcSelected: Img.bom_red,
    heading: "BOM",
    validPaths: [path.bom],
  },
];

export const getAccessibleMenuItems = (role) => {
  const accessiblePaths = roleAccess[role] || [];
  return menuItems.filter(menuItem => accessiblePaths.includes(menuItem.path));
};