import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "../pages/login/login";
import ForgotPass from "../pages/forgotPassword/ForgotPass";
import ResetPassword from "../pages/resetPassword/ResetPassword";
import UserManagement from "../pages/userManagement/userManagement/UserManagement";
import { AuthProvider } from "../utils/authcontext/authContext";
import PrivateRoute from "../utils/privateRoutes/PrivateRoutes";
import GateEntry from "../pages/gateEntry/GateEntry";
import QRDetails from "../commonComponent/QRDetails/QRDetails";
import AllPackets from "../pages/allPackets/AllPackets";
import IssuedRods2 from "../pages/IssuedRods/issuedRods2";
import PartMaster from "../pages/masterData/partMaster/PartMaster";
import RmMaster from "../pages/masterData/rmMaster/RmMaster";
import RodInventory from "../pages/rodInventory/RodInventory";
import BOM from "../pages/BOM/bom";

const queryClient = new QueryClient();

const RoutesComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/details-from-qr/:type/:id" element={<QRDetails />} />
            <Route path="/recovery" element={<ForgotPass />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/user-management"
              element={<PrivateRoute element={UserManagement} />}
            />
            <Route
              path="/gate-entry"
              element={<PrivateRoute element={GateEntry} />}
            />
            <Route
              path="/all-packets"
              element={<PrivateRoute element={AllPackets} />}
            />
            <Route
              path="/issued-rods"
              element={<PrivateRoute element={IssuedRods2} />}
            />
            <Route
              path="/part-master"
              element={<PrivateRoute element={PartMaster} />}
            />
            <Route
              path="/rm-master"
              element={<PrivateRoute element={RmMaster} />}
            />
            <Route
              path="/rm-inventory"
              element={<PrivateRoute element={RodInventory} />}
            />
            <Route path="/bom" element={<PrivateRoute element={BOM} />} />
          </Routes>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default RoutesComponent;
