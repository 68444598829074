const customModalStyles = {
  content: {
    position: "fixed", // Changed to 'fixed' for the modal to stay fixed on the screen
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    zIndex: 1000, // Ensure this is higher than other elements
  },
  overlay: {
    position: "fixed", // Make sure the overlay covers the entire screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(5px)", // Adjust the blur level here
    zIndex: 999, // Ensure this is below the modal content but above other elements
  },
  };
  export default customModalStyles;

  export const customModalStyle = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "1134px",   // Set the width
      height: "591px",   // Set the height
      padding: "20px",   // Optional: add some padding for better visual
      overflow: "auto",  // Optional: enable scrolling if content overflows
      borderRadius: "16px",
      scrollbarWidth: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)",
      zIndex:3,
    },
  };
