export const path = {
    ums_usermanagement: "/user-management",
    ums_gate_entry: "/gate-entry",
    all_packets: "/all-packets",
    issued_rods: "/issued-rods",
    part_master: "/part-master",
    rm_master: "/rm-master",
    rod_inventory: "/rm-inventory",
    bom:"/bom",
  };
  