import React from "react";
import style from "./Loader.module.css";
import loaderImg from "../../assets/Loader/LoaderN.svg";

const Loader = () => {
  return (
    <div>
      <img src={loaderImg} alt="loader" className={style.loaderImg} />
      <p className={style.loadingText}>Loading...</p>
    </div>
  );
};

export default Loader;