const baseControlStyle = (provided, state) => ({
  ...provided,
  backgroundColor: "#EFF0F0",
  borderColor: "#EFF0F0",
  border: "0px",
  boxShadow: state.isFocused ? "0 0 0 1px #EFF0F0" : null,
  "&:hover": {
    borderColor: "#EFF0F0",
  },
  fontSize: "14px",
  fontWeight: 500,
  borderRadius: "8px",
});

const baseMenuStyle = (provided) => ({
  ...provided,
  backgroundColor: "#f8f9fa",
  border: "1px solid #ccc",
  borderRadius: "4px",
});

const baseOptionStyle = (provided, state) => {
  let backgroundColor;

  if (state.isSelected) {
    backgroundColor = "#007bff";
  } else if (state.isFocused) {
    backgroundColor = "#e2e6ea";
  } else {
    backgroundColor = null;
  }

  return {
    ...provided,
    backgroundColor,
    color: state.isSelected ? "#fff" : "#000",
    padding: "10px 20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isSelected ? "#0056b3" : "#e2e6ea",
      color: state.isSelected ? "#fff" : "#000",
    },
  };
};
const baseSingleValueStyle = (provided) => ({
  ...provided,
  color: "#495057",
});

const basePlaceholderStyle = (provided) => ({
  ...provided,
  color: "#6c757d",
  fontSize: "14px",
  fontFamily: "roboto",
  fontWeight: 500,
});
 const baseClearIndicator = (provided) => ({
  ...provided,
  padding: "4px",
  cursor:"pointer" // Adjust padding for the clear indicator (cross icon)
});
const baseDropdownIndicator = (provided, state) => ({
  ...provided,
  padding: "4px", // Adjust padding for the dropdown indicator
  display: state.selectProps.menuIsOpen || !state.selectProps.value ? 'block' : 'none', // Hide dropdown indicator when an option is selected
  cursor:"pointer"
});

export const customStyleFormSelect = {
  control: (provided, state) => ({
    ...baseControlStyle(provided, state),
    width: "275px",
    height: "36px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    marginTop: "-10px",
    zIndex: 9999,
  }),
  menu: baseMenuStyle,
  option: baseOptionStyle,
  singleValue: baseSingleValueStyle,
  placeholder: basePlaceholderStyle,
};

export const customStyleSearchSelect = {
  control: (provided, state) => ({
    ...baseControlStyle(provided, state),
    width: "200px",
    height: "40px",
  }),
  menu: baseMenuStyle,
  option: baseOptionStyle,
  singleValue: baseSingleValueStyle,
  placeholder: basePlaceholderStyle,
  menuPortal: (provided) => ({
    ...provided,
    marginTop: "-10px",
    zIndex: 9999,
  }),
  clearIndicator: baseClearIndicator,
  dropdownIndicator: baseDropdownIndicator,
};

export const customStyleTableSelect = {
  control: (provided, state) => ({
    ...baseControlStyle(provided, state),
    width: "300px",
    height: "16px",
    backgroundColor: "transparent",
  }),
  menu: (provided) => ({
    ...baseMenuStyle(provided),
    zIndex: 9999,
    top:"auto"
  }),
  menuPortal: (provided) => ({
    ...provided,
    marginTop: "10px",
    zIndex: 9999, // Ensure the menu is on top of other elements
  }),
  option: baseOptionStyle,
  singleValue: baseSingleValueStyle,
  placeholder: basePlaceholderStyle,
};
