import jsPDF from "jspdf";
import "jspdf-autotable";

export const printAllPacketQR = (qrDataList) => {
    // Initialize jsPDF with custom size (100x100 mm)
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [100, 100]
    });

    qrDataList.forEach((qrData, index) => {
        const imgData = `data:image/png;base64,${qrData.qrLink}`;
        
        // Define the coordinates for QR code and table
        const qrX = 0;
        const qrY = 25;
        const qrWidth = 40; // Adjusted width for 100x100 mm page
        const qrHeight = 40; // Adjusted height for 100x100 mm page

        // Add the QR code image to the PDF
        doc.addImage(imgData, "PNG", qrX, qrY, qrWidth, qrHeight);

        // Define the tabular data
        const tableData = Object.entries(qrData.details).map(([key, value]) => [key, value]);

        // Set the starting position for the table
        const tableX = qrX + qrWidth + 5; // Place the table to the right of the QR code
        let tableY = 20; // Adjust the vertical position as needed

        // Add the company name at the top center of the table
        const companyName = "Cutting Edge Technologies";
        const companyNameX = 47.5; // Center the text
        doc.setFontSize(10);
        doc.setFont("bold");
        doc.text(companyName, companyNameX, tableY - 2); // Adjust position as needed

        // Add the table to the PDF using jspdf-autotable with borders
        doc.autoTable({
            startY: tableY,
            head: [],
            body: tableData,
            theme: "grid", // Use "grid" theme to add borders around the cells
            styles: {
                fontSize: 6 // Reduce font size for table content
            },
            columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 30 }
            },
            margin: { left: tableX }
        });

        // Add a new page if it's not the last item
        if (index < qrDataList.length - 1) {
            doc.addPage();
        }
    });

    // Open the PDF in a new window
    const pdfData = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfData);
    const printWindow = window.open(pdfUrl);

    // Print the PDF
    if (printWindow) {
        printWindow.onload = () => {
            printWindow.print();
        };
    }
};
