import jsPDF from "jspdf";
import "jspdf-autotable";

export const printFromPrinter = (qrLink, qrData) => {
    // Initialize jsPDF in landscape mode
    const doc = new jsPDF('landscape');

    const imgData = `data:image/png;base64,${qrLink}`;
    // Define the coordinates for QR code and table
    const qrX = 10;
    const qrY = 10;
    const qrWidth = 50; // Adjusted width
    const qrHeight = 50; // Adjusted height

    // Add the QR code image to the PDF
    doc.addImage(imgData, "PNG", qrX, qrY, qrWidth, qrHeight);

    // Define the tabular data
    const tableData = qrData?.length > 0 && Object.entries(qrData[0]).map(([key, value]) => [key, value]);

    // Set the starting position for the table
    const tableX = qrX + qrWidth + 20; // Place the table to the right of the QR code
    let tableY = qrY; // Adjust the vertical position as needed

    // Add the company name at the top center of the table
    const companyName = "Cutting Edge Technologies";
    const companyNameX = (doc.internal.pageSize.width - 70) / 2; // Center the text
    doc.setFontSize(14);
    doc.setFont("bold");
    doc.text(companyName, companyNameX, tableY-5); // Adjust position as needed

    // Draw border around the company name
    doc.setLineWidth(0.5);
    doc.rect(tableX, tableY - 20, 150, 10); // Adjust width and height as needed

    // Add the table to the PDF using jspdf-autotable with borders
    doc.autoTable({
        startY: tableY,
        head: [],
        body: tableData,
        theme: "grid", // Use "grid" theme to add borders around the cells
        columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 100 }
        },
        margin: { left: tableX }
    });

    // Open the PDF in a new window
    const pdfData = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfData);
    const printWindow = window.open(pdfUrl);

    // Print the PDF
    if (printWindow) {
        printWindow.onload = () => {
            printWindow.print();
        };
    }
};
