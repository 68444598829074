import RoutesComponent from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();

  return (
      <QueryClientProvider client={queryClient}>
        <RoutesComponent />
        <ToastContainer className="toastContainer" hideProgressBar={true} />
      </QueryClientProvider>
  );
}

export default App;
