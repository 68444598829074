import React, { useState } from "react";
import style from "./editRM.module.css";
import CancelButton from "../../../../commonComponent/Button/CancelButton";
import ColoredButton from "../../../../commonComponent/Button/ColoredButton";
import editIcon from "../../../../assets/svg/common/edit-filled.svg";
import alert from "../../../../assets/svg/common/alert-circle.svg";
import PropTypes from "prop-types";

function EditRM({setEditModal,selectedRm,updateRMMutation}) {
  const [rm,setRm] = useState(selectedRm);
  const [errors, setErrors] = useState({});
  
  const handleCancel = () => {
        setEditModal(false);
    }
    const handleSave = () => {
      let valid = true;
      if (!rm.partGrade.trim()) {
        setErrors({ ...errors, partGrade: "Required" });
        valid = false;
      }
      if (!rm.code) {
        setErrors({ ...errors, code: "Required" });
        valid = false;
      }
      if (!rm.description.trim()) {
        setErrors({ ...errors, description: "Required" });
        valid = false;
      }
      if (!rm.diameter.trim()) {
        setErrors({ ...errors, diameter: "Required" });
        valid = false;
      }
      if (!rm.color.trim()) {
        setErrors({ ...errors, color: "Required" });
        valid = false;
      }

      if (!valid) return;
      updateRMMutation.mutate({...rm,active:true});
      setEditModal(false);
    }

    const handleKeyDown = (e) => {
      // Prevent 'e' from being entered
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "+" ||
        e.key === "-" ||
        e.key === "."
      ) {
        e.preventDefault();
      }
    };
    const handleInputClickRM = (e) => {
      e.target.addEventListener("mousewheel", (ev) => {
        e.target.blur();
      });
    };

    const handleChange = (e,key) => {
      if (!(e.target.value).trim()){
        setRm({...rm,[key]:e.target.value});
        setErrors({...errors,[key]:"Required"});
      }
      else if(key==="code"){
        let code = e.target.value.replace(/[^0-9.-]/g, "");
        let newCode = parseInt(code, 10);
        setRm({ ...rm, code: newCode });
        setErrors({...errors,code:null});
      }
      else{
        setRm({...rm,[key]:e.target.value});
        setErrors({...errors,[key]:null});
      }
    }
  return (
    <div className={style.editPart}>
      <div className={style.header}>
      <div className={style.titleContainer}>
          <img src={editIcon} alt="icon" />
        <p className={style.title}>Edit RM Master</p>
      </div>
      </div>
      <div className={style.editables}>
        <div className={style.editable}>
          <label className={style.label}>RM Code</label>
          <div>
          <input 
          className={`${style.input} ${
            errors.code ? style.errorInput : ""
          }`}
          type="number"
          value={rm?.code}
          onChange={(e)=>handleChange(e,"code")}
          onKeyDown={handleKeyDown}
          onClick={handleInputClickRM}
           />
           {errors.code && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.code}
              </p>
            )}
           </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>RM Description</label>
          <div>
          <input 
          className={`${style.input} ${
            errors.description ? style.errorInput : ""
          }`}
          type="text" 
          value={rm?.description}
          onChange={(e)=>handleChange(e,"description")}
          />
          {errors.description && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.description}
              </p>
            )}
          </div>
        </div>
      <div className={style.editables}>
        <div className={style.editable}>
          <label className={style.label}>RM Grade</label>
          <div>
          <input className={`${style.input} ${
                errors.partGrade ? style.errorInput : ""
              }`} 
          type="text"
          value={rm?.partGrade} 
          onChange={(e)=>handleChange(e,"partGrade")}
          />
          {errors.partGrade && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.partGrade}
              </p>
            )}
            </div>
        </div>
      </div>
        <div className={style.editable}>
          <label className={style.label}>RM Diameter</label>
          <div>
          <input 
          className={`${style.input} ${
            errors.diameter ? style.errorInput : ""
          }`}
           type="text" 
          value={rm?.diameter}
          onChange={(e)=>handleChange(e,"diameter")}
          />
          {errors.diameter && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.diameter}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>RM Color</label>
          <div>
          <input  
          className={`${style.input} ${
            errors.color ? style.errorInput : ""
          }`}
          type="text" 
          value={rm?.color}
          onChange={(e)=>handleChange(e,"color")}
          />
          {errors.color && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.color}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={style.action}>
        <CancelButton
          buttonName="Cancel"
          onClick={handleCancel}
        />
        <ColoredButton
          buttonName="Save"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}
EditRM.propTypes = {
  setEditModal: PropTypes.func.isRequired,
  selectedRm: PropTypes.object.isRequired,
  updateRMMutation: PropTypes.object.isRequired,
};
export default EditRM;
