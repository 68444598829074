import React from 'react'
import style from './RejectionComment.module.css'
import PropTypes from 'prop-types'

function ViewRejectionComment({setShowRejectionComment,comment}) {
    const handleClose = () => {
        setShowRejectionComment(false)
    };
    
  return (
    <div className={style.modalContent}>
                <p className={style.title}>Reason for Rejection</p>
                <textarea
                    value={comment}
                    placeholder="No Reason Provided"
                    className={style.textarea}
                    disabled
                />
                <div className={style.buttonContainer}>
                    <button className={style.submitButton} onClick={handleClose}>Close</button>
                </div>
            </div>
  )
}
ViewRejectionComment.propTypes = {
    setShowRejectionComment: PropTypes.func.isRequired,
    comment: PropTypes.string.isRequired,
}

export default ViewRejectionComment
