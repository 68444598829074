import React, { useEffect, useState } from "react";
import Sidebar from "../../commonComponent/sidebar/Sidebar";
import searchIcon from "../../assets/svg/common/serach-icon.svg";
import style from "./bom.module.css";
import {
  getPartGrade,
  getRawMaterialList,
  getRmSize,
  getToken,
  handle401Error,
} from "../../utils/api/api";
import { useQuery } from "react-query";
import Pagination from "../../commonComponent/Pagination/Pagination";
import { customStyleSearchSelect } from "../../utils/styles/react-select/customSelect";
import Select, { components } from "react-select";
import Loader from "../../commonComponent/CommonLoader/Loader";
import { debounce } from "../../utils/Debounce/debounce";
import previewIcon from "../../assets/svg/common/preview-icon.svg";
import ColoredButton from "../../commonComponent/Button/ColoredButton";
import axios from "axios";
import { formatColumnDate } from "../../utils/functions/utils";
import CustomExcelFileDownload from "../../utils/excelDownload/ExcelDownload";
import downloadIcon from "../../assets/svg/common/download_icon_white.svg";
import ReactModal from "react-modal";
import { customModalStyle } from "../../utils/styles/react-modal/reactModalCustomStyle";
import ViewBOM from "./ViewBom/viewBom";
import { showErrorToast } from "../../utils/notify";

function BOM() {
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rawMaterialCount, setRawMaterialCount] = useState(0);
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [rmSizeOptions, setRmSizeOptions] = useState([]);
  const [selectedPartGrade, setSelectedPartGrade] = useState(null);
  const [selectedRmSize, setSelectedRmSize] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rawMaterial, setRawMaterial] = useState(null);

  // const { data: rodsData, isLoading } = useQuery(
  //   ["rodList", searchQuery, currentPage, selectedPartGrade, selectedRmSize],
  //   () =>
  //     getRodList(
  //       searchQuery,
  //       currentPage,
  //       10,
  //       selectedPartGrade?.value,
  //       selectedRmSize?.value
  //     )
  // );
  const { data:rawMaterialData, refetch, isLoading } = useQuery(
    ["rMasterList", currentPage,searchQuery],
    () => getRawMaterialList(searchQuery, currentPage, 10),
    {
      retry: false,
      onError: (error) => {
        showErrorToast("Something went wrong.");
      },
    }
  );
  const { data: partGradeResponse, status: partGradeStatus } = useQuery(
    "part-grade",
    getPartGrade
  );
  const { data: rmSizeResponse, status: rmSizeStatus } = useQuery(
    "rm-size",
    getRmSize
  );
  useEffect(() => {
    if (partGradeStatus === "success" && partGradeResponse?.data) {
      const transformedOptions = partGradeResponse.data.map((grade) => ({
        value: grade,
        label: grade,
      }));
      setPartGradeOptions(transformedOptions);
    }
  }, [partGradeResponse, partGradeStatus]);
  useEffect(() => {
    if (rmSizeStatus === "success" && rmSizeResponse?.data) {
      const transformedOptions = rmSizeResponse.data.map((size) => ({
        value: size,
        label: size,
      }));
      setRmSizeOptions(transformedOptions);
    }
  }, [rmSizeResponse, rmSizeStatus]);

  useEffect(() => {
    if (rawMaterialData?.data?.records) {
      setRawMaterialList(rawMaterialData.data.records);
      setRawMaterialCount(rawMaterialData.data.recordsCount);
    }
  }, [rawMaterialData]);

  const handleSearch = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1);
  };
  const handleSearchDebounce = debounce(handleSearch, 300);

  const CustomDropdownIndicator = (props) => {
    if (props.selectProps.value) {
      return null;
    }
    return <components.DropdownIndicator {...props} />;
  };

  // Downloading Excel File
  const getSheetData = async () => {
    const token = getToken();
    let url = `/bom-export?searchKeyword=`;
    try {
      const response = await axios.get(`${apiKey}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      handle401Error(error);
      throw error;
    }
  };

  const handleBomDownload = async () => {
    const data = await getSheetData();
    const records = data?.data;
    const sheetData = records?.flatMap((item) => {
      if (item.rawMaterial.countOfPart === 0) {
        return [
          [
            `${item.rawMaterial.code}`,
            `${item.rawMaterial.partGrade}`,
            `${item.rawMaterial.diameter}`,
            `${item.rawMaterial.description}`,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ];
      } else {
        return item.associatedParts.map((subItem) => {
          return [
            `${item.rawMaterial.code}`,
            `${item.rawMaterial.partGrade}`,
            `${item.rawMaterial.diameter}`,
            `${item.rawMaterial.description}`,
            `${subItem.partDescription}`,
            `${subItem.sapPartNo}`,
            `${subItem.cuttingLength}`,
            `${subItem.machineId}`,
            `${subItem.perPieceWeightKg}`,
            `${subItem.plantId}`,
            `${subItem.binTareWt}`,
          ];
        });
      }
    });
    const sheetHeader = [
      "RM Code",
      "RM Grade",
      "RM Size",
      "RM Description",
      "Part Description",
      "Part No.",
      "Cutting Length",
      "Machine Id",
      "Per Piece Weight (Kg)",
      "Plant Id",
      "Bin Tare Wt.",
    ];
    const fileNameLabel = "BOM Data";
    
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };

  //
  const handlePreview = (rm) => {
    setRawMaterial(rm);
    setOpenModal(true);
  };

  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>RM vs Part BOM</p>
          <div className={style.downloadBtn}>
            <ColoredButton
              buttonName={"Download"}
              width={"120px"}
              weight={"400"}
              onClick={() => {
                handleBomDownload();
              }}
              img={downloadIcon}
            />
          </div>

        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.filters}>
              <div className={style.inputBox}>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className={style.searchInput}
                  onChange={handleSearchDebounce}
                  placeholder="Search"
                  // value={searchQuery}
                />
                <span className={style.imgContainer}>
                  <img src={searchIcon} alt="search-icon" />
                </span>
              </div>
              {/* <Select
                value={selectedPartGrade}
                onChange={(selectedOption) => {
                  setSelectedPartGrade(selectedOption);
                  setCurrentPage(1);
                }}
                options={partGradeOptions}
                placeholder="RM Grade"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <Select
                value={selectedRmSize}
                onChange={(selectedOption) => {
                  setSelectedRmSize(selectedOption);
                  setCurrentPage(1);
                }}
                options={rmSizeOptions}
                placeholder="RM Size"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              /> */}
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddleP}>
              <div className={style.bodyMiddle}>
                <div className={style.table}>
                  <div className={style.thead}>
                    <div className={style.thr}>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Code
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Grade
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Size
                      </div>
                      <div className={style.th} style={{ width: "16rem" }}>
                        RM Description
                      </div>
                      <div className={style.th} style={{ width: "12rem" }}>
                        Number Of Part(s)
                      </div>
                    </div>
                  </div>

                  {/* <SimpleBar style={{ maxHeight: "56vh" }}> */}
                  <div className={style.tbody}>
                    {rawMaterialList?.length > 0 ? (
                      rawMaterialList?.map((rod) => {
                        return (
                          <div className={style.tr} key={rod.id}>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.code}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.partGrade}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.diameter}
                            </div>
                            <div
                              className={style.td}
                              style={{ width: "16rem" }}
                            >
                              {rod.description}
                            </div>
                            <div
                              className={style.td}
                              style={{ width: "12rem" }}
                            >
                              <span
                                onClick={()=>handlePreview(rod)}
                                onKeyDown={()=>handlePreview(rod)}
                                className={style.number}
                              >
                                {rod.countOfPart}
                              </span>{" "} parts mapped
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={style.noUser}>No Data Found</div>
                    )}
                  </div>
                  {/* </SimpleBar> */}
                </div>
              </div>
              <div className={style.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(rawMaterialCount / 10)}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        className={style.container}
        isOpen={openModal}
        center
        showCloseIcon={true}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <ViewBOM
          rawMaterial={rawMaterial}
          setOpenModal={setOpenModal}
          refetch={refetch}
        />
      </ReactModal>
    </div>
  );
}

export default BOM;
