import React from 'react'
import Leftside from '../../commonComponent/login/leftSide/Leftside'
import style from "./ForgotPass.module.css";
import Recovery from './recovery/recovery';
import pic2 from "../../assets/svg/common/logout-screen-image.svg"

function ForgotPass () {
  return (
    <div className={style.loginParent}>
      <Leftside pic2={pic2}/>
      <Recovery />
    </div>
  )
}

export default ForgotPass;