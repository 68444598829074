import React, { useState } from 'react'
import style from './RejectionComment.module.css'
import PropTypes from 'prop-types'

function RejectionComment({setShowRejectionModal,onSubmit}) {
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');

    const handleCancel = () => {
        setShowRejectionModal(false);
    };
    const handleSubmit = () => {
        if(reason.trim().length < 20){
            setError("Reason should be atleast 20 characters long");
            return;
        }
        onSubmit(reason);
        setReason('');
    };
    
  return (
    <div className={style.modalContent}>
                <p className={style.title}>Reason for Rejection</p>
                <div>
                <textarea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter reason here..."
                    className={`${style.textarea} ${error ? style.error : ""}`}
                />
                {error && <p className={style.errorText}>{error}</p>}
                </div>
                <div className={style.buttonContainer}>
                    <button className={style.cancelButton}  onClick={handleCancel}>Cancel</button>
                    <button className={style.submitButton} onClick={handleSubmit}>Submit</button>
                </div>
            </div>
  )
}
RejectionComment.propTypes = {
    setShowRejectionModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default RejectionComment
