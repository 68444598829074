import React, { useEffect, useState } from "react";
import style from "./commonModal.module.css";
import ReactModal from "react-modal";
import { getRoleList } from "../../utils/api/api";
import { useQuery } from "react-query";
import Select from "react-select";
import { customStyleFormSelect } from "../../utils/styles/react-select/customSelect";
import alert from "../../assets/svg/common/alert-circle.svg";
import validateEmail from "../../utils/validations/emailValidation";
import PropTypes from "prop-types";

const customStyles = {
  content: {
    position: "absolute", // 'absolute' to 'fixed' for modal to be fixed on screen
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(5px)", // Adjust the blur level here
  },
};

function CommonModal({
  showModal,
  setShowModal,
  icon,
  title,
  buttonName,
  handleButton2,
  user,
  setUser,
}) {
  const customStyleSelect = (hasError) => ({
    ...customStyleFormSelect,
    control: (provided, state) => ({
      ...customStyleFormSelect.control(provided, state),
      width: "248px",
      height: "40px", // Example width, change to desired value
      border: hasError ? "1px solid red" : provided.border,
      "&:hover": {
        borderColor: hasError ? "red" : provided.borderColor,
      },
    }),
  });

  const [roleOptions, setRoleOptions] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);
  const [errors, setErrors] = useState({});
  const nameRegex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces

  useEffect(() => {
    if (user) {
      setName(user.userName || "");
      setEmail(user.email || "");
      setRole(
        user.roleCode ? { value: user.roleCode, label: user.roleName } : null
      );
    } else {
      setName("");
      setEmail("");
      setRole(null);
    }
    setErrors({});
  }, [user, showModal]);
  const resetStates = () => {
    setName(user ? user.userName : "");
    setEmail(user ? user.email : "");
    setRole(user ? { value: user.roleCode, label: user.roleName } : {});
    setErrors({});
    setUser(null);
  };
  const handleNameChange = (e) => {
    const nameValue = e.target.value;

    if (!nameValue || nameValue.trim() === "") {
      setErrors((prev) => ({ ...prev, name: "Required" }));
    } else if (!nameRegex.test(nameValue)) {
      setErrors((prev) => ({
        ...prev,
        name: "Name can only contain letters and spaces",
      }));
    } else {
      setErrors((prev) => ({ ...prev, name: null }));
    }
    setName(nameValue);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    let msg = validateEmail(emailValue);
    if (msg) {
      setErrors((prev) => ({ ...prev, email: msg }));
    } else {
      setErrors((prev) => ({ ...prev, email: null }));
    }
  };

  const handleSelectRole = (selectedOption) => {
    setRole(selectedOption);
    if (!selectedOption) {
      setErrors((prev) => ({ ...prev, role: "Required" }));
    } else {
      setErrors((prev) => ({ ...prev, role: null }));
    }
  };
  const handleAdd = () => {
    let valid = true;
    if (!name || name.trim() === "") {
      setErrors((prev) => ({ ...prev, name: "Required" }));
      valid = false;
    }
    if (!role?.value) {
      setErrors((prev) => ({ ...prev, role: "Required" }));
      valid = false;
    }
    let msg = validateEmail(email);
    if (msg) {
      setErrors((prev) => ({ ...prev, email: msg }));
      valid = false;
    }
    if (!nameRegex.test(name)) {
      setErrors((prev) => ({
        ...prev,
        name: "Name can only contain letters and spaces",
      }));
      valid = false;
    }
    if (valid === false) return;
    if (
      name &&
      email &&
      role?.value &&
      buttonName === "Add" &&
      email.length <= 50
    ) {
      handleButton2(name, email, role);
      resetStates();
    }
    if (
      name &&
      email &&
      role?.value &&
      buttonName === "Save" &&
      email.length <= 50
    ) {
      handleButton2(name, email, role);
      resetStates();
    }
  };
  const { data: roleList } = useQuery("roleList", () => getRoleList(), {
    retry: false,
  });
  useEffect(() => {
    setRoleOptions(
      roleList?.data?.map((role) => ({ value: role.code, label: role.name }))
    );
    return () => {};
  }, [roleList?.data]);
  const handleCancel = () => {
    setShowModal(false);
    resetStates();
  };
  return (
    <ReactModal
      className={style.container}
      isOpen={showModal}
      center
      showCloseIcon={true}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      onRequestClose={() => {
        setShowModal(false);
        resetStates();
      }}
    >
      <div className={style.titleContainer}>
          <img src={icon} alt="icon" />
        <p className={style.title}>{title}</p>
      </div>
      <div className={style.fields}>
        <div>
          <div className={style.field}>
            <label className={style.label}>Name</label>
            <input
              type="text"
              className={`${errors.name ? style.errorInput : ""} ${
                style.input
              } `}
              placeholder="Enter Name"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          {errors.name && (
            <p className={style.error}>
              <img src={alert} alt="alert-icon" /> {errors.name}
            </p>
          )}
        </div>
        <div>
          <div className={style.field}>
            <label className={style.label}>Email</label>
            <input
              value={email}
              type="email"
              className={`${style.input} ${
                errors.email ? style.errorInput : ""
              }`}
              placeholder="Enter Email"
              onChange={handleEmailChange}
              readOnly={user}
            />
          </div>
          {errors.email && (
            <p className={style.error}>
              <img src={alert} alt="alert-icon" /> {errors.email}
            </p>
          )}
        </div>
        <div>
          <div className={style.field} style={{ gap: "35px" }}>
            <label className={style.label}>Role</label>
            <Select
              value={role}
              onChange={handleSelectRole}
              placeholder="Select Role"
              options={roleOptions}
              styles={customStyleSelect(!!errors.role)}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPortalTarget={document.body}
              menuPosition="absolute"
            />
          </div>
          {errors.role && (
            <p className={style.error}>
              <img src={alert} alt="alert-icon" /> {errors.role}
            </p>
          )}
        </div>
      </div>
      <div className={style.buttons}>
        <button className={style.button1} onClick={handleCancel}>
          Cancel
        </button>
        <button className={style.button2} onClick={handleAdd}>
          {buttonName}
        </button>
      </div>
    </ReactModal>
  );
}

CommonModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  handleButton2: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userName: PropTypes.string,
    email: PropTypes.string,
    roleCode: PropTypes.string,
    roleName: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
};

export default CommonModal;
