import jsPDF from "jspdf";
import "jspdf-autotable";

export const printAllRodQR = (qrDataList) => {
    // Initialize jsPDF with custom size (100x100 mm)
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [38, 20]
    });

    qrDataList.forEach((qrData, index) => {
        const imgData = `data:image/png;base64,${qrData.qrLink}`;
        
        // Define the coordinates for QR code and table
        const qrX = 0;
        const qrY = 0;
        const qrWidth = 14; // Adjusted width for 100x100 mm page
        const qrHeight = 14; // Adjusted height for 100x100 mm page

        // Add the QR code image to the PDF
        doc.addImage(imgData, "PNG", qrX, qrY, qrWidth, qrHeight);

        // Define the tabular data
        const tableData = Object.entries(qrData.details).map(([key, value]) => [key, value]);

        // Set the starting position for the table
        const tableX = qrX + qrWidth + 2; // Place the table to the right of the QR code
        let tableY = 2; // Adjust the vertical position as needed

        // Add the company name at the top center of the table
        const companyName = "Cutting Edge Technologies";
        const companyNameX = qrX+qrWidth+2; // Center the text
        doc.setFontSize(3);
        doc.setFont("bold");
        doc.text(companyName, companyNameX, tableY - 0.5); // Adjust position as needed

        // Add the table to the PDF using jspdf-autotable with borders
        doc.autoTable({
            startY: tableY,
            head: [],
            body: tableData,
            theme: "grid", // Use "grid" theme to add borders around the cells
            styles: {
                fontSize: 2, // Reduce font size for table content
                cellPadding: 0.1// Reduce cell padding to fit more content
            },
            columnStyles: {
                0: { cellWidth: 7 },
                1: { cellWidth: 13 }
            },
            margin: { left: tableX },
            pageBreak: 'auto', // Automatically handle page breaks
            rowPageBreak: 'auto', // Automatically handle row page breaks
            tableWidth: 'auto' // Auto adjust table width
        });

        // Add a new page if it's not the last item
        if (index < qrDataList.length - 1) {
            doc.addPage();
        }
    });

    // Open the PDF in a new window
    const pdfData = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfData);
    const printWindow = window.open(pdfUrl);

    // Print the PDF
    if (printWindow) {
        printWindow.onload = () => {
            printWindow.print();
        };
    }
};
