import React, { useState } from "react";
import styles from "./IssueRods.module.css";
import { issueRod } from "../../../utils/api/api";
import { useMutation } from "react-query";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/svg/common/plus_blue.svg";
import minusIcon from "../../../assets/svg/common/minus_blue.svg";
import { printAllRodQR } from "../../../utils/Print/printAllRodQr";
import PropTypes from "prop-types";
const IssueModal = ({
  packetId,
  totalRods,
  setShowIssueRodModal,
  refetchPacketsData,
  issuedRods,
}) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [error, setError] = useState("");

  const handleDecrement = () => {
    if (count > 1) setCount(count - 1);
  };

  const handleIncrement = () => {
    if (count < (totalRods-issuedRods)) setCount(count + 1);
  };

  //  API Integration
  const issueRodMutation = useMutation(issueRod, {
    onMutate: () => {},
    onSuccess: (res) => {
    let qrDataList = [];
    res?.data?.rods.forEach((rod) => {
      qrDataList.push({
        qrLink: rod?.qrLink,
        details: {
          "Rod Id": rod?.rodId,
          "RM Grade": res.data?.partGrade,
          "RM Size": res.data?.size,
        },
      });
    });
    printAllRodQR(qrDataList);
      showSuccessToast(res.message);
      refetchPacketsData();
      setShowIssueRodModal(false);
      navigate("/issued-rods");
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });
  const handleIssue = () => {
    if (count > totalRods - issuedRods || count < 1 || !count) {
      setError(
        "Enter a valid quantity"
      );
      return;
    }
    issueRodMutation.mutate({ packetId, numberOfRods: count });
  };
  const handleCountChange = (e) => {
    let count = e.target.value.replace(/[^0-9.-]/g, "");
    let newCount = parseInt(count, 10);
    if (newCount) setError("");
    setCount(newCount);
  };
  return (
    <div className={styles.issueRodsContainer}>
      <p className={styles.title}>Issue Rod</p>
      <div className={styles.issueRod}>
        <p className={styles.para}>Rods available in packet</p>
        <div>
            <div className={styles.input2}>
                {totalRods - issuedRods}
            </div>
            </div>
      </div>
      {((totalRods-issuedRods)>0) && <div className={styles.issueRod}>
        <p className={styles.para}>Rods to be Issued</p>
        <div className={`${styles.counter} ${error ? styles.error : ""}`}>
          <button className={styles.button} onClick={handleDecrement}>
            <img src={minusIcon} alt="minus-icon" />
          </button>
          {/* <span className={styles.count}>{count}</span> */}
          <span className={`${styles.count}`}>
            <input
              type="number"
              value={count}
              onChange={handleCountChange}
              className={styles.input}
            />
          </span>
          <button className={styles.button} onClick={handleIncrement}>
            <img src={plusIcon} alt="" />
          </button>
        </div>
        <button className={styles.issueButton} onClick={handleIssue}>
          Issue
        </button>
      </div>}
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

IssueModal.propTypes = {
  packetId: PropTypes.string.isRequired,
  totalRods: PropTypes.number.isRequired,
  setShowIssueRodModal: PropTypes.func.isRequired,
  refetchPacketsData: PropTypes.func.isRequired,
  issuedRods: PropTypes.number.isRequired,
};

export default IssueModal;
