import React, { useState } from "react";
import style from "./recovery.module.css";
import forArr from "../../../assets/svg/common/forwardIcon.svg";
import alert from "../../../assets/svg/common/alert-circle.svg";
import forArrRed from "../../../assets/svg/common/forwardIconRed.svg";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import { requestResetPassword } from "../../../utils/api/api";
import { useMutation } from "react-query";
import validateEmail from "../../../utils/validations/emailValidation";
function Recovery() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 
  const navigate = useNavigate();

  const resetPasswordMutation = useMutation(requestResetPassword, {
    onMutate: () => {},
    onSuccess: (res) => {
      showSuccessToast("Reset link has been sent!");
      navigate("/");
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
      if (err?.response?.data?.message === "User not found with this id.") {
        setErrors({ email: "Please enter a registered email" });
      }
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    },
  });

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    let msg = validateEmail(emailValue);
    if (msg) {
      setErrors((prev) => ({ ...prev, email: msg }));
    }
    else {
      setErrors((prev) => ({ ...prev, email: null }));
    }
  };
  const handleSubmit = () => {
    let msg = validateEmail(email);
    if (msg) {
      setErrors((prev) => ({ ...prev, email: msg }));
      return;
    }
    setIsButtonDisabled(true);
    resetPasswordMutation.mutate(email);
  };
  const handleBack = () => {
    navigate("/");
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      e.preventDefault();  // Prevent form submission
      handleSubmit();
    }
  };
  return (
    <div className={style.loginCard}>
      <div className={style.upperSection}>
        <p className={style.heading}>Forgot Password?</p>
        <p className={style.line}>
          Enter your email to receive a password reset link
        </p>
      </div>
      <div className={style.inputs}>
        <div className={style.email}>
          <label className={style.label}>Email</label>
          <input
            type="email"
            placeholder="Enter your email"
            className={`${style.input} ${
              errors?.email ? style.errorInput : ""
            }`}
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
          />
          <div className={style.errorConatiner}>
            {errors.email && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon"/> {errors.email}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <button className={style.submitButton} onClick={handleSubmit}
        disabled={isButtonDisabled}
        >
          Submit <img src={forArr} alt="forward-arrow" />
        </button>
        <button className={style.backButton} onClick={handleBack}
                
                >
          <img
            src={forArrRed}
            style={{ transform: "rotate(180deg)" }}
            alt="back-arrow"
          />{" "}
          Back To Login
        </button>
      </div>
    </div>
  );
}

export default Recovery;
