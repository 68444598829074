import React, { useState } from "react";
import style from "./Sidebar.module.css";
import { getAccessibleMenuItems } from "./menu";
import victoraLogo from "../../assets/svg/common/victora_logo2.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logoutIconWhite from "../../assets/svg/common/logout-white.svg";
import logoutIconRed from "../../assets/svg/common/logout-red.svg";
import cookie from "react-cookies";
import { useAuth } from "../../utils/authcontext/authContext";
import ReactModal from "react-modal";
import logoutStyle from "./logout.module.css";
import customModalStyles from "../../utils/styles/react-modal/reactModalCustomStyle";
function Sidebar() {
  const location = useLocation();
  const { logout } = useAuth();
  const [buttonHover, setButtonHover] = useState(false);
  const [logoutHover, setLogoutHover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const isActiveMenuItem = (validPaths) => {
    return validPaths.some((path) => location.pathname.includes(path));
  };
  const handleLogout = () => {
    logout();
    cookie.remove("token");
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
  const handleLogoutButton = () => {
    setShowModal(true);
  };
  const handleStay = () => {
    setShowModal(false);
  };
  let userRole = localStorage.getItem("roleCode");
  let menus = getAccessibleMenuItems(userRole) || [];

  return (
    <div className={style.leftParent}>
      <div className={style.leftSection}>
        <img
          alt="DashBoardImage"
          src={victoraLogo}
          className={style.dashboardImage}
          // onClick={() => history.push("/dashboard")}
        />

        <div className={style.side_buttons_div}>
          {menus?.map((i, index) => {
            return (
              <NavLink
                to={i.path}
                style={{ textDecoration: "none" }}
                key={i.heading}
              >
                <div
                  className={
                    // check if path matches or index match to hover one
                    isActiveMenuItem(i.validPaths) || buttonHover === index
                      ? style.active_btn
                      : style.deactivate_btn
                  }
                  // set index on hover
                  onClick={() => localStorage.setItem("currentPath", i.path)}
                  onKeyDown={() => localStorage.setItem("currentPath", i.path)}
                  onMouseEnter={() => setButtonHover(index)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  <img
                    src={
                      isActiveMenuItem(i.validPaths) || buttonHover === index
                        ? i.imgSrcSelected
                        : i.imgSrc
                    }
                    alt={i.heading}
                    className={style.icon}
                  />
                  <div
                    className={style.heading}
                    dangerouslySetInnerHTML={{ __html: i.heading }}
                  />
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
      <div
        className={logoutHover ? style.logoutActive : style.logoutInactive}
        onMouseEnter={() => setLogoutHover(true)}
        onMouseLeave={() => setLogoutHover(false)}
        onClick={handleLogoutButton}
        onKeyDown={handleLogoutButton}
        style={{ cursor: "pointer" }}
      >
        <img
          src={logoutHover ? logoutIconRed : logoutIconWhite}
          alt="logout-icon"
          className={style.icon}
        />
        <div className={style.heading}>Logout</div>
      </div>
      <ReactModal
        className={logoutStyle.container}
        isOpen={showModal}
        center
        showCloseIcon={true}
        style={customModalStyles}
        onRequestClose={() => setShowModal(false)}
      >
        <div className={logoutStyle.titleContainer}>
          {/* <span>
            <img src={} alt="icon" />
          </span> */}
          <p className={logoutStyle.title}>Logout ?</p>
        </div>
        <div>
          <p className={logoutStyle.confirmText}>
            Are you sure you want to logout?
          </p>
        </div>
        <div className={logoutStyle.buttons}>
          <button className={logoutStyle.button1} onClick={handleStay}>
            Stay
          </button>
          <button
            className={logoutStyle.button2}
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        </div>
      </ReactModal>
    </div>
  );
}

export default Sidebar;
