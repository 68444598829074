import React, { useEffect, useState } from "react";
import style from "./ProductDescription.module.css";
import print from "../../../assets/svg/common/print-icon.svg";
import view from "../../../assets/svg/common/view-qr.svg";
import { useQuery } from "react-query";
import { getGateEntryDetails } from "../../../utils/api/api";
import ReactModal from "react-modal";
import PreviewQR from "../../previewQR/PreviewQR";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { printPacketQR } from "../../../utils/Print/printPacketQr";
import { printAllPacketQR } from "../../../utils/Print/printAllPacketQr";

function ProductDescription2({ id }) {
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: 5,
    },
  };
  const [lineItems, setLineItems] = useState([]);
  const [viewQRModal, setViewQRModal] = useState(false);
  const [currentQR, setCurrentQR] = useState(null);
  const [packetId, setPacketId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [pre, setPre] = useState({});

  const { data: gateEntryDetails } = useQuery(
    "getGateEntryDetails",
    () => getGateEntryDetails(id),
    {
      retry: false,
      enabled: id !== undefined && id !== null,
    }
  );
  useEffect(() => {
    setLineItems(gateEntryDetails?.data?.lineItems);
    setPre({
      gateEntryDate: gateEntryDetails?.data?.gateEntryDate
        ? moment(gateEntryDetails?.data?.gateEntryDate).format("DD.MM.YYYY")
        : "",
      gateEntryNo: gateEntryDetails?.data?.gateEntryNumber,
    });
    if (gateEntryDetails?.data?.lineItems?.length > 0) {
      let visible = gateEntryDetails.data.lineItems[0].numberOfPackets > 0;
      setVisible(visible);
    }
    return () => {};
  }, [gateEntryDetails?.data]);

  // Function to print all QR codes and details
  const printAllQRs = () => {
    const qrDataList = [];
    lineItems?.forEach((item) => {
      item?.packetResponseList?.forEach((i) => {
        qrDataList.push({
          qrLink: i?.qrLink,
          details: {
            "VIctora Bundle Id": item?.bundleId,
            "RM Code": item?.description?.rmCode,
            "Gate Entry No": pre?.gateEntryNo,
            "Gate Entry Dt.": pre?.gateEntryDate,
            "Heat No.": item?.description?.heatNumber,
            "RM Grade": item?.description?.partGrade,
            "RM Size": item?.size,
            "No of of Rods ": item?.rodsPerPacket,
          },
        });
      });
    });

    // Call the print function with all QR data
    printAllPacketQR(qrDataList);
  };

  return (
    <>
      {visible ? (
        <div className={style.descriptionParent}>
          <div className={style.lineHeader}>
            <p className={style.lineHeading}>Line Details</p>
            <p
              className={`${style.printAll}`}
              onClick={printAllQRs}
              onKeyDown={printAllQRs}
            >
              Print <img src={print} alt="print-icon" />
            </p>
          </div>
          <div className={style.tableContainer}>
            {lineItems?.map((item) => {
              let qrData = [
                {
                  "Victora Bundle ID": item?.bundleId,
                  "RM.Code": item?.description?.rmCode,
                  "Gate Entry No": pre?.gateEntryNo,
                  "Gate Entry Dt.": pre?.gateEntryDate,
                  "Heat No.": item?.description?.heatNumber,
                  "RM. Grade": item?.description?.partGrade,
                  "RM. Size": item.size,
                  "No of of Rods": item?.rodsPerPacket,
                },
              ];
              return (
                <div key={item?.id}>
                  <div className={style.tableHeader}>
                    <p className={style.tableHeading}>Line Item #{item?.id}</p>
                  </div>
                  <div className={style.table}>
                    <div className={style.thead}>
                      <div className={style.thRow}>
                        <p className={style.th}>Packet ID</p>
                        <p className={style.th}>Description</p>
                        <p className={style.th}>Action</p>
                      </div>
                    </div>
                    <div className={style.tbody}>
                      {item?.packetResponseList?.map((i) => (
                        <div className={style.tdRow} key={i?.packetId}>
                          <p className={style.td}>{i?.packetId}</p>
                          <p className={style.td}>{`RM Code: ${
                            item?.description?.rmCode
                          } | RM Description: ${
                            item?.description?.rmDescription
                          } | RM Grade: ${
                            item?.description?.partGrade
                          } | Color: ${item?.color} | No of Rods: ${
                            item?.rodsPerPacket
                          } | Total Weight: ${Number(
                            item?.description?.totalWeight
                          )} | Heat Grade: ${
                            item?.description?.heatNumber
                          } | Bundle Id: ${
                            item?.bundleId ? item.bundleId : "-"
                          }`}</p>
                          <div className={style.td}>
                            <div className={style.qrContainer}>
                              <img
                                src={`data:image/png;base64,${i?.qrLink}`}
                                alt="QR Code"
                                className={style.qrImage}
                                height="122px"
                                width="122px"
                              />
                              <div className={style.qrActions}>
                                <div className={style.viewNprint}>
                                  <img
                                    onClick={() =>
                                      printPacketQR(i?.qrLink, qrData)
                                    }
                                    onKeyDown={() =>
                                      printPacketQR(i?.qrLink, qrData)
                                    }
                                    src={print}
                                    alt="print-qr"
                                    className={style.printIcon}
                                  />
                                  <img
                                    src={view}
                                    alt="view-qr"
                                    className={style.viewIcon}
                                    onClick={() => {
                                      setViewQRModal(true);
                                      setCurrentQR(i?.qrLink);
                                      setPacketId(i?.packetId);
                                    }}
                                    onKeyDown={() => {
                                      setViewQRModal(true);
                                      setCurrentQR(i?.qrLink);
                                      setPacketId(i?.packetId);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ReactModal
                    // className={style.previewQR}
                    isOpen={viewQRModal}
                    showCloseIcon={true}
                    style={customStyles}
                    onRequestClose={() => {
                      setViewQRModal(false);
                    }}
                  >
                    <PreviewQR
                      setViewQRModal={setViewQRModal}
                      qrLink={currentQR}
                      id={id}
                      packetID={packetId}
                    />
                  </ReactModal>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

ProductDescription2.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProductDescription2;
