import React, { useEffect, useState} from "react";
import style from "./viewBom.module.css";
import { useMutation, useQuery } from "react-query";
import {
  getPartsByRM,
  getPartDescription,
  getPartsByDescription,
  rawMaterialPartMapping,
} from "../../../utils/api/api";
import bluePlusIcon from "../../../assets/svg/common/plus-blueBg.svg";
import deleteIcon from "../../../assets/svg/common/delete-line-entries.svg";
import deleteIcon2 from "../../../assets/svg/common/delete-fill.svg";
import ConfirmPopup2 from "../../../commonComponent/confirmPopup/ConfirmPopup2";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import Select from "react-select";
import { customStyleTableSelect } from "../../../utils/styles/react-select/customSelect";
import PropTypes from "prop-types";
import ColoredButton from "../../../commonComponent/Button/ColoredButton";

const ViewBOM = ({ rawMaterial,setOpenModal,refetch }) => {
  const [tableRows, setTableRows] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [partDescriptionOptions, setPartDescriptionOptions] = useState([]);
  const [partNumberOptions, setPartNumberOptions] = useState([]);

  // Fetch Part Details
  const { data: partsDetailsRes } = useQuery(
    "getPartsByRM",
    () => getPartsByRM(rawMaterial.id),
    { retry: false }
  );

  useEffect(() => {
    if (partsDetailsRes?.data) {
      setTableRows(partsDetailsRes.data.map((part, index) => ({
        id: index,
        partId: part.id || "",
        partDescription: part.partDescription || "",
        partNumber: part.sapPartNo || "",
        cuttingLength: part.cuttingLength || "",
        machineId: part.machineId || "",
        perPieceWeightKg: part.perPieceWeightKg || "",
        plantId: part.plantId || "",
        binTareWt: part.binTareWt || "",
        checked: false,
      })));
    }
  }, [partsDetailsRes]);

  // Fetch Part Descriptions
  const { data: partDescriptionRes } = useQuery(
    "getPartDescription",
    getPartDescription,
    { retry: false }
  );

  useEffect(() => {
    if (partDescriptionRes?.data) {
      setPartDescriptionOptions(
        partDescriptionRes.data.map((desc) => ({
          value: desc,
          label: desc,
        }))
      );
    }
  }, [partDescriptionRes]);

  const addRow = () => {
    setTableRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length,
        partId: "",
        partDescription: "",
        partNumber: "",
        cuttingLength: "",
        machineId: "",
        perPieceWeightKg: "",
        plantId: "",
        binTareWt: "",
        checked: false,
      },
    ]);
  };

  const handleCheckboxChangeAll = () => {
    setCheckedItems((prevChecked) =>
      prevChecked.length === tableRows.length ? [] : tableRows.map((_, i) => i)
    );
  };

  const handleCheckboxChange = (index) => {
    setCheckedItems((prevChecked) => {
      const newChecked = [...prevChecked];
      if (newChecked.includes(index)) {
        newChecked.splice(newChecked.indexOf(index), 1);
      } else {
        newChecked.push(index);
      }
      return newChecked;
    });
  };

  const handleDelete = () => setShowDeleteModal(true);

  const deleteRows = () => {
    if (checkedItems.length === tableRows.length) {
      showErrorToast("At least one row should be present");
      setShowDeleteModal(false);
      return;
    }
    setTableRows((prevRows) =>
      prevRows.filter((_, index) => !checkedItems.includes(index))
    );
    setCheckedItems([]);
    setShowDeleteModal(false);
  };

  const handleDescriptionChange = (selectedOption, index) => {
    const updatedRows = [...tableRows];
    updatedRows[index].partDescription = selectedOption.value;
    updatedRows[index].partId = "";
    updatedRows[index].partNumber = "";
    updatedRows[index].cuttingLength = "";
    updatedRows[index].machineId = "";
    updatedRows[index].perPieceWeightKg = "";
    updatedRows[index].plantId = "";
    updatedRows[index].binTareWt = "";

    setTableRows(updatedRows);
    setPartNumberOptions([]);
  };

  // const handlePartNumberClick = async (partDescription, index) => {
  //   if (!partDescription) return;
  //   setPartNumberOptions([]);
  //   const { data } = await getPartsByDescription(partDescription);
  //   if (data) {
  //     setPartNumberOptions(
  //       data.map((part) => ({ value: part.sapPartNo, label: part.sapPartNo }))
  //     );
  //   }
  // };
  const [partDetails, setPartDetails] = useState({});
  const handlePartNumberClick = async (partDescription, index) => {
    if (!partDescription) return;
    setPartNumberOptions([]);
    
    const { data } = await getPartsByDescription(partDescription);
    if (data) {
      setPartNumberOptions(
        data.map((part) => ({ value: part.sapPartNo, label: part.sapPartNo }))
      );
  
      // Store part details for each part number
      const partDetails = data.reduce((acc, part) => {
        acc[part.sapPartNo] = {
          cuttingLength: part.cuttingLength,
          partId: part.id,
          machineId: part.machineId,
          perPieceWeightKg: part.perPieceWeightKg,
          plantId: part.plantId,
          binTareWt: part.binTareWt,
        };
        return acc;
      }, {});
  
      setPartDetails(partDetails);
    }
  };

  const rawMatrialPartMappingMutation = useMutation(rawMaterialPartMapping,
    {
      onSuccess: (res) => {
        showSuccessToast(res?.message);
        setOpenModal(false);
        refetch();
       },
       onError: (error) => {
        showErrorToast(error.response.data.message);
       }
    }
  );

  
  const handleSubmit = () =>{
    const partIds = tableRows.map((row) => row.partId);
    if (
      tableRows.some(
        (item) =>
          !item.partDescription ||
          !item.partNumber
      )
    ) {
      showErrorToast("Please fill all Rows");
      return;
    }
    if(new Set(partIds).size !== partIds.length){
      showErrorToast("Duplicate Part Numbers are not allowed");
      return;
    }
    
    rawMatrialPartMappingMutation.mutate({partIds:partIds,rawMaterialId:rawMaterial.id});
  }

  return (
    <div className={style.formContainer}>
      <div className={style.header}>
        <p className={style.heading}>RM vs Part BOM</p>
      </div>
      <div className={style.headerDetail}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Raw Material Details</p>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>RM Code</label>
            <p className={style.input}>{rawMaterial?.code}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>RM Description</label>
            <p className={style.input}>{rawMaterial?.description}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>RM Grade</label>
            <p className={style.input}>{rawMaterial?.partGrade}</p>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>RM Size</label>
            <p className={style.input}>{rawMaterial?.diameter}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>RM Color</label>
            <p className={style.input}>{rawMaterial?.color}</p>
          </div>
        </div>
      </div>

      <div className={style.lineDetails}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Part Details</p>
          <button className={style.addRowButton} onClick={addRow}>
            <img src={bluePlusIcon} alt="plus-icon" />
          </button>
        </div>
        {tableRows?.length>0?<div className={style.tableContainer}>
          <table className={style.table}>
            <thead className={style.thead}>
              <tr className={style.thRow}>
                <th className={style.th}>
                  <div className={style.checkboxContainerH}>
                    <div>
                    <input
                      type="checkbox"
                      id={`checkbox`}
                      checked={checkedItems.length === tableRows.length}
                      onChange={handleCheckboxChangeAll}
                    />
                    <label
                        htmlFor={`checkbox`}
                        className={style.checkmark}
                      ></label>
                    </div>
                    <div>Part Description</div>
                  </div>
                </th>
                <th className={style.th}>Part Number</th>
                <th className={style.th}>Cutting Length</th>
                <th className={style.th}>Machine ID</th>
                <th className={style.th}>Per Piece Weight(kg)</th>
                <th className={style.th}>Plant ID</th>
                <th className={style.th}>Bin Tare Wt.</th>
              </tr>
            </thead>
            <tbody className={style.tbody}>
              {tableRows.map((row, index) => (
                <tr className={style.tdRow} key={row.id}>
                  <td className={style.td}>
                    <div className={style.checkboxContainer}>
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        checked={checkedItems.includes(index)}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <label
                          htmlFor={`checkbox-${index}`}
                          className={style.checkmark}
                        ></label>
                      <Select
                        options={partDescriptionOptions}
                        styles={customStyleTableSelect}
                        components={{ IndicatorSeparator: () => null }}
                        placeholder="Select Part Description"
                        menuPortalTarget={document.body}
                        menuPlacement="top"
                        menuPosition="absolute"
                        value={row.partDescription && {
                          label: row.partDescription,
                          value: row.partDescription,
                        }}
                        onChange={(option) =>
                          handleDescriptionChange(option, index)
                        }
                      />
                    </div>
                  </td>
                  <td
                    className={style.td}
                    onClick={() =>
                      handlePartNumberClick(row.partDescription, index)
                    }
                  >
                    <Select
                      options={row.partDescription?partNumberOptions:[]}
                      styles={customStyleTableSelect}
                      components={{ IndicatorSeparator: () => null }}
                      placeholder="Select Part Number"
                      menuPortalTarget={document.body}
                      menuPlacement="top"
                      menuPosition="absolute"
                      value={row.partNumber && {
                        label: row.partNumber,
                        value: row.partNumber,
                      }}
                      onChange={(option) => {
                        const updatedRows = [...tableRows];
                        const selectedPart = option.value;
                  
                        // Update part number
                        updatedRows[index].partNumber = selectedPart;
                  
                        // Update other columns based on the selected part number
                        if (partDetails[selectedPart]) {
                          updatedRows[index].cuttingLength = partDetails[selectedPart].cuttingLength;
                          updatedRows[index].machineId = partDetails[selectedPart].machineId;
                          updatedRows[index].perPieceWeightKg = partDetails[selectedPart].perPieceWeightKg;
                          updatedRows[index].plantId = partDetails[selectedPart].plantId;
                          updatedRows[index].binTareWt = partDetails[selectedPart].binTareWt;
                          updatedRows[index].partId = partDetails[selectedPart].partId;
                        }
                  
                        setTableRows(updatedRows);
                      }}
                    />
                  </td>
                  <td className={style.td}>
                    <input
                      type="text"
                      className={style.input}
                      value={row.cuttingLength}
                      readOnly
                    />
                  </td>
                  <td className={style.td}>
                    <input
                      type="text"
                      className={style.input}
                      value={row.machineId}
                      readOnly
                    />
                  </td>
                  <td className={style.td}>
                    <input
                      type="text"
                      className={style.input}
                      value={row.perPieceWeightKg}
                      readOnly
                    />
                  </td>
                  <td className={style.td}>
                    <input
                      type="text"
                      className={style.input}
                      value={row.plantId}
                      readOnly
                    />
                  </td>
                  <td className={style.td}>
                    <input
                      type="text"
                      className={style.input}
                      value={row.binTareWt}
                      readOnly
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>:null}
        <div
          className={`${style.deleteCard} ${
            checkedItems.length === 0 ? style.hidden : ""
          }`}
        >
          <span className={style.selectionStatement}>
            {checkedItems.length} Part(s) selected
          </span>
          <div className={style.buttonCon}>
            <button className={style.deleteButton} onClick={handleDelete}>
              <img
                src={deleteIcon}
                alt="delete-icon"
                className={style.deleteImg}
              />
              <span>Delete</span>
            </button>
          </div>
        </div>
        {tableRows?.length>0?<div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <ColoredButton buttonName="Submit" onClick={handleSubmit} />
        </div>:null}
      </div>
      <ConfirmPopup2
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        icon={deleteIcon2}
        title="Delete Part(s)"
        statement="Are you sure you want to delete the selected Part(s)?"
        buttonName1="No"
        buttonName2="Yes"
        handleButton2={deleteRows}
      />
    </div>
  );
};

ViewBOM.propTypes = {
  rawMaterial: PropTypes.object.isRequired,
};

export default ViewBOM;
