import React from "react";
import style from "./ErrorList.module.css";
import CancelButton from "../../Button/CancelButton";
import PropTypes from "prop-types";

function ErrorList({ onClose, errors }) {
  return (
    <div className={style.errorContainer}>
      <p className={style.title}>Error List</p>
      <div className={style.body}>
        {errors.map((error) => (
          <div key={error.rowNumber} className={style.errors}>
            <div className={style.row}>Row {error.rowNumber} : </div>
            <div className={style.error}>{error.reason}</div>
          </div>
        ))}
      </div>
      <CancelButton onClick={onClose} buttonName={"Close"} />
    </div>
  );
}
ErrorList.propTypes = {
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};
export default ErrorList;
