import React, { useEffect, useState } from "react";
import style from "./ResetPasswordComp.module.css";
import showIcon from "../../../assets/svg/common/show-password.svg";
import hideIcon from "../../../assets/svg/common/hide-password.svg";
import forArr from "../../../assets/svg/common/forwardIcon.svg";
import alert from "../../../assets/svg/common/alert-circle.svg";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetPassword,
  verifyResetPasswordToken,
} from "../../../utils/api/api";
import { useMutation } from "react-query";

function ResetPasswordComp() {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await verifyResetPasswordToken(token);
        setTokenVerified(true);
      } catch (error) {
        showErrorToast(
          error?.response?.data?.message || "Something went wrong"
        );
        navigate("/");
      }
    };
    verifyToken();
  }, [token]);

  const resetPasswordMutation = useMutation(resetPassword, {
    onMutate: () => {},
    onSuccess: (res) => {
      showSuccessToast("Password has been reset");
      navigate("/");
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'",.<>?/])[^\s\t]{7,16}$/;
    return passwordRegex.test(password);
  };

  const handleReset = () => {
    if (!validatePassword(password)) {
      setErrorMsg(
        "Password must be 7-16 characters, contain at least one letter, one number, and one special character."
      );
      return;
    }
    if (password !== confirmPassword) {
      setErrorMsg("Passwords do not match.");
      return;
    }
    let data = {
      token: token,
      password: password,
    };
    resetPasswordMutation.mutate(data);
  };
  return (
    <>
      {tokenVerified ? (
        <div className={style.loginCard}>
          <p className={style.heading}>Create Password</p>
          <div className={style.inputs}>
            <div className={style.password}>
              <label className={style.label}>New Password</label>
              <div>
                <input
                  type="text"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter Password"
                  className={`${errorMsg ? style.errorInput : ""} ${
                    style.input
                  } `}
                  style={{
                    WebkitTextSecurity: passwordVisible ? "none" : "disc",
                    MozTextSecurity: passwordVisible ? "none" : "disc",
                    textSecurity: passwordVisible ? "none" : "disc",
                  }}
                />
                <img
                  src={passwordVisible ? hideIcon : showIcon}
                  alt={passwordVisible ? "Hide Password" : "Show Password"}
                  onClick={togglePasswordVisibility}
                  onKeyDown={togglePasswordVisibility}
                  className={style.toggleIcon}
                />
              </div>
              <p style={{ fontSize: "10px", fontWeight: "700" }}>
                **min 7 characters, uppercase letter, lowercase letter, at least
                one number
              </p>
            </div>
            <div className={style.password}>
              <label className={style.label}>Confirm Password</label>
              <div>
                <input
                  type="text"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Confirm Password"
                  className={`${errorMsg ? style.errorInput : ""} ${
                    style.input
                  } `}
                  style={{
                    WebkitTextSecurity: confirmPasswordVisible
                      ? "none"
                      : "disc",
                    MozTextSecurity: confirmPasswordVisible ? "none" : "disc",
                    textSecurity: confirmPasswordVisible ? "none" : "disc",
                  }}
                />
                <img
                  src={confirmPasswordVisible ? hideIcon : showIcon}
                  alt={
                    confirmPasswordVisible ? "Hide Password" : "Show Password"
                  }
                  onClick={toggleConfirmPasswordVisibility}
                  onKeyDown={toggleConfirmPasswordVisibility}
                  className={style.toggleIcon}
                />
              </div>
              <div style={{ maxWidth: "328px" }}>
                {errorMsg && (
                  <p className={style.error}>
                    <img src={alert} alt="alert" /> {errorMsg}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={style.buttons}>
            <button className={style.loginButton} onClick={handleReset}>
              Reset <img src={forArr} alt="forward-arrow" />
            </button>
          </div>
        </div>
      ) : (
        "Token Expired"
      )}
    </>
  );
}

export default ResetPasswordComp;
