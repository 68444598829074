import React, { useEffect, useState } from "react";
import style from "./UserManagemet.module.css";
import addIcon from "../../../assets/svg/common/add-filled.svg";
import editIcon from "../../../assets/svg/common/edit-filled.svg";
import deleteIcon from "../../../assets/svg/common/delete-fill.svg";
import "simplebar/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import searchIcon from "../../../assets/svg/common/serach-icon.svg";
import addUserIcon from "../../../assets/svg/common/add-user-icon.svg";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import { useMutation, useQuery } from "react-query";
import { createUser, editUser, getUserList } from "../../../utils/api/api";
import Sidebar from "../../../commonComponent/sidebar/Sidebar";
import CommonModal from "../../../commonComponent/userOperationModals/commonModal";
import ConfirmPopup from "../../../commonComponent/confirmPopup/ConfirmPopup";
import Pagination from "../../../commonComponent/Pagination/Pagination";
import Loader from "../../../commonComponent/CommonLoader/Loader";
import { debounce } from "../../../utils/Debounce/debounce";

function UserManagement() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userCounts, setUserCounts] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // API Integrations
  const { data, refetch, isLoading } = useQuery(
    ["userList", currentPage, searchQuery],
    () => {
      return getUserList(currentPage, 10,searchQuery)},
    {
      retry: false,
      onError:()=>{
        showErrorToast("Something went wrong.");
      }
    }
  );
  const createUserMutation = useMutation(createUser, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetch();
      showSuccessToast("User Added Successfully");
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });
  const editUserMutation = useMutation(editUser, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetch();
      showSuccessToast(res.message);
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });
  useEffect(() => {
    setUserList(data?.data?.records);
    setUserCounts(data?.data?.recordsCount);
    return () => {};
  }, [data?.data]);

  const handleAddUser = () => {
    setShowAddModal(true);
  };
  const handleAddButton = (name, email, role) => {
    let data = {
      userName: name,
      email: email,
      roleCode: role?.value,
    };
    setShowAddModal(false);
    createUserMutation.mutate(data);
  };
  const handleEdit = (user) => {
    setCurrentUser(user);
    setShowEditModal(true);
  };
  const handleUpdateButton = (name, email, role) => {
    setShowEditModal(false);
    let data = {
      userId: currentUser.userId,
      userName: name,
      email: email,
      roleCode: role?.value,
    };
    setCurrentUser(null);
    editUserMutation.mutate(data);
  };
  const handleDelete = (user) => {
    setShowDeleteModal(true);
    setCurrentUser(user);
  };
  const handleDeleteButton = () => {
    setShowDeleteModal(false);
    let data = {
      userId: currentUser.userId,
      active: false,
    };
    editUserMutation.mutate(data);
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const handleSearchDebounce = debounce(handleSearch, 300);

  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>User Management</p>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.inputBox}>
              <input
                type="text"
                name="search"
                id="search"
                className={style.searchInput}
                onChange={handleSearchDebounce}
                placeholder="Search By Name"
                // value={searchQuery}
              />
              <span className={style.imgContainer}>
                <img src={searchIcon} alt="search-icon" />
              </span>
            </div>
            <div>
              <button className={style.addButton} onClick={handleAddUser}>
                <img src={addIcon} alt="add-icon" /> Add User
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddle}>
              <div className={style.table}>
                <div className={style.thead}>
                  <div className={style.th}>User ID</div>
                  <div className={style.th}>Name</div>
                  <div className={style.th}>Email ID</div>
                  <div className={style.th}>Role</div>
                  <div className={style.th}>Action</div>
                </div>
                <SimpleBar style={{ maxHeight: "56vh" }}>
                  <div className={style.tbody}>
                    {userList?.length > 0 ? (
                      userList?.map((user) => (
                        <div className={style.tr} key={user.userId}>
                          <div className={style.td}>{user.userId}</div>
                          <div className={style.td}>{user.userName}</div>
                          <div className={style.td}>{user.email}</div>
                          <div className={style.td}>{user.roleName}</div>
                          <div className={style.td}>
                            <img
                              src={editIcon}
                              alt="edit-img"
                              onClick={() => handleEdit(user)}
                              onKeyDown={() => handleEdit(user)}
                            />
                            <img
                              src={deleteIcon}
                              alt="delete-img"
                              onClick={() => handleDelete(user)}
                              onKeyDown={() => handleDelete(user)}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={style.noUser}>No Data Found</div>
                    )}
                  </div>
                </SimpleBar>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(userCounts / 10)}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
      <CommonModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        icon={addUserIcon}
        title="Add User"
        buttonName="Add"
        handleButton2={handleAddButton}
        user={null}
        setUser={setCurrentUser}
      />
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        icon={editIcon}
        title="Edit User"
        buttonName="Save"
        handleButton2={handleUpdateButton}
        user={currentUser}
        setUser={setCurrentUser}
      />
      <ConfirmPopup
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        icon={deleteIcon}
        title="Delete User"
        statement="Are you sure you want to delete this user?"
        buttonName1="No"
        buttonName2="Yes"
        handleButton2={handleDeleteButton}
        user={currentUser}
        setUser={setCurrentUser}
      />
    </div>
  );
}

export default UserManagement;
