import React from "react";
import style from "./PreviewQR.module.css";
import PropTypes from "prop-types";
import { printPacketQR } from "../../utils/Print/printPacketQr";
import CancelButton from "../Button/CancelButton";
import ColoredButton from "../Button/ColoredButton";
import { printRodQR } from "../../utils/Print/printRodQr";

function PreviewQR2({ setViewQRModal, qrLink, qrData, qrType }) {
  return (
    <div className={style.preview}>
      <div className={style.image}>
        <img
          src={`data:image/png;base64,${qrLink}`}
          alt="QR Code"
          className={style.qrImage}
        />
      </div>
      <div className={style.buttons}>
        <CancelButton buttonName="Cancel" onClick={() => {
          setViewQRModal(false);
        }} />
        <ColoredButton buttonName="Print" onClick={() => {
          setViewQRModal(false);
          if(qrType === "packet"){
            printPacketQR(qrLink, qrData);
          }
          else if(qrType === "rod"){
            printRodQR(qrLink, qrData)
          }
        }
        } />
      </div>
    </div>
  );
}

PreviewQR2.propTypes = {
  setViewQRModal: PropTypes.func.isRequired,
  qrLink: PropTypes.string.isRequired,
  qrData: PropTypes.array.isRequired,
  qrType: PropTypes.string.isRequired,
};

export default PreviewQR2;
