import React from "react";
import style from "./Button.module.css";
import PropTypes from "prop-types";

function CancelButton({ buttonName, onClick,img }) {
  return (
    <button className={style.button1} onClick={onClick}>
      {img ? <img src={img} alt="icon" /> : ""}
      <span>{buttonName}</span>
    </button>
  );
}

CancelButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  img: PropTypes.string,
};

export default CancelButton;
