import React from 'react'
import style from "./ConfirmPopup.module.css"
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import CancelButton from "../Button/CancelButton";
import ColoredButton from "../Button/ColoredButton";

 const customStyles = {
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(5px)', // Adjust the blur level here
      zIndex: 3,
    },
  };

function ConfirmPopup2({
    showModal,
    setShowModal,
    icon,
    title,
    statement,
    buttonName1,
    buttonName2,
    handleButton2,
    }) {
    return (
      <ReactModal
        className={style.container}
        isOpen={showModal}
        center
        showCloseIcon={true}
        style={customStyles}
        onRequestClose={() => setShowModal(false)}
      >
        <div className={style.titleContainer}>
          <span>
            <img src={icon} alt="delete-icon" />
          </span>
          <p className={style.title}>{title}</p>
        </div>
        <div>
            <p className={style.confirmText}>{statement}</p>
        </div>
        <div className={style.buttons}>
              <CancelButton onClick={()=>setShowModal(false)} buttonName = {buttonName1}/>
              <ColoredButton onClick={handleButton2} buttonName = {buttonName2}/>
          </div>
      </ReactModal>
    );
  }
  ConfirmPopup2.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    statement: PropTypes.string.isRequired,
    buttonName1: PropTypes.string.isRequired,
    buttonName2: PropTypes.string.isRequired,
    handleButton2: PropTypes.func.isRequired,
  };
export default ConfirmPopup2
