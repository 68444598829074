import React, { useEffect, useState } from "react";
import style from "./GateEntry.module.css";
import Sidebar from "../../commonComponent/sidebar/Sidebar";
import addIcon from "../../assets/svg/common/add-filled.svg";
import editIcon from "../../assets/svg/common/edit-filled.svg";
import previewIcon from "../../assets/svg/common/preview-icon.svg";
import "simplebar/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import ReactModal from "react-modal";
import NewEntryForm from "../../commonComponent/entryForm/EntryForm";
import { useQuery } from "react-query";
import {
  getGateEntries,
  getSupplier,
} from "../../utils/api/api";
import moment from "moment";
import Select,{components} from "react-select";
import { customStyleSearchSelect } from "../../utils/styles/react-select/customSelect";
import EditEntry from "../../commonComponent/entryForm/editEntry";
import ViewEntry from "../../commonComponent/entryForm/viewEntry/viewEntry";
import { customModalStyle } from "../../utils/styles/react-modal/reactModalCustomStyle";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import Pagination from "../../commonComponent/Pagination/Pagination";
import Loader from "../../commonComponent/CommonLoader/Loader";
import PropTypes from "prop-types";
import { showErrorToast } from "../../utils/notify";

const CustomInput = React.forwardRef(
  ({ value, onClick, placeholderText }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      placeholder={placeholderText}
    />
  )
);
const CustomDropdownIndicator = (props) => {
  if (props.selectProps.value) {
    return null;
  }
  return <components.DropdownIndicator {...props} />;
};

function GateEntry() {
  const [entryModal, setEntryModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [gateEntriesList, setGateEntriesList] = useState([]);
  const [supplierNameOption, setSupplierNameOption] = useState([]);
  const [gateEntryId, setGateEntryId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [gateEntriesCount, setGateEntriesCount] = useState(0);
  const [date, setDate] = useState({
    minDate: null,
    maxDate: null,
  });
  const [filter, setFilter] = useState({
    transporterId: null,
    supplierId: null,
    createdOnFrom: null,
    createdOnTo: null,
    paginationCriteria: {
      limit: 10,
      page: currentPage,
      isPaginationRequired: true,
    },
  });

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      paginationCriteria: {
        ...prevFilter.paginationCriteria,
        page: currentPage,
      },
    }));
  }, [currentPage]);

  const { data: gateEntries, refetch: refetchGateEntries,isLoading } = useQuery(
    ["gateEntries", filter],
    () => getGateEntries(filter),
    {
      retry: false,
      onError: () => {
        showErrorToast("Something went wrong");
      },
    }
  );

  useEffect(() => {
    setGateEntriesList(gateEntries?.records);
    setGateEntriesCount(gateEntries?.recordsCount);
  }, [gateEntries]);

  // Handling Date Changes
  const handleFromDateChanges = (date) => {
    setFromDate(date);
    setDate((prev) => ({ ...prev, minDate: date }));
    setCurrentPage(1);
    setFilter((prevFilter) => ({
      ...prevFilter,
      createdOnFrom: date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null,
    }));
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    setDate((prev) => ({ ...prev, maxDate: date }));
    setCurrentPage(1);
    setFilter((prevFilter) => ({
      ...prevFilter,
      createdOnTo: date
        ? moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      paginationCriteria: {
        ...prevFilter.paginationCriteria,
        page: 1,
      },
    }));
  };
  const { data: supplierData } = useQuery("getSupplier", () => getSupplier(), {
    retry: false,
  });

  useEffect(() => {
    setSupplierNameOption(
      supplierData?.data?.map((item) => ({ value: item.id, label: item.name }))
    );
  }, [supplierData?.data]);

  const handleSupplierSearch = (option) => {
    setCurrentPage(1);
    setFilter((prevFilter) => ({
      ...prevFilter,
      supplierId: option ? option.value : null,
      paginationCriteria: {
        ...prevFilter.paginationCriteria,
        page: 1,
      },
    }));
  };

  const handleNewEntry = () => {
    setEntryModal(true);
  };

  const handleEdit = (gateEntryId) => {
    setEditModal(true);
    setGateEntryId(gateEntryId);
  };

  const handlePreview = (gateEntryId) => {
    setGateEntryId(gateEntryId);
    setViewModal(true);
  };
  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>Gate Entry</p>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.filters}>
              {/* <Select
                onChange={handleTransporterSearch}
                options={transporterNameOption}
                isClearable
                placeholder="Transporter Name"
                styles={customStyleSearchSelect}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              /> */}
              <Select
                onChange={handleSupplierSearch}
                options={supplierNameOption}
                placeholder="Supplier Name"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <div className={style.dateRange}>
                <div className="custom-date-picker">
                  {/* <FaCalendarAlt className="calendar-icon" onClick={() => document.querySelector('.custom-date-picker input').focus()} /> */}
                  <ReactDatePicker
                    selected={fromDate}
                    onChange={handleFromDateChanges}
                    dateFormat="dd/MM/yyyy"
                    maxDate={date?.maxDate ? date?.maxDate : new Date()}
                    customInput={
                      <CustomInput placeholderText="Select Start Date" />
                    }
                    isClearable
                  />
                </div>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#6c757d",
                  }}
                >
                  To
                </span>
                <div className="custom-date-picker">
                  {/* <FaCalendarAlt className="calendar-icon" onClick={() => document.querySelector('.custom-date-picker input').focus()} /> */}
                  <ReactDatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    dateFormat="dd/MM/yyyy"
                    minDate={date?.minDate}
                    maxDate={new Date()}
                    customInput={
                      <CustomInput placeholderText="Select End Date" />
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
            <div>
              <button className={style.addButton} onClick={handleNewEntry}>
                <img src={addIcon} alt="add-icon" /> New Entry
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
          <div className={style.bodyMiddle}>
            <div className={style.table}>
              <div className={style.thead}>
                <div className={style.th}>GE No.</div>
                <div className={style.th}>Supplier Code</div>
                <div className={style.th}>Supplier Name</div>
                <div className={style.th}>Invoice No.</div>
                <div className={style.th}>Transporter Name</div>
                <div className={style.th}>Date</div>
                <div className={style.th}>Action</div>
              </div>
              <SimpleBar style={{ maxHeight: "56vh" }}>
                <div className={style.tbody}>
                  {gateEntriesList?.length > 0 ? (
                    gateEntriesList?.map((item, index) => (
                      <div className={style.tr} key={item?.gateEntryId}>
                        <div className={style.td}>{item?.gateEntryId}</div>
                        <div className={style.td}>{item?.supplierCode}</div>
                        <div className={style.td}>{item?.supplierName}</div>
                        <div className={style.td}>{item?.invoiceNumber}</div>
                        <div className={style.td}>{item?.transporterName}</div>
                        <div className={style.td}>
                          {item?.gateEntryDateTime
                            ? moment(item.gateEntryDateTime).format(
                                "DD/MM/YYYY"
                              )
                            : null}
                        </div>
                        <div className={style.td}>
                          <img
                            src={editIcon}
                            alt="edit-img"
                            onClick={() => handleEdit(item?.gateEntryId)}
                            onKeyDown={() => handleEdit(item?.gateEntryId)}
                          />
                          <img
                            src={previewIcon}
                            alt="preview-img"
                            onClick={() => handlePreview(item?.gateEntryId)}
                            onKeyDown={() => handlePreview(item?.gateEntryId)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={style.noUser}>No Data Found</div>
                  )}
                </div>
              </SimpleBar>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(gateEntriesCount / 10)}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
        </div>
      </div>
      <ReactModal
        className={style.container}
        isOpen={entryModal}
        center
        showCloseIcon={true}
        style={customModalStyle}
        onRequestClose={() => setEntryModal(false)}
      >
        <NewEntryForm
          setEntryModal={setEntryModal}
          refetchGateEntries={refetchGateEntries}
        />
      </ReactModal>
      <ReactModal
        className={style.container}
        isOpen={editModal}
        center
        showCloseIcon={true}
        onRequestClose={() => setEditModal(false)}
        style={customModalStyle}
      >
        <EditEntry
          id={gateEntryId}
          setEditModal={setEditModal}
          refetchGateEntries={refetchGateEntries}
        />
      </ReactModal>
      <ReactModal
        className={style.container}
        isOpen={viewModal}
        center
        showCloseIcon={true}
        onRequestClose={() => setViewModal(false)}
        style={customModalStyle}
      >
        <ViewEntry
          id={gateEntryId}
          setEditModal={setEditModal}
          refetchGateEntries={refetchGateEntries}
        />
      </ReactModal>
    </div>
  );
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
};
CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

export default GateEntry;
