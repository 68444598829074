import React, { useEffect, useState } from "react";
import style from "./viewEntry.module.css";
import { useQuery } from "react-query";
import { getGateEntryDetails } from "../../../utils/api/api";
import moment from "moment";
import ProductDescription2 from "../productDescription/ProductDescription2";
import convertTime from "../../../utils/customFunctions/convertTime";
import PropTypes from "prop-types";

const ViewEntry = ({ id }) => {
  // Gate Entry Details
  const [gateEntryDetails, setGateEntryDetails] = useState({});
  const { data: gateEntryData } = useQuery(
    "getGateEntryDetails",
    () => getGateEntryDetails(id),
    {
      retry: false,
    }
  );

  useEffect(() => {
    if (gateEntryData?.data) {
      setGateEntryDetails(gateEntryData.data);
    }
  }, [gateEntryData]);

  const [headerDetails, setHeaderDetails] = useState({});
  const [fixedDetails, setFixedDetails] = useState({});

  useEffect(() => {
    if (gateEntryDetails) {
      setHeaderDetails({
        supplierCode: gateEntryDetails.supplierCode,
        supplierName: gateEntryDetails.supplierName,
        transporterName: gateEntryDetails.transporterName,
        vehicleNumber: gateEntryDetails.vehicleNumber,
        driverName: gateEntryDetails.driverName,
        invoiceNumber: gateEntryDetails.invoiceNumber,
        invoiceDate: gateEntryDetails.invoiceDate
          ? moment(gateEntryDetails.invoiceDate).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        invoiceGrossWeight: gateEntryDetails.invoiceGrossWeight,
      });

      setFixedDetails({
        gateEntryNumber: gateEntryDetails.gateEntryNumber,
        gateEntryTime: gateEntryDetails.gateEntryTime
          ? convertTime(gateEntryDetails.gateEntryTime)
          : null,
        gateEntryDate: gateEntryDetails.gateEntryDate
          ? moment(gateEntryDetails.gateEntryDate).format("DD/MM/YYYY")
          : null,
      });
    }
  }, [gateEntryDetails]);

  return (
    <div className={style.formContainer}>
      <div className={style.header}>
        <p className={style.heading}>View Entry</p>
      </div>
      <div className={style.headerDetail}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Header Details</p>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>GE No.</label>
            <p className={style.input}>{fixedDetails.gateEntryNumber}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Transporter Name</label>
            <p className={style.input}>{headerDetails.transporterName}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice Date</label>
            <p className={style.input}>
              {headerDetails.invoiceDate
                ? moment(headerDetails.invoiceDate).format("DD/MM/YYYY")
                : ""}
            </p>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Supplier Code</label>
            <p className={style.input}>{headerDetails.supplierCode}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Vehicle Number</label>
            <p className={style.input}>{headerDetails.vehicleNumber}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice Gwt.</label>
            <p className={style.input}>{headerDetails.invoiceGrossWeight}</p>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Supplier Name</label>
            <p className={style.input}>{headerDetails.supplierName}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Driver</label>
            <p className={style.input}>{headerDetails.driverName}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice No.</label>
            <p className={style.input}>{headerDetails.invoiceNumber}</p>
          </div>
        </div>
        <div className={style.row}>
          {/* <div className={style.column}>
            <label className={style.label}>Transporter Code</label>
            <p className={style.input}>{headerDetails.transporterCode}</p>
          </div> */}
          <div className={style.column}>
            <label className={style.label}>Date</label>
            <p className={style.input}>{fixedDetails.gateEntryDate}</p>
          </div>
          <div className={style.column}>
            <label className={style.label}>Gate in Time</label>
            <p className={style.input}>{fixedDetails.gateEntryTime}</p>
          </div>
        </div>
      </div>
      <ProductDescription2 id={id} />
    </div>
  );
};

ViewEntry.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ViewEntry;
