import moment from "moment/moment";
import { showErrorToast } from "../notify";

export const handleUnauthorizedUser = (errMsg) => {
    localStorage.clear();
    window.location.href = "/";
    showErrorToast(errMsg);
};
export const formatColumnDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "";
}
