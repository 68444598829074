import React from "react";
import style from "./Button.module.css";
import PropTypes from "prop-types";

function ColoredButton({ buttonName, onClick, img, width,weight }) {
  return (
    <button
      className={style.button2}
      onClick={onClick}
      style={{ width: width || "114px",fontWeight:weight||"600" }}
    >
      {img ? <img src={img} alt="icon" /> : ""}
      <span>{buttonName}</span>
    </button>
  );
}

ColoredButton.propTypes = {
  buttonName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  img: PropTypes.string,
  width: PropTypes.string,
  weight: PropTypes.string,
};

export default ColoredButton;
