import axios from "axios";
import { showErrorToast } from "../notify";
const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

export const getToken = () => {
  return localStorage.getItem("bearerToken");
};

export const handle401Error = (error) => {
  if (error.response && error.response.status === 401) {
    // Implement your logout logic here
    localStorage.removeItem("bearerToken");
    localStorage.clear();
    window.location.href = "/"; // Redirect to login page or any other appropriate action
    showErrorToast("Session expired. Please login again.");
  }
};

export const getUserList = async (currentPage, limit,searchQuery = "") => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/user-list?searchKeyword=${searchQuery}&page=${currentPage}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getSupplier = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/supplier`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getRawMaterial = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/raw-material`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getTransporter = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/transporter`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getGateEntries = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/gate-entries`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const createGateEntry = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/gate-entry`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const editGateEntry = async (data) => {
  const token = getToken();
  try {
    const response = await axios.put(`${apiKey}/gate-entry`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getGateEntryDetails = async (id) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/gate-entry-details?gateEntryId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const detailsFromQR = async (type, id) => {
  try {
    const response = await axios.get(`${apiKey}/details-from-qr/${type}/${id}`);
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getRoleList = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const createUser = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const editUser = async (data) => {
  const token = getToken();
  try {
    const response = await axios.put(`${apiKey}/edit-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const requestResetPassword = async (email) => {
  const token = getToken();
  try {
    const response = await axios.post(
      `${apiKey}/request-password-reset?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const verifyResetPasswordToken = async (token) => {
  try {
    const response = await axios.get(
      `${apiKey}/verify-reset-token?token=${token}`
    );
    return response;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axios.post(
      `${apiKey}/reset-password?token=${data?.token}&password=${data?.password}`
    );
    return response.data.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

// QA Issuance
export const getPacketList = async (
  {searchQuery,
  currentPage,
  limit,
  availableRods,
  selectedPartGrade,
  selectedRmSize,
  fromDate,
  toDate}
) => {
  const token = getToken();
  let url = `/packet-list?searchKeyword=${searchQuery}&page=${currentPage}&limit=${limit}&isPaginationRequired=1&availableRods=${availableRods}&partGrade=${selectedPartGrade||""}&size=${selectedRmSize||""}&createdOnFrom=${fromDate||""}&createdOnTo=${toDate||""}`;
  try {
    const response = await axios.get(`${apiKey}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getRodList = async (searchQuery, currentPage, limit,partGrade,size,status,fromDate,toDate) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/rod-list?searchKeyword=${searchQuery}&page=${currentPage}&limit=${limit}&isPaginationRequired=1&partGrade=${partGrade||""}&size=${size||""}&status=${status||""}&createdOnFrom=${fromDate||""}&createdOnTo=${toDate||""}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const issueRod = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/issue-rod`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const updateRodStatus = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/update-rod-status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const partMasterUpload = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/part-master`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getPartMasterList = async (
  searchQuery = "",
  currentPage = 1,
  limit = 10
) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/part-master?searchKeyword=${searchQuery}&page=${currentPage}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const  getRawMaterialList = async (
  searchQuery,
  currentPage,
  limit
) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/raw-material?page=${currentPage}&limit=${limit}&searchKeyword=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const updatePart = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/update-part`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getRodStatuses = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/rod-statuses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const rawMaterialUpload = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/raw-material`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getPartGrade = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/part-grade`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getRawMaterialFromGrade = async (partGrade) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/raw-material-from-grade/${partGrade}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getRmSize = async () => {
  const token = getToken();
  try {
    const response = await axios.get(`${apiKey}/size`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const updateRM = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/update-raw-material`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};

export const getRodInventory = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/rod-inventory`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getRodInventoryNew = async (currentPage, limit,rodId,size,partGrade,createdOnTo,createdOnFrom,) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/rod-inventory?page=${currentPage}&limit=${limit}&rodId=${rodId}&size=${size}&partGrade=${partGrade}&createdOnTo=${createdOnTo}&createdOnFrom=${createdOnFrom}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const addSupplier = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/add-supplier`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getPartsByRM = async (id) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/parts-by-raw-material/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getPartDescription = async () => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/part-description`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const getPartsByDescription = async (description) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `${apiKey}/parts-by-description/${description}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};
export const rawMaterialPartMapping = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post(`${apiKey}/raw-material-part-mapping`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    handle401Error(error);
    throw error;
  }
};