import React from 'react'
import style from "./PreviewQR.module.css"
// import QRImage from "../../assets/svg/common/"
import PropTypes from "prop-types";
import { printFromPrinter } from '../../utils/Print/print';

function PreviewQR({setViewQRModal,qrLink,id,packetID}) {
  return (
    <div className={style.preview}>
      <div className={style.image}>
      <img
                    src={`data:image/png;base64,${qrLink}`} alt="QR Code"
                    className={style.qrImage}
                  />
      </div>
      <div className={style.buttons}>
        <button className={style.button1} onClick={()=>setViewQRModal(false)}>Cancel</button>
        <button className={style.button2} onClick={()=>{
          setViewQRModal(false)
          printFromPrinter(qrLink,[])
        }}>Print</button>
      </div>
    </div>
  )
}

PreviewQR.propTypes = {
  setViewQRModal: PropTypes.func.isRequired,
  qrLink: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  packetID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PreviewQR
