import React from 'react'
import Leftside from '../../commonComponent/login/leftSide/Leftside'
import pic2 from "../../assets/svg/common/logout-screen-image.svg"
import style from "./ResetPasswword.module.css"
import ResetPasswordComp from './resetPasswordComp/ResetPasswordComp'

function ResetPassword() {
  return (
    <div className={style.loginParent}>
      <Leftside pic2={pic2} />
      <ResetPasswordComp />
    </div>
  )
}

export default ResetPassword
