import React from 'react'
import Leftside from '../../commonComponent/login/leftSide/Leftside'
import Userlogin from './user-login/Userlogin'
import style from "./login.module.css";
import pic2 from "../../assets/svg/login/login-screen-image.svg"

function Login () {
  return (
    <div className={style.loginParent}>
      <Leftside pic2={pic2} />
      <Userlogin />
    </div>
  )
}

export default Login;