import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { detailsFromQR } from '../../utils/api/api';
import style from './QRDetails.module.css';
import victoraLogo from '../../assets/svg/common/victora-logo.svg';
import PropTypes from 'prop-types';

const QRDetails = () => {
  const { type, id } = useParams();
  const { data: qrData } = useQuery(['detailsFromQR', type, id], () => detailsFromQR(type, id));
  const [data, setData] = useState(null);

  useEffect(() => {
    if (qrData) {
      setData(qrData?.data);
    }
  }, [qrData]);

  return (
    <div className={style.qrDetails}>
      <div className={style.upper}>
        <p className={style.heading}>QR Details</p>
        <img src={victoraLogo} alt="victora-logo" height="65px" />
      </div>
      {data && (
        <div className={style.qrDetailsGrid}>
          <DetailRow label="Raw Material Code" value={data.rmCode} />
          <DetailRow label="RM Grade" value={data.partGrade} />
          <DetailRow label="Size" value={data.size} />
          <DetailRow label="Color" value={data.color} />
          <DetailRow label="Heat Number" value={data.heatNumber} />
          <DetailRow label="Number of Packets" value={data.numberOfPackets} />
          <DetailRow label="Rods per Packet" value={data.rodsPerPacket} />
          <DetailRow label="Weight per Rod" value={data.weightPerRod} />
          <DetailRow label="Total Rods" value={data.totalRods} />
          <DetailRow label="Supplier Code" value={data.supplierCode} />
          <DetailRow label="Supplier Name" value={data.supplierName} />
          <DetailRow label="Transporter Code" value={data.transporterCode} />
          <DetailRow label="Transporter Name" value={data.transporterName} />
          <DetailRow label="Invoice Date" value={new Date(data.invoiceDate).toLocaleDateString()} />
          <DetailRow label="Invoice Number" value={data.invoiceNumber} />
          <DetailRow label="Invoice Gross Weight" value={data.invoiceGrossWeight} />
          <DetailRow label="Vehicle Number" value={data.vehicleNumber} />
          <DetailRow label="Driver Name" value={data.driverName} />
          <DetailRow label="Entry By" value={data.entryBy} />
        </div>
      )}
    </div>
  );
};

const DetailRow = ({ label, value }) => (
  <div className={style.qrDetail}>
    <span className={style.label}>{label}:</span>
    <span className={style.value}>{value}</span>
  </div>
);
DetailRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
};
export default QRDetails;
