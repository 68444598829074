import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const CustomExcelFileDownload = ( sheetHeader, sheetData, fileNameLabel ) => {
  

  const generateExcelData = () => {
    // Combine the header and data
    const data = [sheetHeader, ...sheetData];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Correct the range of the worksheet to avoid empty rows
    // worksheet['!ref'] = XLSX.utils.encode_range({
    //     s: { c: 0, r: 0 },
    //     e: { c: sheetHeader.length - 1, r: sheetData.length }
    // });

    // Generate Excel file and trigger download
    // XLSX.writeFile(workbook, 'sample.xlsx');


    return workbook;
  };

  const downloadExcelFile = (workbook) => {
    const fileExtension = '.xlsx';
    const fileName = `${fileNameLabel}${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    saveAs(blob, fileName);
  };
  const handleDownloadButtonClick = () => {
    const workbook = generateExcelData();
    downloadExcelFile(workbook);
  };
  handleDownloadButtonClick();
};

export default CustomExcelFileDownload;
