import React, { useEffect, useState } from "react";
import style from "./EntryForm.module.css";
import { useMutation, useQuery } from "react-query";
import PropTypes from "prop-types";
import {
  editGateEntry,
  getGateEntryDetails,
  getPartGrade,
  getRawMaterialFromGrade,
  getSupplier,
} from "../../utils/api/api";
import Select from "react-select";
import alert from "../../assets/svg/common/alert-circle.svg";
import {
  customStyleFormSelect,
  customStyleTableSelect,
} from "../../utils/styles/react-select/customSelect";
import { showErrorToast, showSuccessToast } from "../../utils/notify";
import moment from "moment";
import ProductDescription2 from "./productDescription/ProductDescription2";
import ReactModal from "react-modal";

const EditEntry = ({ id, setEditModal, refetchGateEntries }) => {
  const customStyle = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "80vh",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: 4,
    },
  };

  const [headerDetailsN, setHeaderDetailsN] = useState({});
  const [lineDetailsN, setLineDetailsN] = useState([]);
  const [updatingIndex, setUpdatingIndex] = useState(null);
  const [rmData, setRmData] = useState([[]]);
  const [rmSizeOptions, setRmSizeOptions] = useState([[]]);
  const [selectedPartGrade, setSelectedPartGrade] = useState([]);
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [openQRModal, setOpenQRModal] = useState(false);
  const { data: gateEntryDetails } = useQuery(
    "getGateEntryDetails",
    () => getGateEntryDetails(id),
    {
      retry: false,
    }
  );
  const { data: partGradeResponse, status: partGradeResponseStatus } = useQuery(
    "part-grade",
    getPartGrade
  );
  const {
    data: rmFromGradeResponse,
    status: rmFromGradeResponseStatus,
    refetch: refetchRM,
  } = useQuery(
    ["rm-from-grade", selectedPartGrade?.value, updatingIndex],
    () =>{if(selectedPartGrade?.value) 
            return getRawMaterialFromGrade(selectedPartGrade?.value)
          },
    {
      enabled: !!selectedPartGrade?.value,
    }
  );

  useEffect(() => {
      refetchRM();
  }, [selectedPartGrade]);
  useEffect(() => {
    setHeaderDetailsN(() => {
      return {
        gateEntryNumber: gateEntryDetails?.data?.gateEntryNumber,
        supplierId: gateEntryDetails?.data?.supplierId,
        supplierCode: gateEntryDetails?.data?.supplierCode,
        supplierName: gateEntryDetails?.data?.supplierName,
        transporterName: gateEntryDetails?.data?.transporterName,
        vehicleNumber: gateEntryDetails?.data?.vehicleNumber,
        driverName: gateEntryDetails?.data?.driverName,
        gateEntryDate: gateEntryDetails?.data?.gateEntryDate,
        gateEntryTime: gateEntryDetails?.data?.gateEntryTime,
        invoiceGrossWeight: gateEntryDetails?.data?.invoiceGrossWeight,
        invoiceDate: gateEntryDetails?.data?.invoiceDate,
        invoiceNumber: gateEntryDetails?.data?.invoiceNumber,
      };
    });
    setLineDetailsN(() => {
      return gateEntryDetails?.data?.lineItems?.map((item) => {
        return {
          id: item.id,
          partGrade: item?.description?.partGrade,
          rmDescription: item?.description?.rmDescription,
          rawMaterialId: item?.description?.rawMaterialId,
          heatNumber: item?.description?.heatNumber,
          color: item.color,
          size: item.size,
          numberOfPackets: item.numberOfPackets,
          rodsPerPacket: item.rodsPerPacket,
          totalRods: item.totalRods,
          totalWeight: item?.description?.totalWeight,
          bundleId: item.bundleId,
          rmCode: item?.description?.rmCode,
        };
      });
    });
  }, [gateEntryDetails]);
  useEffect(() => {
    if (partGradeResponseStatus === "success" && partGradeResponse?.data) {
      const transformedOptions = partGradeResponse.data.map((grade) => ({
        value: grade,
        label: grade,
      }));
      setPartGradeOptions(transformedOptions);
    }
  }, [partGradeResponse, partGradeResponseStatus]);
  useEffect(() => {
    if (rmFromGradeResponseStatus === "success" && rmFromGradeResponse?.data) {
      const transformedOptions = rmFromGradeResponse.data.map((rm) => ({
        value: rm.id,
        label: rm.diameter,
      }));
      setRmSizeOptions((prev) => {
        const updatedState = [...prev];
        updatedState[updatingIndex] = transformedOptions;
        return updatedState;
      });
      setRmData((prev) => {
        const updatedState = [...prev];
        updatedState[updatingIndex] = rmFromGradeResponse.data;
        return updatedState;
      });
    }
  }, [rmFromGradeResponse, rmFromGradeResponseStatus]);
  const [suppliers, setSuppliers] = useState([]);
  const handleChangeSupplier = (selectedOption, type) => {
    if (type === "name") {
      const correspondingSupplier = suppliers.find(
        (supplier) => supplier.name === selectedOption.label
      );
      setHeaderDetailsN({
        ...headerDetailsN,
        supplierCode: correspondingSupplier.code,
        supplierName: correspondingSupplier.name,
      });
    } else if (type === "code") {
      const correspondingSupplier = suppliers.find(
        (supplier) => supplier.code === selectedOption.label
      );
      setHeaderDetailsN({
        ...headerDetailsN,
        supplierCode: correspondingSupplier.code,
        supplierName: correspondingSupplier.name,
      });
    }
  };
  const [supplierCodeOption, setSupplierCodeOption] = useState([]);
  const [supplierNameOption, setSupplierNameOption] = useState([]);
  const [headersError, setHeadersError] = useState({
    supplierCode: false,
    supplierName: false,
    transporterCode: false,
    transporterName: false,
    vehicleNumber: false,
    driverName: false,
    invoiceNumber: false,
    invoiceDate: false,
    invoiceGrossWeight: false,
  });
  const { data: supplierData } = useQuery("getSupplier", () => getSupplier(), {
    retry: false,
  });
  useEffect(() => {
    setSupplierNameOption(
      supplierData?.data?.map((item) => ({ value: item.id, label: item.name }))
    );
    setSupplierCodeOption(
      supplierData?.data?.map((item) => ({ value: item.id, label: item.code }))
    );
    setSuppliers(supplierData?.data);
    return () => {};
  }, [supplierData?.data]);

  const editGateEntryMutation = useMutation(editGateEntry, {
    onMutate: () => {},
    onSuccess: () => {
      showSuccessToast("Gate Entry Edited Successfully");
      setOpenQRModal(true);
      refetchGateEntries();
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });
  const handleGenerateQR = () => {
    if (!headerDetailsN.supplierName) {
      setHeadersError((prev) => ({ ...prev, supplierName: true }));
    } else {
      setHeadersError((prev) => ({ ...prev, supplierName: false }));
    }
    if (!headerDetailsN.invoiceDate) {
      setHeadersError((prev) => ({ ...prev, invoiceDate: true }));
    } else {
      setHeadersError((prev) => ({ ...prev, invoiceDate: false }));
    }
    if (
      !headerDetailsN.supplierName ||
      !headerDetailsN.transporterName ||
      !headerDetailsN.vehicleNumber ||
      !headerDetailsN.driverName ||
      !headerDetailsN.invoiceNumber ||
      !headerDetailsN.invoiceDate ||
      !headerDetailsN.invoiceGrossWeight ||
      headersError?.invoiceGrossWeight ||
      headersError?.driverName ||
      headersError?.vehicleNumber ||
      headersError?.transporterName ||
      headersError?.invoiceNumber
    ) {
      showErrorToast("Please fill all the header details correctly");
      return;
    }
    if (
      lineDetailsN.some(
        (item) =>
          !item.rmCode ||
          !item.rmDescription ||
          !item.partGrade ||
          !item.heatNumber ||
          !item.size ||
          !item.numberOfPackets ||
          !item.rodsPerPacket ||
          !item.totalRods ||
          !item.totalWeight
      )
    ) {
      showErrorToast("Please fill all the line details");
      return;
    }
    let sendData = {
      ...headerDetailsN,
      id: id,
      lineItemRequestList: lineDetailsN,
    };

    editGateEntryMutation.mutate(sendData);
  };
  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (
      e.key === "e" ||
      e.key === "E" ||
      e.key === "+" ||
      e.key === "-"
    ) {
      e.preventDefault();
    }
  };
  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", () => {
      e.target.blur();
      // Your event handling code here
    });
  };
  const calculateTotalRods = (index) => {
    const updatedState = [...lineDetailsN];
    updatedState[index].totalRods = Number(
      Number(lineDetailsN[index].numberOfPackets) *
        Number(lineDetailsN[index].rodsPerPacket)
    );
    setLineDetailsN(updatedState);
  };
  const handleSelectPartGrade = (option, index) => {
    setSelectedPartGrade(option);
    const updatedState = [...lineDetailsN];
    updatedState[index] = {
      ...updatedState[index],
      partGrade: option.value,
      rawMaterialId: null,
      size: null,
      rmDescription: "",
      rmCode: "",
      color: "",
    };
    setUpdatingIndex(index);
    setLineDetailsN(updatedState);
  };
  const handleSelectRMSize = (option, index) => {
    const selectedObject = rmData[index].find(
      (item) => item.id === option.value
    );
    const updatedState = [...lineDetailsN];
    updatedState[index] = {
      ...updatedState[index],
      rawMaterialId: option.value,
      size: option.label,
      rmDescription: selectedObject.description,
      rmCode: selectedObject.code,
      color: selectedObject.color,
    };
    setLineDetailsN(updatedState);
  };

  const handleRMOptions = (partGrade, index) => {
    setUpdatingIndex(index);
    setSelectedPartGrade({
      value: partGrade,
      label: partGrade,
    });
  };

  const renderRows = () => {
    return lineDetailsN?.map((item, index) => (
      <tr className={style.tdRow} key={item?.id}>
        <td className={style.td}>
          <Select
            value={{ value: item?.partGrade, label: item?.partGrade }}
            onChange={(option) => handleSelectPartGrade(option, index)}
            options={partGradeOptions}
            styles={customStyleTableSelect}
            components={{
              IndicatorSeparator: () => null,
            }}
            menuPortalTarget={document.body}
            menuPosition="absolute"
            menuPlacement="top"
          />
        </td>
        <td
          className={style.td}
          onClick={() => {
            handleRMOptions(item?.partGrade, index);
          }}
        >
          <Select
            value={{ value: item?.rawMaterialId, label: item?.size }}
            onChange={(option) => handleSelectRMSize(option, index)}
            options={rmSizeOptions[index]}
            styles={customStyleTableSelect}
            components={{
              IndicatorSeparator: () => null,
            }}
            menuPortalTarget={document.body}
            menuPlacement="top"
            menuPosition="absolute"
          />
        </td>
        {/* RM Description */}
        <td className={`${style.td} ${style.autoS}`}>
          <input
            type="text"
            className={style.input}
            value={item?.rmDescription}
            readOnly
            disabled
          />
        </td>
        {/* RM Code */}
        <td className={`${style.td} ${style.autoS}`}>
          <input
            type="text"
            className={style.input}
            value={item?.rmCode}
            readOnly
          />
        </td>
        {/* RM Color */}
        <td className={`${style.td} ${style.autoS}`}>
          <input
            type="text"
            className={style.input}
            value={item?.color}
            readOnly
          />
        </td>
        <td className={style.td}>
          <input
            type="text"
            className={style.input}
            value={item?.heatNumber}
            onChange={(e) => {
              let temp = [...lineDetailsN];
              temp[index].heatNumber = e.target.value;
              setLineDetailsN(temp);
            }}
          />
        </td>
        <td className={style.td}>
          <input
            type="number"
            className={style.input}
            min={0}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
            value={item?.numberOfPackets}
            onChange={(e) => {
              let temp = [...lineDetailsN];
              let no = e.target.value.replace(/[^0-9.-]/g, "");
              let newNo = parseInt(no, 10);
              temp[index].numberOfPackets = Number(newNo);
              calculateTotalRods(index);
              setLineDetailsN(temp);
            }}
          />
        </td>
        <td className={style.td}>
          <input
            type="number"
            className={style.input}
            value={item?.rodsPerPacket}
            min={0}
            onChange={(e) => {
              const updatedState = [...lineDetailsN];
              let rpp = e.target.value.replace(/[^0-9.-]/g, "");
              let newRpp = parseInt(rpp, 10);
              updatedState[index].rodsPerPacket = Number(newRpp);
              calculateTotalRods(index);
              setLineDetailsN(updatedState);
            }}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
          />
        </td>
        <td className={style.td}>
          <input
            type="number"
            className={style.input}
            value={item?.totalRods}
            readOnly
          />
        </td>
        <td className={style.td}>
          <input
            type="text"
            className={style.input}
            value={item?.bundleId}
            onChange={(e) => {
              const updatedState = [...lineDetailsN];
              updatedState[index].bundleId = e.target.value;
              setLineDetailsN(updatedState);
            }}
          />
        </td>
        <td className={style.td}>
          <input
            type="number"
            className={style.input}
            value={item?.totalWeight}
            onChange={(e) => {
              const updatedState = [...lineDetailsN];
              let tw = e.target.value.replace(/[^0-9.-]/g, "");
              // Parse the input value to a float
              let newTw = parseFloat(tw);
              // Format the number to 5 decimal places
              updatedState[index].totalWeight =
                Number.isNaN(newTw) ? null : parseFloat(newTw.toFixed(5));
              setLineDetailsN(updatedState);
            }}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
          />
        </td>
      </tr>
    ));
  };
  return (
    <div className={style.formContainer}>
      <div className={style.header}>
        <p className={style.heading}>Edit Entry</p>
      </div>
      <div className={style.headerDetail}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Header Details</p>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>GE No.</label>
            <input
              className={style.input}
              type="number"
              value={headerDetailsN?.gateEntryNumber}
              readOnly
              disabled={true}
            />
          </div>
          <div className={style.column}>
            <label className={style.label}>Transporter Name</label>
            <input
              className={`${style.input} ${
                headersError?.transporterName ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetailsN?.transporterName}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({
                    ...prev,
                    transporterName: "Required",
                  }));
                } else if (e.target.value.length > 50) {
                  setHeadersError((prev) => ({
                    ...prev,
                    transporterName: "Should Not Exceed 50 Characters",
                  }));
                } else {
                  setHeadersError((prev) => ({
                    ...prev,
                    transporterName: false,
                  }));
                }
                setHeaderDetailsN({
                  ...headerDetailsN,
                  transporterName: e.target.value,
                });
              }}
            />
            {headersError?.transporterName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headersError?.transporterName}
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice Date</label>
            <input
              className={`${style.input} ${
                headersError?.invoiceDate ? style.errorInput : ""
              }`}
              type="date"
              value={
                headerDetailsN?.invoiceDate
                  ? moment(headerDetailsN?.invoiceDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({ ...prev, invoiceDate: true }));
                } else {
                  setHeadersError((prev) => ({ ...prev, invoiceDate: false }));
                }
                setHeaderDetailsN({
                  ...headerDetailsN,
                  invoiceDate: moment(e.target.value).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                });
              }}
              onKeyDown={(e) => e.preventDefault()}
            />
            {headersError?.invoiceDate && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> Required
              </p>
            )}
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Supplier Code</label>
            <Select
              value={{
                value: headerDetailsN?.supplierId,
                label: headerDetailsN?.supplierCode,
              }}
              onChange={(option) => handleChangeSupplier(option, "code")}
              options={supplierCodeOption}
              styles={customStyleFormSelect}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPortalTarget={document.body}
              menuPosition="absolute"
            />
          </div>
          <div className={style.column}>
            <label className={style.label}>Vehicle Number</label>
            <input
              className={`${style.input} ${
                headersError?.vehicleNumber ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetailsN?.vehicleNumber}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({
                    ...prev,
                    vehicleNumber: "Required",
                  }));
                } else if (e.target.value.length > 20) {
                  setHeadersError((prev) => ({
                    ...prev,
                    vehicleNumber: "Should Not Exceed 20 Characters",
                  }));
                } else {
                  setHeadersError((prev) => ({
                    ...prev,
                    vehicleNumber: false,
                  }));
                }
                setHeaderDetailsN({
                  ...headerDetailsN,
                  vehicleNumber: e.target.value,
                });
              }}
            />
            {headersError?.vehicleNumber && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headersError?.vehicleNumber}
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice Gwt.</label>
            <input
              className={`${style.input} ${
                headersError?.invoiceGrossWeight ? style.errorInput : ""
              }`}
              type="number"
              value={headerDetailsN?.invoiceGrossWeight}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: "Required",
                  }));
                } else if (e.target.value.length > 20) {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: "Should Not Exceed 20 Digits",
                  }));
                } else {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: false,
                  }));
                }

                let igwt = e.target.value.replace(/[^0-9.-]/g, "");
                let newigwt = parseInt(igwt, 10);
                setHeaderDetailsN({
                  ...headerDetailsN,
                  invoiceGrossWeight: Number(newigwt),
                });
              }}
              onKeyDown={handleKeyDown}
              onClick={handleInputClick}
            />
            {headersError?.invoiceGrossWeight && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headersError?.invoiceGrossWeight}
              </p>
            )}
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Supplier Name</label>
            <Select
              value={{
                value: headerDetailsN?.supplierId,
                label: headerDetailsN?.supplierName,
              }}
              onChange={(option) => handleChangeSupplier(option, "name")}
              options={supplierNameOption}
              styles={customStyleFormSelect}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPortalTarget={document.body}
              menuPosition="absolute"
            />
          </div>
          <div className={style.column}>
            <label className={style.label}>Driver</label>
            <input
              className={`${style.input} ${
                headersError?.driverName ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetailsN?.driverName}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({
                    ...prev,
                    driverName: "Required",
                  }));
                } else if (e.target.value.length > 50) {
                  setHeadersError((prev) => ({
                    ...prev,
                    driverName: "Should Not Exceed 50 Characters",
                  }));
                } else {
                  setHeadersError((prev) => ({ ...prev, driverName: false }));
                }
                setHeaderDetailsN({
                  ...headerDetailsN,
                  driverName: e.target.value,
                });
              }}
            />
            {headersError?.driverName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {headersError?.driverName}
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice No.</label>
            <input
              className={`${style.input} ${
                headersError?.invoiceNumber ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetailsN?.invoiceNumber}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceNumber: "Required",
                  }));
                } else if (e.target.value.length > 25) {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceNumber: "Should Not Exceed 25 Characters",
                  }));
                } else {
                  setHeadersError((prev) => ({
                    ...prev,
                    invoiceNumber: false,
                  }));
                }
                setHeaderDetailsN({
                  ...headerDetailsN,
                  invoiceNumber: e.target.value,
                });
              }}
            />
            {headersError?.invoiceNumber && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headersError?.invoiceNumber}
              </p>
            )}
          </div>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Date</label>
            <input
              className={style.input}
              type="text"
              value={headerDetailsN?.gateEntryDate}
              readOnly
              disabled={true}
            />
          </div>
          <div className={style.column}>
            <label className={style.label}>Gate in Time</label>
            <input
              className={style.input}
              type="text"
              value={moment(
                headerDetailsN?.gateEntryTime,
                "HH:mm:ss.SSS"
              ).format("HH:mm:ss ")}
              readOnly
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className={style.lineDetails}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Line Details</p>
        </div>
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead className={style.thead}>
              <tr className={style.thRow}>
                <th className={style.th}>RM Grade</th>
                <th className={style.th}>RM Size</th>
                <th className={style.th}>RM Description</th>
                <th className={style.th}>RM Code</th>
                <th className={style.th}>RM Color</th>
                <th className={style.th}>Heat Number</th>
                <th className={style.th}>Number of Packets</th>
                <th className={style.th}>No. of Rods in each Pack</th>
                <th className={style.th}>Total No. Of Rods</th>
                <th className={style.th}>Bundle Id</th>
                <th className={style.th}>Total Weight</th>
              </tr>
            </thead>
            <tbody className={style.tbody}>{renderRows()}</tbody>
          </table>
        </div>
      </div>
      <div className={style.buttons}>
        <button className={style.saveButton} onClick={handleGenerateQR}>
          Generate QR
        </button>
      </div>
      {/* QR Modal */}
      <ReactModal
        className={style.container}
        isOpen={openQRModal}
        center
        showCloseIcon={true}
        style={customStyle}
        onRequestClose={() => {
          setOpenQRModal(false);
          setEditModal(false);
        }}
      >
        <div className={style.productsDesList}>
          <ProductDescription2 id={id} />
        </div>
      </ReactModal>
    </div>
  );
};
EditEntry.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Allow id to be either a string or a number
  setEditModal: PropTypes.func.isRequired,
  refetchGateEntries: PropTypes.func.isRequired,
};

export default EditEntry;
