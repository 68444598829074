import React, { useEffect, useState } from "react";
import style from "./EntryForm.module.css";
import bluePlusIcon from "../../assets/svg/common/plus-blueBg.svg";
import { useMutation, useQuery } from "react-query";
import PropTypes from "prop-types";
import alert from "../../assets/svg/common/alert-circle.svg";
import deleteIcon from "../../assets/svg/common/delete-line-entries.svg";
import deleteIcon2 from "../../assets/svg/common/delete-fill.svg";
import {
  addSupplier,
  createGateEntry,
  getPartGrade,
  getRawMaterialFromGrade,
  getSupplier,
} from "../../utils/api/api";
import Select from "react-select";
import {
  customStyleFormSelect,
  customStyleTableSelect,
} from "../../utils/styles/react-select/customSelect";
import { showErrorToast, showSuccessToast } from "../../utils/notify";
import moment from "moment";
import ConfirmPopup2 from "../confirmPopup/ConfirmPopup2";
import ReactModal from "react-modal";
import AddSupplier from "./addSupplier/addSupplier";
import ConfirmPopup3 from "../confirmPopup/ConfirmPopup3";

const NewEntryForm = ({ setEntryModal, refetchGateEntries }) => {
  const customStyles = {
    content: {
      position: "absolute", // 'absolute' to 'fixed' for modal to be fixed on screen
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: "3",
    },
  };
  const customStyleSelect = (hasError) => ({
    ...customStyleFormSelect,
    control: (provided, state) => ({
      ...customStyleFormSelect.control(provided, state),
      border: hasError ? "1px solid red" : provided.border,
      "&:hover": {
        borderColor: hasError ? "red" : provided.borderColor,
      },
    }),
  });
  const [suppliers, setSuppliers] = useState([]);
  const [supplierCodeOption, setSupplierCodeOption] = useState([]);
  const [supplierNameOption, setSupplierNameOption] = useState([]);
  const [selectedSupplierCode, setSelectedSupplierCode] = useState(null);
  const [selectedSupplierName, setSelectedSupplierName] = useState(null);
  // New
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [rmSizeOptions, setRmSizeOptions] = useState([[]]);
  const [selectedPartGrade, setSelectedPartGrade] = useState(null);
  const [rmData, setRmData] = useState([[]]);
  const [updatingIndex, setUpdatingIndex] = useState(null);
  const [supplier,setSupplier] = useState({
    name:"",
    code:""
  })
  const [supplierModal,setSupplierModal] = useState(false)
  const [supplierModalConfirmation,setSupplierModalConfirmation] = useState(false)

  // End
  const [headerDetails, setHeaderDetails] = useState({
    supplierCode: null,
    supplierName: null,
    transporterName: null,
    vehicleNumber: "",
    driverName: "",
    invoiceNumber: "",
    invoiceDate: "",
    invoiceGrossWeight: null,
  });
  const lineDetailsProto = {
    rawMaterialId: null,
    rmCode: "",
    rmDescription: "",
    partGrade: "",
    heatNumber: "",
    color: "",
    size: null,
    numberOfPackets: null,
    rodsPerPacket: null,
    totalRods: null,
    totalWeight: null,
  };
  const [lineDetails, setLineDetails] = useState([lineDetailsProto]);
  const [headerDetailsError, setHeaderDetailsError] = useState({
    supplierCode: false,
    supplierName: false,
    transporterCode: false,
    transporterName: false,
    vehicleNumber: false,
    driverName: false,
    invoiceNumber: false,
    invoiceDate: false,
    invoiceGrossWeight: false,
  });
  const { data: supplierData,refetch:refetchSupplier } = useQuery("getSupplier", getSupplier, {
    retry: false,
  });
  // New
  const { data: partGradeResponse, status: partGradeResponseStatus } = useQuery(
    "part-grade",
    getPartGrade
  );
  const { data: rmFromGradeResponse, status: rmFromGradeResponseStatus } =
    useQuery(
      ["rm-from-grade", selectedPartGrade?.value, updatingIndex],
      () => getRawMaterialFromGrade(selectedPartGrade?.value),
      {
        enabled: !!selectedPartGrade?.value,
      }
    );

  useEffect(() => {
    if (supplierData) {
      setSupplierNameOption(
        supplierData.data.map((item) => ({ value: item.id, label: item.name }))
      );
      setSupplierCodeOption(
        supplierData.data.map((item) => ({ value: item.id, label: item.code }))
      );
      setSuppliers(supplierData.data);
    }
  }, [supplierData]);

  useEffect(() => {
    if (partGradeResponseStatus === "success" && partGradeResponse?.data) {
      const pGradeOptions = partGradeResponse.data.map((pGrade) => ({
        value: pGrade,
        label: pGrade,
      }));
      setPartGradeOptions(pGradeOptions);
    }
  }, [partGradeResponse, partGradeResponseStatus]);
  useEffect(() => {
    if (rmFromGradeResponseStatus === "success" && rmFromGradeResponse?.data) {
      const rmFromGradeOptions = rmFromGradeResponse.data.map(
        (rmFromGrade) => ({
          value: rmFromGrade.id,
          label: rmFromGrade.diameter,
        })
      );
      setRmSizeOptions((prev) => {
        const updatedState = [...prev];
        updatedState[updatingIndex] = rmFromGradeOptions;
        return updatedState;
      });
      setRmData((prev) => {
        const updatedState = [...prev];
        updatedState[updatingIndex] = rmFromGradeResponse.data;
        return updatedState;
      });
    }
  }, [rmFromGradeResponse, rmFromGradeResponseStatus]);

  const updateHeaderDetails = (correspondingItem, category) => {
    if (category === "supplier") {
      setSelectedSupplierName({
        value: correspondingItem.id,
        label: correspondingItem.name,
      });
      setSelectedSupplierCode({
        value: correspondingItem.id,
        label: correspondingItem.code,
      });
      setHeaderDetails({
        ...headerDetails,
        supplierCode: correspondingItem.code,
        supplierName: correspondingItem.name,
      });
    }
  };
  const handleSelectChange = (selectedOption, type, category, index) => {
    let correspondingItem;
    if (category === "supplier") {
      correspondingItem = suppliers.find(
        (item) =>
          (type === "name" ? item.name : item.code) === selectedOption.label
      );
      updateHeaderDetails(correspondingItem, category);
    }
  };

  // Select Changes

  const handleSelectPartGrade = (option, index) => {
    setSelectedPartGrade(option);
    const updatedState = [...lineDetails];
    updatedState[index] = {
      ...updatedState[index],
      partGrade: option.value,
      rawMaterialId: null,
      size: null,
      rmDescription: "",
      rmCode: "",
      color: "",
    };
    setUpdatingIndex(index);
    setLineDetails(updatedState);
  };
  const handleSelectRMSize = (option, index) => {
    const selectedObject = rmData[index].find(
      (item) => item.id === option.value
    );
    const updatedState = [...lineDetails];
    updatedState[index] = {
      ...updatedState[index],
      rawMaterialId: option.value,
      size: option.label,
      rmDescription: selectedObject.description,
      rmCode: selectedObject.code,
      color: selectedObject.color,
    };
    setLineDetails(updatedState);
  };

  const gateEntryMutation = useMutation(createGateEntry, {
    onSuccess: (res) => {
      showSuccessToast("Gate Entry Created Successfully");
      setEntryModal(false);
      refetchGateEntries();
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.message || "Something went wrong");
    },
  });

  const handleGenerateQR = () => {
    if (!headerDetails.supplierName) {
      setHeaderDetailsError((prev) => ({ ...prev, supplierName: true }));
    } else {
      setHeaderDetailsError((prev) => ({ ...prev, supplierName: false }));
    }
    if (!headerDetails.transporterName) {
      setHeaderDetailsError((prev) => ({
        ...prev,
        transporterName: "Required",
      }));
    }
    if (!headerDetails.vehicleNumber) {
      setHeaderDetailsError((prev) => ({ ...prev, vehicleNumber: "Required" }));
    }
    if (!headerDetails.driverName) {
      setHeaderDetailsError((prev) => ({ ...prev, driverName: "Required" }));
    }
    if (!headerDetails.invoiceNumber) {
      setHeaderDetailsError((prev) => ({ ...prev, invoiceNumber: "Required" }));
    }
    if (!headerDetails.invoiceDate) {
      setHeaderDetailsError((prev) => ({ ...prev, invoiceDate: true }));
    } else {
      setHeaderDetailsError((prev) => ({ ...prev, invoiceDate: false }));
    }
    if (!headerDetails.invoiceGrossWeight) {
      setHeaderDetailsError((prev) => ({
        ...prev,
        invoiceGrossWeight: "Required",
      }));
    }
    if (
      !headerDetails.supplierName ||
      !headerDetails.transporterName ||
      !headerDetails.vehicleNumber ||
      !headerDetails.driverName ||
      !headerDetails.invoiceNumber ||
      !headerDetails.invoiceDate ||
      !headerDetails.invoiceGrossWeight ||
      headerDetailsError?.invoiceGrossWeight ||
      headerDetailsError?.driverName ||
      headerDetailsError?.vehicleNumber ||
      headerDetailsError?.transporterName ||
      headerDetailsError?.invoiceNumber
    ) {
      showErrorToast("Please fill all the header details correctly");
      return;
    }
    if (
      lineDetails.some(
        (item) =>
          !item.rmCode ||
          !item.rmDescription ||
          !item.partGrade ||
          !item.color ||
          !item.size
      )
    ) {
      showErrorToast("Please fill all the RM details");
      return;
    }

    gateEntryMutation.mutate({
      ...headerDetails,
      lineItemRequestList: lineDetails,
    });
  };
  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };
  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };
  const calculateTotalRods = (index) => {
    const updatedState = [...lineDetails];
    updatedState[index].totalRods = Number(
      Number(lineDetails[index].numberOfPackets) *
        Number(lineDetails[index].rodsPerPacket)
    );
    setLineDetails(updatedState);
  };
  const calculateTotalWeight = (index) => {
    const updatedState = [...lineDetails];
    updatedState[index].totalWeight = Number(
      Number(lineDetails[index].totalRods) *
        Number(lineDetails[index].weightPerRod)
    );
    setLineDetails(updatedState);
  };
  // Checkox Operations
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const handleDelete = () => {
    setShowDeleteModal(true);
  };
  // Dynamic Rows

  const [trows, setTrows] = useState([
    { id: 0, name: "Row 1", checked: false },
  ]);

  const addRow = () => {
    const newRow = {
      id: trows.length + 1,
      name: `Row ${trows.length + 1}`,
      checked: false,
    };
    setTrows([...trows, newRow]);
    setLineDetails([...lineDetails, lineDetailsProto]);
  };

  const deleteRows = () => {
    if (checkedItems?.length === trows.length) {
      showErrorToast("Atleast one row should be present");
      setShowDeleteModal(false);
      return;
    }
    const remainingRows = trows.filter(
      (_, rowIndex) => !checkedItems.includes(rowIndex)
    );
    setTrows(remainingRows);
    const remainingDetails = lineDetails.filter(
      (_, rowIndex) => !checkedItems.includes(rowIndex)
    );
    setLineDetails(remainingDetails);
    const remainingRmData = rmData.filter(
      (_, rowIndex) => !checkedItems.includes(rowIndex)
    );
    setRmData(remainingRmData);
    const remainingRmSizeOptions = rmSizeOptions.filter(
      (_, rowIndex) => !checkedItems.includes(rowIndex)
    );
    setRmSizeOptions(remainingRmSizeOptions);
    setCheckedItems([]);
    setShowDeleteModal(false);
  };

  // Function to handle checkbox change
  const handleAllCheckboxChange = () => {
    if (checkedItems.length === trows.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(trows.map((_, index) => index));
    }
  };
  const handleCheckboxChange = (index) => {
    setCheckedItems((prev) => {
      const newCheckedItems = [...prev];
      if (newCheckedItems.includes(index)) {
        const indexToRemove = newCheckedItems.indexOf(index);
        newCheckedItems.splice(indexToRemove, 1);
      } else {
        newCheckedItems.push(index);
      }
      return newCheckedItems;
    });
  };

  //  Add Supplier
  const addSupplierMutation = useMutation(addSupplier, {
    onSuccess: (data) => {
      showSuccessToast(data?.message)
      refetchSupplier()
      setSupplierModalConfirmation(false);
      setSupplierModal(false);
      setSupplier({
        name: "",
        code: "",
      });
    },
    onError: (error) => {
      showErrorToast(error?.response?.data?.message || "Something went wrong");
    },
  });
  return (
    <div className={style.formContainer}>
      {/* Header details */}
      <div className={style.header}>
        <p className={style.heading}>New Entry</p>
      </div>
      <div className={style.headerDetail}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Header Details</p>
        </div>
        <div className={style.row}>
          <div className={style.column}>
            <div style={{display:"flex", alignItems:"center", gap: "10px"}}>
            <label className={style.label}>Supplier Name</label>
            <img
              src={bluePlusIcon}
              alt=""
              style={{cursor:"pointer", height: "20px" }}
              onClick={()=>setSupplierModal(true)}
              onKeyDown={()=>setSupplierModal(true)}
            />
            </div>
            <Select
              value={selectedSupplierName}
              onChange={(option) => {
                if (!option.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    supplierName: true,
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    supplierName: false,
                  }));
                }
                handleSelectChange(option, "name", "supplier");
              }}
              options={supplierNameOption}
              styles={customStyleSelect(!!headerDetailsError.supplierName)}
              components={{ IndicatorSeparator: () => null }}
              menuPortalTarget={document.body}
              menuPosition="absolute"
            />
            {headerDetailsError?.supplierName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> Required
              </p>
            )}
          </div>
          {/* Transporter */}
          <div className={style.column}>
            <label className={style.label}>Transporter Name</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.transporterName ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetails?.transporterName}
              onChange={(e) => {
                if (!e.target.value.trim()) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    transporterName: "Required",
                  }));
                } else if (e.target.value.length > 50) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    transporterName: "Should Not Exceed 50 Characters",
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    transporterName: false,
                  }));
                }
                setHeaderDetails({
                  ...headerDetails,
                  transporterName: e.target.value,
                });
              }}
            />
            {headerDetailsError?.transporterName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headerDetailsError?.transporterName}
              </p>
            )}
          </div>
          {/* Invoice Date */}
          <div className={style.column}>
            <label className={style.label}>Invoice Date</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.invoiceDate ? style.errorInput : ""
              }`}
              type="date"
              value={
                headerDetails?.invoiceDate
                  ? moment(headerDetails?.invoiceDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                if (!e.target.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceDate: true,
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceDate: false,
                  }));
                }
                setHeaderDetails({
                  ...headerDetails,
                  invoiceDate: moment(e.target.value).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                });
              }}
              onKeyDown={(e) => e.preventDefault()}
            />
            {headerDetailsError?.invoiceDate && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> Required
              </p>
            )}
          </div>
        </div>
        {/* Supplier Name, Vehicle Number, Invoice Gwt */}
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Supplier Code</label>
            <Select
              value={selectedSupplierCode}
              onChange={(option) => {
                if (!option.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    supplierName: true,
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    supplierName: false,
                  }));
                }
                handleSelectChange(option, "code", "supplier");
              }}
              options={supplierCodeOption}
              styles={customStyleSelect(!!headerDetailsError.supplierName)}
              components={{ IndicatorSeparator: () => null }}
              menuPortalTarget={document.body}
              menuPosition="absolute"
            />
            {headerDetailsError?.supplierName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> Required
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Vehicle Number</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.vehicleNumber ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetails?.vehicleNumber}
              onChange={(e) => {
                if (!e.target.value.trim()) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    vehicleNumber: "Required",
                  }));
                } else if (e.target.value.length > 20) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    vehicleNumber: "Should Not Exceed 20 Characters",
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    vehicleNumber: false,
                  }));
                }
                setHeaderDetails({
                  ...headerDetails,
                  vehicleNumber: e.target.value,
                });
              }}
            />
            {headerDetailsError?.vehicleNumber && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headerDetailsError?.vehicleNumber}
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice Gwt.</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.invoiceGrossWeight ? style.errorInput : ""
              }`}
              type="number"
              value={headerDetails?.invoiceGrossWeight}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: "Required",
                  }));
                } else if (e.target.value.length > 20) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: "Should Not Exceed 20 Digits",
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceGrossWeight: false,
                  }));
                }
                let igwt = e.target.value.replace(/[^0-9.-]/g, "");
                let newigwt = parseInt(igwt, 10);
                setHeaderDetails({
                  ...headerDetails,
                  invoiceGrossWeight: Number(newigwt),
                });
              }}
              onClick={handleInputClick}
              onKeyDown={handleKeyDown}
            />
            {headerDetailsError?.invoiceGrossWeight && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headerDetailsError?.invoiceGrossWeight}
              </p>
            )}
          </div>
        </div>
        {/* Transporter Code, Driver, Invoice No */}
        <div className={style.row}>
          <div className={style.column}>
            <label className={style.label}>Driver</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.driverName ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetails?.driverName}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    driverName: "Required",
                  }));
                } else if (e.target.value.length > 50) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    driverName: "Should Not Exceed 50 Characters",
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    driverName: false,
                  }));
                }
                setHeaderDetails({
                  ...headerDetails,
                  driverName: e.target.value,
                });
              }}
            />
            {headerDetailsError?.driverName && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headerDetailsError?.driverName}
              </p>
            )}
          </div>
          <div className={style.column}>
            <label className={style.label}>Invoice No.</label>
            <input
              className={`${style.input} ${
                headerDetailsError?.invoiceNumber ? style.errorInput : ""
              }`}
              type="text"
              value={headerDetails?.invoiceNumber}
              onChange={(e) => {
                if (!e.target.value) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceNumber: "Required",
                  }));
                } else if (e.target.value.length > 25) {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceNumber: "Should Not Exceed 25 Characters",
                  }));
                } else {
                  setHeaderDetailsError((prev) => ({
                    ...prev,
                    invoiceNumber: false,
                  }));
                }
                setHeaderDetails({
                  ...headerDetails,
                  invoiceNumber: e.target.value,
                });
              }}
            />
            {headerDetailsError?.invoiceNumber && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" />{" "}
                {headerDetailsError?.invoiceNumber}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* Line details */}
      <div className={style.lineDetails}>
        <div className={style.lineHeader}>
          <p className={style.lineHeading}>Line Details</p>
          <button
            className={style.addRowButton}
            onClick={() => {
              addRow();
            }}
          >
            <img src={bluePlusIcon} alt="plus-icon" />
          </button>
        </div>
        {/* Line details table */}
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead className={style.thead}>
              <tr className={style.thRow}>
                <th className={style.th}>
                  <div className={style.checkboxContainerH}>
                    <div>
                      <input
                        type="checkbox"
                        id={`checkbox`}
                        checked={checkedItems.length === trows.length}
                        onChange={() => {
                          handleAllCheckboxChange();
                        }}
                      />
                      <label
                        htmlFor={`checkbox`}
                        className={style.checkmark}
                      ></label>
                    </div>
                    <div>RM Grade</div>
                  </div>
                </th>
                <th className={style.th}>RM Size</th>
                <th className={style.th}>RM Description</th>
                <th className={style.th}>RM Code</th>
                <th className={style.th}>RM Color</th>
                <th className={style.th}>Heat Number</th>
                <th className={style.th}>Number of Packets</th>
                <th className={style.th}>No. of Rods in each Pack</th>
                <th className={style.th}>Total No. Of Rods</th>
                <th className={style.th}>Bundle Id</th>
                <th className={style.th}>Total Weight</th>
              </tr>
            </thead>
            {/* <tbody className={style.tbody}>{renderRows()}</tbody> */}
            <tbody className={style.tbody}>
              {trows.map((row, index) => {
                return (
                  <tr className={style.tdRow} key={row.id}>
                    <td className={style.td}>
                      <div className={style.checkboxContainer}>
                        <input
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={checkedItems.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label
                          htmlFor={`checkbox-${index}`}
                          className={style.checkmark}
                        ></label>
                        <Select
                          value={
                            lineDetails[index].partGrade && {
                              value: lineDetails[index].partGrade,
                              label: lineDetails[index].partGrade,
                            }
                          }
                          onChange={(option) =>
                            handleSelectPartGrade(option, index)
                          }
                          // onChange={(option) =>
                          //   handleSelectChange(
                          //     option,
                          //     "description",
                          //     "rawMaterial",
                          //     index
                          //   )
                          // }
                          options={partGradeOptions}
                          styles={customStyleTableSelect}
                          components={{ IndicatorSeparator: () => null }}
                          placeholder="Select RM Grade"
                          menuPortalTarget={document.body}
                          menuPlacement="top"
                          menuPosition="absolute"
                        />
                      </div>
                    </td>
                    <td className={style.td}>
                      <Select
                        value={
                          lineDetails[index].rawMaterialId
                            ? {
                                value: lineDetails[index].rawMaterialId,
                                label: lineDetails[index].size,
                              }
                            : null
                        }
                        onChange={(option) => handleSelectRMSize(option, index)}
                        options={rmSizeOptions[index]}
                        styles={customStyleTableSelect}
                        components={{ IndicatorSeparator: () => null }}
                        placeholder="Select RM Size"
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        menuPlacement="top"
                      />
                    </td>
                    {/* RM Description */}
                    <td className={`${style.td} ${style.autoS}`}>
                      <input
                        type="text"
                        className={style.input}
                        value={lineDetails[index].rmDescription}
                        disabled
                        readOnly
                      />
                    </td>
                    {/* RM Code */}
                    <td className={`${style.td} ${style.autoS}`}>
                      <input
                        type="text"
                        className={style.input}
                        value={lineDetails[index].rmCode}
                        readOnly
                      />
                      {/* RM COLOR */}
                    </td>
                    <td className={`${style.td} ${style.autoS}`}>
                      <input
                        type="text"
                        className={style.input}
                        value={lineDetails[index].color}
                        readOnly
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="text"
                        className={style.input}
                        value={lineDetails[index].heatNumber}
                        onChange={(e) => {
                          const updatedState = [...lineDetails];
                          updatedState[index].heatNumber = e.target.value;
                          setLineDetails(updatedState);
                        }}
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="number"
                        className={style.input}
                        value={lineDetails[index].numberOfPackets}
                        min={0}
                        onChange={(e) => {
                          const updatedState = [...lineDetails];
                          let no = e.target.value.replace(/[^0-9.-]/g, "");
                          let newNo = parseInt(no, 10);
                          updatedState[index].numberOfPackets = Number(newNo);
                          calculateTotalRods(index);
                          calculateTotalWeight(index);
                          setLineDetails(updatedState);
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="number"
                        className={style.input}
                        value={lineDetails[index].rodsPerPacket}
                        min={0}
                        onChange={(e) => {
                          const updatedState = [...lineDetails];
                          let rpp = e.target.value.replace(/[^0-9.-]/g, "");
                          let newRpp = parseInt(rpp, 10);
                          updatedState[index].rodsPerPacket = Number(newRpp);
                          calculateTotalRods(index);
                          calculateTotalWeight(index);
                          setLineDetails(updatedState);
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="number"
                        className={style.input}
                        value={lineDetails[index].totalRods}
                        readOnly
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="text"
                        className={style.input}
                        value={lineDetails[index].bundleId}
                        onChange={(e) => {
                          const updatedState = [...lineDetails];
                          updatedState[index].bundleId = e.target.value;
                          setLineDetails(updatedState);
                        }}
                      />
                    </td>
                    <td className={style.td}>
                      <input
                        type="number"
                        className={style.input}
                        value={lineDetails[index].totalWeight}
                        onChange={(e) => {
                          const updatedState = [...lineDetails];
                          let tw = e.target.value.replace(/[^0-9.-]/g, "");
                          // Parse the input value to a float
                          let newTw = parseFloat(tw);
                          // Format the number to 5 decimal places
                          updatedState[index].totalWeight =
                            Number.isNaN(newTw) ? null : parseFloat(newTw.toFixed(5));
                          setLineDetails(updatedState);
                        }}
                        onKeyDown={handleKeyDown}
                        onClick={handleInputClick}                       
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        // className={style.deleteCard}
        className={`${style.deleteCard} ${
          checkedItems.length === 0 ? style.hidden : ""
        }`}
      >
        <span className={style.selectionStatement}>
          {checkedItems.length} Line entries selected
        </span>
        <div className={style.buttonCon}>
          <button className={style.deleteButton}>
            <img
              src={deleteIcon}
              alt="delete-icon"
              className={style.deleteImg}
            />
            <span onClick={handleDelete} onKeyDown={handleDelete}>
              Delete
            </span>
          </button>
        </div>
      </div>
      {/* Buttons */}
      <div className={style.buttons}>
        <button className={style.qrButton} onClick={handleGenerateQR}>
          Save
        </button>
      </div>
      <ConfirmPopup2
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        icon={deleteIcon2}
        title="Delete Line Item"
        statement="Are you sure you want to delete the selected line item(s) ?"
        buttonName1="No"
        buttonName2="Yes"
        handleButton2={deleteRows}
      ></ConfirmPopup2>
      <ConfirmPopup3
        showModal={supplierModalConfirmation}
        setShowModal={setSupplierModalConfirmation}
        icon={bluePlusIcon}
        title="Add Supplier"
        statement="This Action is not editable. Are you sure you want to add the supplier?"
        buttonName1="No"
        buttonName2="Yes"
        handleButton2={()=>{
          addSupplierMutation.mutate(supplier);
        }}
        supplier={supplier}
      ></ConfirmPopup3>
      <ReactModal
      className={style.container}
      isOpen={supplierModal}
      center
      showCloseIcon={true}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      onRequestClose={() => {
        setSupplierModal(false);
      }}
    >
      <AddSupplier setSupplierModal={setSupplierModal} setSupplierModalConfirmation={setSupplierModalConfirmation} supplier={supplier} setSupplier={setSupplier} />

    </ReactModal>
    </div>
  );
};

NewEntryForm.propTypes = {
  setEntryModal: PropTypes.func.isRequired,
  refetchGateEntries: PropTypes.func.isRequired,
};

export default NewEntryForm;
