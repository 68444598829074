import jsPDF from "jspdf";
import "jspdf-autotable";
import { showErrorToast } from "../notify";

export const printRodQR = (qrLink, qrData) => {
    try {
        // Initialize jsPDF with custom size (38x20 mm)
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [38, 20]
        });

        const imgData = `data:image/png;base64,${qrLink}`;
        // Define the coordinates for QR code and table
        const qrX = 0; // Small margin from the left
        const qrY = 0; // Small margin from the top
        const qrWidth = 14; // Adjusted width to fit within 38x20 mm page
        const qrHeight = 14; // Adjusted height to fit within 38x20 mm page

        // Add the QR code image to the PDF
        doc.addImage(imgData, "PNG", qrX, qrY, qrWidth, qrHeight);

        // Define the tabular data, limit the number of rows and columns to fit the page height and width
        const tableData = qrData?.length > 0 && Object.entries(qrData[0]).map(([key, value]) => [key, value]);


        // Set the starting position for the table
        const tableX = qrX + qrWidth + 2; // Place the table to the right of the QR code
        const tableY = 2; // Adjust the vertical position as needed

        // Add the company name at the top center of the table
        const companyName = "Cutting Edge Technologies";
        const companyNameX = qrX+qrWidth+2;
        doc.setFontSize(3.5); // Reduced font size for the smaller sticker
        doc.setFont("bold");
        doc.text(companyName, companyNameX, tableY - 0.5); // Adjust position as needed

        // Ensure tableData is in correct format
        if (Array.isArray(tableData) && tableData.length > 0) {
            // Add the table to the PDF using jspdf-autotable with borders
            doc.autoTable({
                startY: tableY ,
                head: [],
                body: tableData,
                theme: "grid", // Use "grid" theme to add borders around the cells
                styles: {
                    fontSize: 2.5, // Reduce font size for table content
                    cellPadding: 0.1// Reduce cell padding to fit more content
                },
                columnStyles: {
                    0: { cellWidth: 7 },
                    1: { cellWidth: 13 }
                },
                margin: { left: tableX },
                pageBreak: 'auto', // Automatically handle page breaks
                rowPageBreak: 'auto', // Automatically handle row page breaks
                tableWidth: 'auto' // Auto adjust table width
            });
        }

        // Open the PDF in a new window
        const pdfData = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfData);
        const printWindow = window.open(pdfUrl);

        // Print the PDF
        if (printWindow) {
            printWindow.onload = () => {
                printWindow.print();
            };
        }
    } catch (error) {
        showErrorToast("Error generating PDF:", error);
    }
};
