import React, { useState } from "react";
import style from "./Pagination.module.css";
import PropTypes from "prop-types";

function Pagination({ currentPage, totalPages, setCurrentPage }) {
  const [page, setPage] = useState(currentPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPage(currentPage + 1);
    }
  };
  const handlePageInput = (e) => {
    if (e.key === "-" || e.key === "+" || e.key === "e" || e.key === ".") {
      e.preventDefault();
    } else if (e.key === "Enter") {
      if (e.target.value > totalPages) {
        setCurrentPage(totalPages);
        setPage(totalPages);
      } else if (e.target.value < 1) {
        setCurrentPage(1);
        setPage(1);
      } else {
        setCurrentPage(parseInt(e.target.value));
        setPage(parseInt(e.target.value));
      }
    }
  };
  const handlePageChange = (e) => {
    setPage(e.target.value);
  };

  return (
    <>
      {totalPages > 1 ? (
        <div className={style.parent}>
          <div className={style.buttonCon}>
            <button
              className={`${style.buttonS} ${
                currentPage > 1 ? style.button : ""
              }`}
              onClick={handlePrevious}
            >
              {"<"}
            </button>
            <div>
              <input
                type="number"
                className={style.pageIp}
                value={page}
                min={1}
                onKeyDown={handlePageInput}
                onChange={handlePageChange}
              />{" "}
              / <span>{totalPages}</span>
            </div>
            <button
              className={`${style.buttonE} ${
                currentPage < totalPages ? style.button : ""
              }`}
              onClick={handleNext}
            >
              {">"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
