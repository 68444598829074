import React, { useState } from "react";
import style from "./addSupplier.module.css";
import alert from "../../../assets/svg/common/alert-circle.svg";

const AddSupplier = ({setSupplierModal,setSupplierModalConfirmation,supplier,setSupplier}) => {
  const [errors, setErrors] = useState({});
  
  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    if (!nameValue || nameValue.trim() === "") {
      setErrors((prev) => ({ ...prev, name: "Required" }));
    } else {
      setErrors((prev) => ({ ...prev, name: null }));
    }
    setSupplier((prev) => ({ ...prev, name: nameValue }));
  }
    const handleCodeChange = (e) => {
        const codeValue = e.target.value;
        if (!codeValue || codeValue.trim() === "") {
        setErrors((prev) => ({ ...prev, code: "Required" }));
        } else {
        setErrors((prev) => ({ ...prev, code: null }));
        }
        setSupplier((prev) => ({ ...prev, code: codeValue }));
    };
    const handleAdd = () => {
        let valid = true;
        if (!supplier.name || supplier.name.trim() === "") {
        setErrors((prev) => ({ ...prev, name: "Required" }));
        valid = false;
        }
        if (!supplier.code || supplier.code.trim() === "") {
        setErrors((prev) => ({ ...prev, code: "Required" }));
        valid = false;
        }
        if (valid === false) return;
        if (valid) {
        setSupplierModalConfirmation(true);
        }
    };
  return (
    <div className={style.parentContainer}>
      <div className={style.titleContainer}>
        {/* <img src={icon} alt="icon" /> */}
        <p className={style.title}>Add Supplier</p>
      </div>
      <div className={style.fields}>
          <div>
            <div className={style.field}>
              <label className={style.label}>Supplier Name</label>
              <input
                type="text"
                className={`${errors.name ? style.errorInput : ""} ${
                  style.input
                } `}
                placeholder="Enter Supplier Name"
                value={supplier.name}
                onChange={handleNameChange}
              />
            </div>
            {errors.name && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.name}
              </p>
            )}
          </div>
          <div>
            <div className={style.field}>
              <label className={style.label}>Supplier Code</label>
              <input
                value={supplier.code}
                type="text"
                className={`${style.input} ${
                  errors.code ? style.errorInput : ""
                }`}
                placeholder="Enter Supplier Code"
                onChange={handleCodeChange}
              />
            </div>
            {errors.code && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.code}
              </p>
            )}
          </div>
        </div>
        <div className={style.buttons}>
        <button className={style.button1}
         onClick={()=>{setSupplierModal(false)
          setSupplier({
            name: "",
            code: "",
          }
          )
         }}
         >
          Cancel
        </button>
        <button className={style.button2} 
        onClick={handleAdd}
        >
          {/* {buttonName} */}Add
        </button>
      </div>
    </div>
  );
};


export default AddSupplier;
