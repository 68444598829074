import React from 'react'
import logo from "../../../assets/svg/common/victora-logo.svg";
import style from "./Leftside.module.css";
import PropTypes from 'prop-types';

function Leftside({pic2}) {
  return (
    <div className={style.imgParent}>
      <div className={style.img1}>
        <img src={logo} alt="Victora Logo" />
      </div>
      <div className={style.img2}>
        <img src={pic2} alt="pic 2" />
      </div>

    </div>
  )
}
Leftside.propTypes = {
    pic2: PropTypes.string.isRequired, // pic2 must be a string and is required
  };
  

export default Leftside
