const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validateEmail = (email) => {
    if (!email) {
        return "Required";
    } else if (email.length > 50) {
        return "Email cannot exceed 50 characters";
    } else if (!emailRegex.test(email)) {
        return "Enter a valid email";
    }
    return null;
    };
export default validateEmail;
// Path: src/utils/validations/passwordValidation.js
