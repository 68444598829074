import React, { useEffect, useState } from "react";
import Sidebar from "../../commonComponent/sidebar/Sidebar";
import addIconB from "../../assets/svg/common/add-icon-black.svg";
import searchIcon from "../../assets/svg/common/serach-icon.svg";
import style from "./AllPackets.module.css";
import qrSearchIcon from "../../assets/svg/common/qr-search-icon.svg";
import ReactModal from "react-modal";
import {
  getPacketList,
  getPartGrade,
  getRmSize,
  getToken,
  handle401Error,
} from "../../utils/api/api";
import { useQuery } from "react-query";
import { formatColumnDate } from "../../utils/functions/utils";
import IssueModal from "./IssueModal/IssueRods";
import customModalStyles from "../../utils/styles/react-modal/reactModalCustomStyle";
import Pagination from "../../commonComponent/Pagination/Pagination";
import Select, { components } from "react-select";
import ReactDatePicker from "react-datepicker";
import { customStyleSearchSelect } from "../../utils/styles/react-select/customSelect";
import "./DatePicker.css";
import downloadIcon from "../../assets/svg/common/download_icon_white.svg";
import Loader from "../../commonComponent/CommonLoader/Loader";
import ColoredButton from "../../commonComponent/Button/ColoredButton";
import moment from "moment";
import { debounce } from "../../utils/Debounce/debounce";
import CustomExcelFileDownload from "../../utils/excelDownload/ExcelDownload";
import axios from "axios";
import PropTypes from "prop-types";
import { showErrorToast } from "../../utils/notify";

const CustomInput = React.forwardRef(
  ({ value, onClick, placeholderText }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      placeholder={placeholderText}
    />
  )
);

const CustomDropdownIndicator = (props) => {
  if (props.selectProps.value) {
    return null;
  }
  return <components.DropdownIndicator {...props} />;
};

function AllPackets() {
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

  const [showIssueRodModal, setShowIssueRodModal] = useState(false);
  const [packetsList, setPacketsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPacket, setSelectedPacket] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [packetCount, setPacketCount] = useState(0);
  const [availableRods, setAvailableRods] = useState(1);
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [rmSizeOptions, setRmSizeOptions] = useState([]);
  const [selectedPartGrade, setSelectedPartGrade] = useState(null);
  const [selectedRmSize, setSelectedRmSize] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sendDate, setSendDate] = useState({
    createdOnFrom: null,
    createdOnTo: null,
  });
  const [date, setDate] = useState({
    minDate: null,
    maxDate: null,
  });

  const handleIssueRods = (packet) => {
    setSelectedPacket(packet);
    setShowIssueRodModal(true);
  };

  const {
    data: packetsData,
    refetch: refetchPacketsData,
    isLoading,
  } = useQuery(
    [
      "packetList",
      currentPage,
      searchQuery,
      availableRods,
      selectedPartGrade,
      selectedRmSize,
      sendDate,
    ],
    () =>
      getPacketList({
        searchQuery,
        currentPage,
        limit: 10,
        availableRods,
        selectedPartGrade: selectedPartGrade?.value,
        selectedRmSize: selectedRmSize?.value,
        fromDate: sendDate?.createdOnFrom,
        toDate: sendDate?.createdOnTo,
      }),
      {
        retry:false,
        onError:()=>{
          showErrorToast("Something went wrong.");
        }
      }
  );

  useEffect(() => {
    if (packetsData?.data?.records) {
      setPacketsList(packetsData.data.records);
      setPacketCount(packetsData.data.recordsCount);
    }
    return () => {};
  }, [packetsData]);

  // API INTEGRATION FOR PART GRADE OPTION
  const { data: partGradeOptionResponse, status: partGradeOptionStatus } = useQuery(
    "part-grade",
    getPartGrade
  );
  const { data: rmSizeOptionResponse, status: rmSizeOptionStatus } = useQuery(
    "rm-size",
    getRmSize
  );

  useEffect(() => {
    if (partGradeOptionStatus === "success" && partGradeOptionResponse?.data) {
      const transformedOptions = partGradeOptionResponse.data.map((grade) => ({
        value: grade,
        label: grade,
      }));
      setPartGradeOptions(transformedOptions);
    }
  }, [partGradeOptionResponse, partGradeOptionStatus]);
  useEffect(() => {
    if (rmSizeOptionStatus === "success" && rmSizeOptionResponse?.data) {
      const transformedOptions = rmSizeOptionResponse.data.map((size) => ({
        value: size,
        label: size,
      }));
      setRmSizeOptions(transformedOptions);
    }
  }, [rmSizeOptionResponse, rmSizeOptionStatus]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };
  const handleSearchDebounce = debounce(handleSearch, 300);

  // Handling Date Changes
  const handleStartDateChanges = (date) => {
    setFromDate(date);
    setDate((prev) => ({ ...prev, minDate: date }));
    setCurrentPage(1);
    setSendDate((prev) => ({
      ...prev,
      createdOnFrom: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null,
    }));
  };
  const handleEndDateChanges = (date) => {
    setToDate(date);
    setDate((prev) => ({ ...prev, maxDate: date }));
    setCurrentPage(1);
    setSendDate((prev) => ({
      ...prev,
      createdOnTo: date
        ? moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
        : null,
    }));
  };
  // Downloading Excel File
  const getSheetData = async () => {
    const token = getToken();
    let url = `/packet-list?searchKeyword=${searchQuery}&page=${1}&limit=${0}&isPaginationRequired=0&availableRods=${availableRods}&partGrade=${
      selectedPartGrade ? selectedPartGrade.value : ""
    }&size=${selectedRmSize ? selectedRmSize.value : ""}&createdOnFrom=${
      fromDate ? moment(fromDate).format("YYYY-MM-DDTHH:mm:ss") : ""
    }&createdOnTo=${
      toDate ? moment(toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") : ""
    }`;
    try {
      const response = await axios.get(`${apiKey}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      handle401Error(error);
      throw error;
    }
  };
  const handleSampleExcelDownload = async () => {
    const data = await getSheetData();
    const records = data?.data?.records;
    const sheetData = records.map((record) => {
      return [
        record.packetId,
        record.gateEntryNumber,
        formatColumnDate(record.gateEntryDate),
        record.rmGrade,
        record.size,
        record.rmDescription,
        record.rmCode,
        record.color,
        record.heatNumber,
        record.numberOfRods,
        record.issuedRod,
        record.packetWeight,
      ];
    });
    const sheetHeader = [
      "Packet Id",
      "GE No.",
      "GE Date",
      "RM Grade",
      "RM Size",
      "RM Description",
      "RM Code",
      "Color",
      "Heat No.",
      "No. Of Rods",
      "Issued Rods",
      "Total Weight",
    ];
    const fileNameLabel = "All Packets Data";
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };

  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>All Packets</p>
          <div className={style.downloadBtn}>
            <ColoredButton
              buttonName={"Download"}
              weight={"400"}
              width={"120px"}
              onClick={() => {
                handleSampleExcelDownload();
              }}
              img={downloadIcon}
            />
          </div>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.headerUpper}>
              <p
                className={`${style.headerText} ${
                  availableRods === 0 ? style.activeHeader : ""
                }`}
                onClick={() => setAvailableRods(0)}
                onKeyDown={() => setAvailableRods(0)}
              >
                All Packets
              </p>
              <p
                className={`${style.headerText} ${
                  availableRods === 1 ? style.activeHeader : ""
                }`}
                onClick={() => setAvailableRods(1)}
                onKeyDown={() => setAvailableRods(1)}
              >
                Balanced Packets
              </p>
            </div>
            {/* <hr /> */}
            <div className={style.filters}>
              <div className={style.inputBox}>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className={style.searchInput}
                  onChange={handleSearchDebounce}
                  placeholder="Scan a packet to get started"
                  // value={searchQuery}
                />
                <span className={style.imgContainer}>
                  <img src={qrSearchIcon} alt="qr-search-icon" />
                  <img src={searchIcon} alt="search-icon" />
                </span>
              </div>
              <Select
                onChange={(selectedOption) => {
                  setSelectedPartGrade(selectedOption);
                  setCurrentPage(1);
                }}
                options={partGradeOptions}
                placeholder="RM Grade"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <Select
                onChange={(selectedOption) => {
                  setSelectedRmSize(selectedOption);
                  setCurrentPage(1);
                }}
                options={rmSizeOptions}
                isClearable
                placeholder="RM Size"
                styles={customStyleSearchSelect}
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <div className={style.dateRange}>
                <div className="custom-date-picker">
                  {/* <FaCalendarAlt className="calendar-icon" onClick={() => document.querySelector('.custom-date-picker input').focus()} /> */}
                  <ReactDatePicker
                    selected={fromDate}
                    onChange={handleStartDateChanges}
                    dateFormat="dd/MM/yyyy"
                    maxDate={date?.maxDate ? date?.maxDate : new Date()}
                    customInput={
                      <CustomInput placeholderText="Select Start Date" />
                    }
                    isClearable
                  />
                </div>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#6c757d",
                  }}
                >
                  To
                </span>
                <div className="custom-date-picker">
                  {/* <FaCalendarAlt className="calendar-icon" onClick={() => document.querySelector('.custom-date-picker input').focus()} /> */}
                  <ReactDatePicker
                    selected={toDate}
                    onChange={handleEndDateChanges}
                    dateFormat="dd/MM/yyyy"
                    minDate={date?.minDate}
                    maxDate={new Date()}
                    customInput={
                      <CustomInput placeholderText="Select End Date" />
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddleP}>
              <div className={style.bodyMiddle}>
                {/* <SimpleBar style={{ maxHeight: "62vh" }}> */}
                <div className={style.table}>
                  <div className={style.thead}>
                    <div className={style.thr}>
                      <div className={`${style.th}`}>Packet ID</div>
                      <div className={`${style.th}`}>GE No.</div>
                      <div className={`${style.th}`}>Gate Entry Date</div>
                      <div className={`${style.th} `}>RM Grade</div>
                      <div className={`${style.th}`}>RM Size</div>
                      <div className={`${style.th}`}>RM Description</div>
                      <div className={`${style.th}`}>RM Code</div>
                      <div className={`${style.th}`}>Color</div>
                      <div className={`${style.th}`}>Heat No.</div>
                      <div className={`${style.th}`}>No. Of Rods</div>
                      <div className={`${style.th}`}>Issued Rods</div>
                      <div className={`${style.th}`}>Total Weight</div>
                      <div className={`${style.th}`}>Action</div>
                    </div>
                  </div>
                  <div className={style.tbody}>
                    {packetsList?.length > 0 ? (
                      packetsList?.map((packet) => (
                        <div className={style.tr} key={packet.packetId}>
                          <div className={style.td}>{packet.packetId}</div>
                          <div className={style.td}>
                            {packet.gateEntryNumber}
                          </div>
                          <div className={style.td}>
                            {formatColumnDate(packet.gateEntryDate)}
                          </div>
                          <div className={style.td}>{packet.rmGrade}</div>
                          <div className={style.td}>{packet.size}</div>
                          <div className={style.td}>{packet.rmDescription}</div>
                          <div className={style.td}>{packet.rmCode}</div>
                          <div className={style.td}>{packet.color}</div>
                          <div className={style.td}>{packet.heatNumber}</div>
                          <div className={style.td}>{packet.numberOfRods}</div>
                          <div className={style.td}>{packet.issuedRod}</div>
                          <div className={style.td}>{packet.packetWeight}</div>
                          <div
                            className={`${style.td} ${
                              showIssueRodModal ? style.blur : ""
                            }`}
                          >
                            {packetCount === 1 ? (
                              <img
                                src={addIconB}
                                alt="add-img"
                                className={style.addIcon}
                                onClick={() => handleIssueRods(packet)}
                                onKeyDown={() => handleIssueRods(packet)}
                              />
                            ) : (
                              <img
                                src={addIconB}
                                alt="add-img"
                                className={style.addIcon}
                                style={{ cursor: "not-allowed" }}
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={style.noUser}>No Data Found</div>
                    )}
                  </div>
                </div>
                {/* </SimpleBar> */}
              </div>
              <div className={style.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(packetCount / 10)}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={showIssueRodModal}
        center
        style={customModalStyles}
        onRequestClose={() => {
          setShowIssueRodModal(false);
        }}
      >
        <IssueModal
          packetId={selectedPacket.packetId}
          totalRods={selectedPacket.numberOfRods}
          setShowIssueRodModal={setShowIssueRodModal}
          refetchPacketsData={refetchPacketsData}
          issuedRods={selectedPacket.issuedRod}
        />
      </ReactModal>
    </div>
  );
}

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
};
CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

export default AllPackets;
