import React, { useEffect, useState } from "react";
import style from "./RmMaster.module.css";
import Sidebar from "../../../commonComponent/sidebar/Sidebar";
import searchIcon from "../../../assets/svg/common/serach-icon.svg";
import ColoredButton from "../../../commonComponent/Button/ColoredButton";
import uploadIconWhite from "../../../assets/svg/common/upload_icon_white.svg";
import editIcon from "../../../assets/svg/common/edit-filled.svg";
import deleteIcon from "../../../assets/svg/common/delete-fill.svg";
import ReactModal from "react-modal";
import customModalStyles from "../../../utils/styles/react-modal/reactModalCustomStyle";
import CustomExcelFileDownload from "../../../utils/excelDownload/ExcelDownload";
import { useMutation, useQuery } from "react-query";
import { getRawMaterialList, getToken, handle401Error, rawMaterialUpload, updateRM } from "../../../utils/api/api";
import { showErrorToast, showSuccessToast } from "../../../utils/notify";
import Pagination from "../../../commonComponent/Pagination/Pagination";
import ConfirmPopup from "../../../commonComponent/confirmPopup/ConfirmPopup";
import EditRM from "./editRmMaster/editRM";
import BulkUpload from "../../../commonComponent/BulkUpload/BulkUpload";
import Loader from "../../../commonComponent/CommonLoader/Loader";
import { debounce } from "../../../utils/Debounce/debounce";
import ErrorList from "../../../commonComponent/BulkUpload/errorList/ErrorList";
import downloadIcon from "../../../assets/svg/common/download_icon_white.svg";
import axios from "axios";
function RmMaster() {
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

  const modalCustomStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: 4,
    },
  };
  const modalCustomStyles2 = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height:"500px",
      borderRadius: "16px",
      overflow: "auto",  // Optional: enable scrolling if content overflows
      scrollbarWidth: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: 5,
    },
  };
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [rMasterList, setRMasterList] = useState([]);
  const [rmCount, setRmCount] = useState(0);
  const [uploadModal, setUploadModal] = useState(false);
  const [selectedRm, setSelectedRm] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorModal,setErrorModal] = useState(false);
  const [errors,setErrors] = useState(null);

  // PartMasterList
  const { data, refetch, isLoading } = useQuery(
    ["rMasterList", currentPage,searchQuery],
    () => getRawMaterialList(searchQuery, currentPage, 10),
    {
      retry: false,
      onError: () => {
        showErrorToast("Something went wrong.");
      }
    }
  );
  useEffect(() => {
    setRMasterList(data?.data?.records);
    setRmCount(data?.data?.recordsCount);
  }, [data]);

  // Search
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const handleSearchDebounce = debounce(handleSearch, 300);

  // Excel Upload Mutation
  const rawMasterUploadMutation = useMutation(rawMaterialUpload, {
    onMutate: () => {},
    onSuccess: (res) => {
      setUploadModal(false);
      showSuccessToast(res.message);
      refetch();
    },
    onError: (err) => {
      if(err.response.status === 500){
        showErrorToast("Something went wrong");
        return
      }
      showErrorToast(err?.response?.data?.message || "Something went wrong");
      setErrors(err?.response?.data?.data);
      setErrorModal(true)

    },
  });

  const handleFileUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      rawMasterUploadMutation.mutate(formData);
    } else {
      showErrorToast("No file selected.");
    }
  };
  // Downloading Excel File
  const handleSampleExcelDownload = () => {
    const sheetHeader = [
      "RM SAP Code",
      "RM Description",
      "RM Grade ",
      "RM Dia",
      "RMColor",
    ];
    const sheetData = [
      [
        "901000000454",
        "S.S. BRIGHT BAR 304L DIA 24.00 MM",
        "SS-304 L",
        "24.0",
        "GREEN",
      ],
    ];
    const fileNameLabel = "RM_MasterTemplate";
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };

  // 
  const updateRMMutation = useMutation(updateRM, {
    onMutate: () => {},
    onSuccess: (res) => {
      refetch();
      setEditModal(false);
      showSuccessToast(res.message);
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });

  const handleEdit = (rm) => {
    setSelectedRm(rm);
    setEditModal(true);
  };
  // Bulk Upload
  const handleBulkUpload = () => {
    setUploadModal(true);
  };

  // Delete
  const handleDelete = (rm) => {
    setSelectedRm(rm);
    setShowDeleteModal(true);
  };
  const handleDeleteButton = () => {
    setShowDeleteModal(false);
    let data = {
      id: selectedRm.id,
      active: false,
    };
    updateRMMutation.mutate(data);
  };
  // Downloading Excel File Data
  const getSheetData = async () => {
    const token = getToken();
    let url = `/raw-material?searchKeyword=${searchQuery}&page=${1}&limit=${10}&isPaginationRequired=0`;
    try {
      const response = await axios.get(`${apiKey}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      handle401Error(error);
      throw error;
    }
  };
  const handleRmDataDownload = async () => {
    const data = await getSheetData();
    const records = data?.data;
    const sheetData = records.map((record) => {
      return [
        record.code,
        record.description,
        record.partGrade,
        record.diameter,
        record.color,
      ];
    });
    const sheetHeader = [
      "RM Code",
      "RM Description",
      "RM Grade",
      "RM Diameter",
      "RM Color",
    ];
    const fileNameLabel = "RM Master Data";
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };
  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>RM Master</p>
          <div className={style.downloadBtn}>
            <ColoredButton
              buttonName={"Download"}
              width={"120px"}
              weight={"400"}
              onClick={() => {
                handleRmDataDownload();
              }}
              img={downloadIcon}
            />
          </div>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.filters}>
              <div className={style.inputBox}>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className={style.searchInput}
                  onChange={handleSearchDebounce}
                  placeholder="Search By RM Code / Description"
                  // value={searchQuery}
                />
                <span className={style.imgContainer}>
                  <img src={searchIcon} alt="search-icon" />
                </span>
              </div>
            </div>
            <div className={style.actions}>
              <div>
              </div>

              <ColoredButton
                buttonName="Bulk Upload"
                weight={"400"}
                onClick={() => handleBulkUpload()}
                img={uploadIconWhite}
                width="200px"
              />
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddleP}>
              <div className={style.bodyMiddle}>
                <div className={style.table}>
                  <div className={style.thead}>
                    <div className={style.th}>RM Code</div>
                    <div className={style.th}>RM Description</div>
                    <div className={style.th}>RM Grade</div>
                    <div className={style.th}>RM Diameter</div>
                    <div className={style.th}>RM Color</div>
                    <div className={style.th}>Action</div>
                  </div>
                  <div className={style.tbody}>
                    {rMasterList?.length > 0 ? (
                      rMasterList?.map((rm) => (
                        <div className={style.tr} key={rm.id}>
                          <div className={style.td}>{rm.code}</div>
                          <div className={style.td}>{rm.description}</div>
                          <div className={style.td}>{rm.partGrade}</div>
                          <div className={style.td}>{rm.diameter}</div>
                          <div className={style.td}>
                            {rm.color}
                          </div>
                          <div className={style.td}>
                            <img
                              src={editIcon}
                              alt="edit-img"
                              onClick={() => handleEdit(rm)}
                              onKeyDown={() => handleEdit(rm)}
                            />
                            <img
                              src={deleteIcon}
                              alt="delete-img"
                              onClick={() => handleDelete(rm)}
                              onKeyDown={() => handleDelete(rm)}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={style.noData}>No Data Found</div>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(rmCount / 10)}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        className={style.container}
        isOpen={editModal}
        center
        showCloseIcon={true}
        onRequestClose={() => setEditModal(false)}
        style={customModalStyles}
      >
        <EditRM setEditModal={setEditModal} selectedRm={selectedRm} 
        updateRMMutation={updateRMMutation}
        />
      </ReactModal>
      <ReactModal
        // className={style.previewQR}
        isOpen={uploadModal}
        showCloseIcon={true}
        style={modalCustomStyles}
        onRequestClose={() => {
          setUploadModal(false);
        }}
      >
        <BulkUpload
          handleFileUpload={handleFileUpload}
          handleSampleExcelDownload={handleSampleExcelDownload}
          setUploadModal={setUploadModal}
        />
      </ReactModal>
      <ReactModal
        isOpen={errorModal}
        showCloseIcon={true}
        style={modalCustomStyles2}
        onRequestClose={() => {
          setErrorModal(false);
        }}
      >
        <ErrorList onClose={()=>setErrorModal(false)} errors={errors} />
      </ReactModal>
      <ConfirmPopup
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        icon={deleteIcon}
        title="Delete RM Master"
        statement="Are you sure you want to delete this RM?"
        buttonName1="No"
        buttonName2="Yes"
        handleButton2={handleDeleteButton}
        user={selectedRm}
        setUser={setSelectedRm}
      />
    </div>
  );
}

export default RmMaster;
