import React, { useState } from "react";
import style from "./EditPart.module.css";
import CancelButton from "../../../../commonComponent/Button/CancelButton";
import ColoredButton from "../../../../commonComponent/Button/ColoredButton";
import editIcon from "../../../../assets/svg/common/edit-filled.svg";
import alert from "../../../../assets/svg/common/alert-circle.svg";
import PropTypes from "prop-types";

function EditPart({ setEditModal, selectedPart, updatePartMutation }) {
  const [part, setPart] = useState(selectedPart);
  const [errors, setErrors] = useState({});

  const handleCancel = () => {
    setEditModal(false);
  };
  const handleSave = () => {
    let valid = true;
    if (!part.sapPartNo) {
      setErrors({ ...errors, sapPartNo: "Required" });
      valid = false;
    }
    if (!part.partDescription) {
      setErrors({ ...errors, partDescription: "Required" });
      valid = false;
    }
    if (!part.cuttingLength) {
      setErrors({ ...errors, cuttingLength: "Required" });
      valid = false;
    }
    if (!part.machineId) {
      setErrors({ ...errors, machineId: "Required" });
      valid = false;
    }
    if (!part.perPieceWeightKg) {
      setErrors({ ...errors, perPieceWeightKg: "Required" });
      valid = false;
    }
    if (!part.plantId) {
      setErrors({ ...errors, plantId: "Required" });
      valid = false;
    }
    if (!part.binTareWt) {
      setErrors({ ...errors, binTareWt: "Required" });
      valid = false;
    }

    if (!valid) return;
    updatePartMutation.mutate(part);
  };

  const handleKeyDown = (e) => {
    // Prevent 'e' from being entered
    if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };
  const handleKeyDownInt = (e) => {
    // Prevent 'e' from being entered
    if (
      e.key === "e" ||
      e.key === "E" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "."
    ) {
      e.preventDefault();
    }
  };
  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };
  const handleChange = (e, key) => {
    if(!(e.target.value).trim()) {
      setPart({ ...part, [key]: e.target.value });
      setErrors({ ...errors, [key]: "Required" });
    } 
    else if (key === "plantId") {
      let plantId = e.target.value.replace(/[^0-9.-.]/g, "");
      let newPlantId = parseInt(plantId, 10);
      setPart({ ...part, plantId: newPlantId });
      setErrors({ ...errors, [key]: null });
    }
    else {
      setPart({ ...part, [key]: e.target.value });
      setErrors({ ...errors, [key]: null });
    }
  };
  return (
    <div className={style.editPart}>
      <div className={style.header}>
        <div className={style.titleContainer}>
          <img src={editIcon} alt="icon" />
          <p className={style.title}>Edit Part</p>
        </div>
      </div>
      <div className={style.editables}>
        <div className={style.editable}>
          <label className={style.label}>Part Number</label>
          <div>
            <input
              type="text"
              className={`${style.input} ${
                errors.sapPartNo ? style.errorInput : ""
              }`}
              value={part?.sapPartNo}
              onChange={(e) => handleChange(e, "sapPartNo")}
            />
            {errors.sapPartNo && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.sapPartNo}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Part Description</label>
          <div>
            <input
              type="text"
              className={`${style.input} ${
                errors.partDescription ? style.errorInput : ""
              }`}
              value={part?.partDescription}
              onChange={(e) => handleChange(e, "partDescription")}
            />
            {errors.partDescription && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.partDescription}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Cutting Length</label>
          <div>
          <input
          className={`${style.input} ${
            errors.cuttingLength ? style.errorInput : ""
          }`}
            type="number"
            value={part?.cuttingLength}
            onChange={(e) => handleChange(e, "cuttingLength")}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
          />
          {errors.cuttingLength && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.cuttingLength}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Machine ID</label>
          <div>
          <input
           className={`${style.input} ${
            errors.machineId ? style.errorInput : ""
          }`}
            type="text"
            value={part?.machineId}
            onChange={(e) => handleChange(e, "machineId")}
          />
          {errors.machineId && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.machineId}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Per Piece Weight(Kg)</label>
          <div>
          <input
          className={`${style.input} ${
            errors.perPieceWeightKg ? style.errorInput : ""
          }`}
            type="number"
            value={part?.perPieceWeightKg}
            onKeyDown={handleKeyDown}
            onChange={(e) => handleChange(e, "perPieceWeightKg")}
            onClick={handleInputClick}
          />
          {errors.perPieceWeightKg && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.perPieceWeightKg}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Plant ID</label>
          <div>
          <input
          className={`${style.input} ${
            errors.plantId ? style.errorInput : ""
          }`}
            type="number"
            value={part?.plantId}
            onKeyDown={handleKeyDownInt}
            onChange={(e) => handleChange(e, "plantId")}
            onClick={handleInputClick}
          />
          {errors.plantId && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.plantId}
              </p>
            )}
          </div>
        </div>
        <div className={style.editable}>
          <label className={style.label}>Bin Tare Wt.</label>
          <div>
          <input
          className={`${style.input} ${
            errors.binTareWt ? style.errorInput : ""
          }`}
            type="number"
            value={part?.binTareWt}
            onKeyDown={handleKeyDown}
            onChange={(e) => handleChange(e, "binTareWt")}
            onClick={handleInputClick}
          />
          {errors.binTareWt && (
              <p className={style.error}>
                <img src={alert} alt="alert-icon" /> {errors.binTareWt}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={style.action}>
        <CancelButton buttonName="Cancel" onClick={handleCancel} />
        <ColoredButton buttonName="Save" onClick={handleSave} />
      </div>
    </div>
  );
}
EditPart.propTypes = {
  setEditModal: PropTypes.func.isRequired,
  selectedPart: PropTypes.object.isRequired,
  updatePartMutation: PropTypes.object.isRequired,
};
export default EditPart;
