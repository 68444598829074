import React, { useState, useRef } from "react";
import style from "./BulkUpload.module.css";
import CancelButton from "../../commonComponent/Button/CancelButton";
import ColoredButton from "../../commonComponent/Button/ColoredButton";
import downloadIcon from "../../assets/svg/common/download_icon_blue.svg";
import uploadIcon from "../../assets/svg/common/upload_icon_blue.svg";
import { showErrorToast } from "../../utils/notify";
import crossIcon from "../../assets/svg/common/cross_icon_fileupload.svg";
import PropTypes from "prop-types";

const BulkUpload = ({
  handleFileUpload,
  handleSampleExcelDownload,
  setUploadModal,
}) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      setFile(selectedFile);
    } else {
      showErrorToast("Please upload a valid Excel file.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (
      droppedFile &&
      (droppedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        droppedFile.type === "application/vnd.ms-excel")
    ) {
      setFile(droppedFile);
    } else {
      showErrorToast("Please upload a valid Excel file.");
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    if (file) {
      // Handle file submission logic here
      handleFileUpload(file);
    } else {
      showErrorToast("No file selected.");
    }
  };

  return (
    <div className={style.uploadContainer}>
      <p className={style.title}>Bulk Upload</p>
      <div className={style.body}>
        <div
          className={style.uploadBox}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {!file ? (
            <div>
              <img src={uploadIcon} alt="upload-icon" width="24px" />
              <p className={style.text}>
                Drag & Drop or{" "}
                <span
                  className={style.chooseFile}
                  onClick={handleFileClick}
                  onKeyDown={handleFileClick}
                >
                  Choose file
                </span>{" "}
                to upload
              </p>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".xlsx, .xls"
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <div className={style.fileDetails}>
              <p className={style.fileName}>{file.name}</p>
              <img
                className={style.fileName}
                onClick={() => setFile(null)}
                onKeyDown={() => setFile(null)}
                src={crossIcon}
                alt="cross-icon"
              />
            </div>
          )}
        </div>
        <div className={style.downloadTemplate}>
          <img src={downloadIcon} alt="download-icon" />
          <p
            onClick={handleSampleExcelDownload}
            onKeyDown={handleSampleExcelDownload}
            className={style.downloadTemplate}
          >
            Download Template
          </p>
        </div>
      </div>

      <div className={style.guidelines}>
        <p className={style.gHeading}>Guidelines:</p>
        <p className={style.gStatement}>1. Supported Format: Excel</p>
        <p className={style.gStatement}>
          2. Make sure to use the columns as per format provided in the
          Template.
        </p>
      </div>
      <div className={style.buttons}>
        <CancelButton
          buttonName={"Cancel"}
          onClick={() => {
            setFile(null);
            setUploadModal(false);
          }}
        />
        <ColoredButton buttonName={"Upload"} onClick={handleSubmit} />
      </div>
    </div>
  );
};
BulkUpload.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
  handleSampleExcelDownload: PropTypes.func.isRequired,
  setUploadModal: PropTypes.func.isRequired,
};

export default BulkUpload;
