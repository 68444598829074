import React, { useEffect, useState } from "react";
import Sidebar from "../../commonComponent/sidebar/Sidebar";
import searchIcon from "../../assets/svg/common/serach-icon.svg";
import style from "./RodInventory.module.css";
import { getPartGrade, getRmSize,getToken, handle401Error,getRodInventoryNew} from "../../utils/api/api";
import { useQuery } from "react-query";
import Pagination from "../../commonComponent/Pagination/Pagination";
import ReactDatePicker from "react-datepicker";
import { customStyleSearchSelect } from "../../utils/styles/react-select/customSelect";
import Select,{components} from "react-select";
import Loader from "../../commonComponent/CommonLoader/Loader";
import moment from "moment";
import { debounce } from "../../utils/Debounce/debounce";
import PropTypes from "prop-types";
import { showErrorToast } from "../../utils/notify";
import ColoredButton from "../../commonComponent/Button/ColoredButton";
import downloadIcon from "../../assets/svg/common/download_icon_white.svg";
import axios from "axios";
import CustomExcelFileDownload from "../../utils/excelDownload/ExcelDownload";
const CustomInput = React.forwardRef(
  ({ value, onClick, placeholderText }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      placeholder={placeholderText}
    />
  )
);
const CustomDropdownIndicator = (props) => {
  if (props.selectProps.value) {
    return null;
  }
  return <components.DropdownIndicator {...props} />;
};



function RodInventory() {
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;

  const [rodsList, setRodsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rodsCount, setRodsCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState({
    minDate: null,
    maxDate: null,
  });
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [rmSizeOptions, setRmSizeOptions] = useState([]);
  const [selectedPartGrade,setSelectedPartGrade] = useState(null);
  const [selectedRmSize,setSelectedRmSize] = useState(null);
  const [filter, setFilter] = useState({
    searchKeyword:"",
    size:"",
    partGrade:"",
    createdOnTo:null,
    createdOnFrom:null,
    paginationCriteria: {
      limit: 10,
      page: currentPage,
      isPaginationRequired: true,
    },
  });
  const { data: partGradeResponse, status:partGradeStatus } = useQuery("part-grade", getPartGrade);
  const {data:rmSizeResponse,status:rmSizeStatus} = useQuery("rm-size",getRmSize);
  const {data:rodsData,isLoading} = useQuery(["rodInventory",filter,currentPage],()=>getRodInventoryNew(currentPage,10,filter?.searchKeyword||"",filter?.size||"",filter?.partGrade||"",filter?.createdOnTo||"",filter?.createdOnFrom||""),{
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => {
      showErrorToast("Something went wrong.");
    },
  });
  
  useEffect(() => {
    if (partGradeStatus === "success" && partGradeResponse?.data) {
      const transformedOptions = partGradeResponse.data.map(grade => ({
        value: grade,
        label: grade
      }));
      setPartGradeOptions(transformedOptions);
    }
  }, [partGradeResponse, partGradeStatus]);
  useEffect(() => {
    if (rmSizeStatus === "success" && rmSizeResponse?.data) {
      const transformedOptions = rmSizeResponse.data.map(size => ({
        value: size,
        label: size
      }));
      setRmSizeOptions(transformedOptions);
    }
  }, [rmSizeResponse, rmSizeStatus]);


  useEffect(() => {
    if (rodsData?.data?.records) {
      setRodsList(rodsData.data.records);
      setRodsCount(rodsData.data.recordsCount);
      
    }
  }, [rodsData]);

  const handleSearch = (e) => {
    if(e.target.value){
      setFilter((prev) => ({
        ...prev,
        searchKeyword: e.target.value,
      }));
      setCurrentPage(1);
    }
    else{
      setFilter((prev) => ({
        ...prev,
        searchKeyword: "",
      }));
      setCurrentPage(1);
    }
  };
  const handleSearchDebounce = debounce(handleSearch, 500)

  // Handling Date Changes
  const handleFromDateChanges = (date) => {
    setStartDate(date);
    setDate((prev) => ({ ...prev, minDate: date }));
    setFilter((prev) => ({
      ...prev,
      createdOnFrom: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null,
      
    }));
    setCurrentPage(1);
  };
  const handleToDateChange = (date) => {
    setEndDate(date);
    setDate((prev) => ({ ...prev, maxDate: date }));
    setFilter((prev) => ({
      ...prev,
      createdOnTo: date ? moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss") : null,
    }));
    setCurrentPage(1);
  };

  // Downloading Excel File
  const getSheetData = async () => {
    const token = getToken();
    let url = `/rod-inventory?page=1&limit=10&rodId=${filter?.searchKeyword}&isPaginationRequired=0`;
    try {
      const response = await axios.get(`${apiKey}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      handle401Error(error);
      throw error;
    }
  };
  const handleRodInventoryDownload = async () => {
    const data = await getSheetData();
    const records = data?.data;
    const sheetData = records.map((record) => {
      return [
        record.rodId08,
        record.recNo,
        record.rmCode01,
        record.gateEntryNo02,
        record.gateEntryDt03?moment(record.gateEntryDt03).format("DD/MM/YYYY"):"",
        record.heatNo04,
        record.supplier05,
        record.invoiceNo06,
        record.bundleId07,
        record.rmGrade09,
        record.rmSize10,
        record.partNo11,
        record.partDescription12,
        record.cuttingLength13,
        record.machineId14,
        record.netWeight15,
        record.plantId16,
        record.binTareWt17,
        record.totalWeight18,
        record.quantity19,
        record.mno,
      ];
    });
    const sheetHeader = [
      "Rod Id",
      "Rec. No.",
      "RM Code",
      "GE Number",
      "Gate Entry Date",
      "Heat No.",
      "Supplier Name",
      "Invoice No.",
      "Bundle Id",
      "RM Grade",
      "RM Size",
      "Part No.",
      "Part Description",
      "Cutting Length",
      "Machine Id",
      "Net Weight",
      "Plant Id",
      "Bin Tare Wt.",
      "Total Weight",
      "Quantity",
      "M No.",
    ];
    const fileNameLabel = "RM Inventory Data";
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };
  
  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>RM Inventory</p>
          <div className={style.downloadBtn}>
            <ColoredButton
              buttonName={"Download"}
              width={"120px"}
              weight={"400"}
              onClick={() => {
                handleRodInventoryDownload();
              }}
              img={downloadIcon}
            />
          </div>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.filters}>
              <div className={style.inputBox}>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className={style.searchInput}
                  onChange={handleSearchDebounce}
                  placeholder="Search By Rod Id"
                />
                <span className={style.imgContainer}>
                  <img src={searchIcon} alt="search-icon" />
                </span>
              </div>
              <Select
              value={selectedPartGrade}
              onChange={(selectedOption) => {
                setSelectedPartGrade(selectedOption);
                setFilter((prev) => ({
                  ...prev,
                  partGrade: selectedOption?.value,
                }));
                setCurrentPage(1);
              }
            }
                options={partGradeOptions}
                placeholder="RM Grade"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <Select
              value={selectedRmSize}
              onChange={(selectedOption) => {
                setSelectedRmSize(selectedOption);
                setFilter((prev) => ({
                  ...prev,
                  size: selectedOption?.value,
                }));
                setCurrentPage(1);
              }
            }
                options={rmSizeOptions}
                placeholder="RM Size"
                styles={customStyleSearchSelect}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <div className={style.dateRangeFilter}>
                <div className="custom-date-picker">
                  <ReactDatePicker
                    selected={startDate}
                    onChange={handleFromDateChanges}
                    dateFormat="dd/MM/yyyy"
                    maxDate={date?.maxDate ? date?.maxDate : new Date()}
                    customInput={
                      <CustomInput placeholderText="Select Start Date" />
                    }
                    isClearable
                  />
                </div>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#6c757d",
                  }}
                >
                  To
                </span>
                <div className="custom-date-picker">
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleToDateChange}
                    dateFormat="dd/MM/yyyy"
                    minDate={date?.minDate}
                    maxDate={new Date()}
                    customInput={
                      <CustomInput placeholderText="Select End Date" />
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddleP}>
          <div className={style.bodyMiddle}>
              <div className={style.table}>
                <div className={style.thead}>
                  <div className={style.thr}>
                    <div className={style.th} style={{width:"5rem"}}>Rod Id</div>
                    <div className={style.th} style={{width:"5rem"}}>Rec. No.</div>
                    <div className={style.th} style={{width:"7rem"}}>RM Code</div>
                    <div className={style.th} style={{width:"6rem"}}>GE Number</div>
                    <div className={style.th} style={{width:"7rem"}}>Gate Entry Date</div>
                    <div className={style.th} style={{width:"5rem"}}>Heat No.</div>
                    <div className={style.th} style={{width:"14rem"}}>Supplier Name</div>
                    <div className={style.th} style={{width:"9rem"}}>Invoice No.</div>
                    <div className={style.th} style={{width:"4rem"}}>Bundle Id</div>
                    <div className={style.th} style={{width:"7rem"}}>RM Grade</div>
                    <div className={style.th} style={{width:"5rem"}}>RM Size</div>
                    <div className={style.th} style={{width:"13rem"}}>Part No.</div>
                    <div className={style.th} style={{width:"22rem"}}>Part Description</div>
                    <div className={style.th} style={{width:"4rem"}}>Cutting Length</div>
                    <div className={style.th} style={{width:"6rem"}}>Machine Id</div>
                    <div className={style.th} style={{width:"4rem"}}>Net Weight</div>
                    <div className={style.th} style={{width:"4rem"}}>Plant Id</div>
                    <div className={style.th} style={{width:"4rem"}}>Bin Tare Wt.</div>
                    <div className={style.th} style={{width:"4rem"}}>Total Weight</div>
                    <div className={style.th} style={{width:"4rem"}}>Quantity</div>
                    <div className={style.th} style={{width:"4rem"}}>M No.</div>
                  </div>
                </div>
                  <div className={style.tbody}>
                    {rodsList?.length > 0 ? (
                      rodsList?.map((rod) => {
                        return(
                        <div className={style.tr} key={rod.rodId}>
                          <div className={style.td} style={{width:"5rem"}}>{rod.rodId08}</div>
                          <div className={style.td} style={{width:"5rem"}}>{rod.recNo}</div>
                          <div className={style.td} style={{width:"7rem"}}>{rod.rmCode01}</div>
                          <div className={style.td} style={{width:"6rem"}}>{rod.gateEntryNo02}</div>
                          <div className={style.td} style={{width:"7rem"}}>
                            {rod.gateEntryDt03 ? moment(rod.gateEntryDate).format("DD/MM/YYYY") : ""}
                          </div>
                          <div className={style.td} style={{width:"5rem"}}>{rod.heatNo04}</div>
                          <div className={style.td} style={{width:"14rem"}}>{rod.supplier05}</div>
                          <div className={style.td} style={{width:"9rem"}}>{rod.invoiceNo06}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.bundleId07}</div>
                          <div className={style.td} style={{width:"7rem"}}>{rod.rmGrade09}</div>
                          <div className={style.td} style={{width:"5rem"}}>{rod.rmSize10}</div>
                          <div className={style.td} style={{width:"13rem"}}>{rod.partNo11}</div>
                          <div className={style.td} style={{width:"22rem"}}>{rod.partDescription12}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.cuttingLength13}</div>
                          <div className={style.td} style={{width:"6rem"}}>{rod.machineId14}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.netWeight15}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.plantId16}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.binTareWt17}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.totalWeight18}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.quantity19}</div>
                          <div className={style.td} style={{width:"4rem"}}>{rod.mno}</div>
                        </div>
                      )})
                    ) : (
                      <div className={style.noUser}>No Data Found</div>
                    )}
                  </div>
              </div>
              </div>
              <div className={style.pagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(rodsCount / 10)}
                setCurrentPage={setCurrentPage}
              />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
};
CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

export default RodInventory;
