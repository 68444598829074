function convertTime(time) {
    // Create a new Date object with the current date and the provided time
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds.split('.')[0]); // Remove milliseconds part
  
    // Format the time
    let hours12 = date.getHours();
    const minutesFormatted = date.getMinutes().toString().padStart(2, '0');
    const period = hours12 >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours12 = hours12 % 12 || 12;
  
    return `${hours12}:${minutesFormatted} ${period}`;
  }
  export default convertTime;
  