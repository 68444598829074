import React, { useEffect, useState } from "react";
import style from "./statusSelect.module.css";
import alert from "../../../../assets/svg/common/alert2.svg";
import { getRodStatuses } from "../../../../utils/api/api";
import { useQuery } from "react-query";
import PropTypes from "prop-types";

function StatusSelect({
  currentStatus,
  onStatusChange,
  setShowRejectionComment,
  setRejectionComment,
  comment,
  rodsCount,
}) {
  const [statusOptions, setStatusOptions] = useState([]);
  const { data: rodStatuses } = useQuery(["rodStatuses"], () =>
    getRodStatuses()
  );
  function convertResponseToOptions(response) {
    // Split the response string by commas
    const items = response.split(",");

    // Map each item to an object with value and label keys
    const options = items.map((item) => ({
      value: item,
      label: item,
    }));

    return options;
  }
  useEffect(() => {
    if (rodStatuses) {
      setStatusOptions(convertResponseToOptions(rodStatuses.data));
    }
  }, [rodStatuses]);
  const handleRejected = () => {
    setRejectionComment(comment);
    setShowRejectionComment(true);
  };
  if (currentStatus === "QA") {
    if (rodsCount > 1) {
      return <span className={style.statusPending}>{currentStatus}</span>;
    } else if (rodsCount === 1) {
      return (
        <select
          value={currentStatus}
          onChange={(e) => onStatusChange(e.target.value)}
          className={style.select}
        >
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }
  } else if (currentStatus === "ACCEPTED") {
    return <span className={style.statusApproved}>{currentStatus}</span>;
  } else if (currentStatus === "REJECTED") {
    return (
      <div
        className={style.statusRejected}
        onClick={handleRejected}
        onKeyDown={handleRejected}
      >
        <img src={alert} alt="alert-img" />
        {currentStatus}
      </div>
    );
  } else {
    return <span className={style.statusPending}>{currentStatus}</span>;
  }
}

StatusSelect.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  setShowRejectionComment: PropTypes.func.isRequired,
  setRejectionComment: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  rodsCount: PropTypes.number.isRequired,
};

export default StatusSelect;
