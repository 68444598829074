import React, { useEffect, useState } from "react";
import Sidebar from "../../commonComponent/sidebar/Sidebar";
import searchIcon from "../../assets/svg/common/serach-icon.svg";
import style from "./IssuedRods.module.css";
import qrSearchIcon from "../../assets/svg/common/qr-search-icon.svg";
import ReactModal from "react-modal";
import {
  getPartGrade,
  getRmSize,
  getRodList,
  getRodStatuses,
  getToken,
  handle401Error,
  updateRodStatus,
} from "../../utils/api/api";
import { useMutation, useQuery } from "react-query";
import { formatColumnDate } from "../../utils/functions/utils";
import customModalStyles from "../../utils/styles/react-modal/reactModalCustomStyle";
import RejectionComment from "./Rejection/RejectionComment";
import { showErrorToast, showSuccessToast } from "../../utils/notify";
import StatusSelect from "./Rejection/statusSelect/statusSelect";
import ViewRejectionComment from "./Rejection/ViewRejectionComment";
import approveStyle from "./approve.module.css";
import Pagination from "../../commonComponent/Pagination/Pagination";
import ReactDatePicker from "react-datepicker";
import { customStyleSearchSelect } from "../../utils/styles/react-select/customSelect";
import Select, { components } from "react-select";
import downloadIcon from "../../assets/svg/common/download_icon_white.svg";
import ColoredButton from "../../commonComponent/Button/ColoredButton";
import Loader from "../../commonComponent/CommonLoader/Loader";
import moment from "moment";
import { debounce } from "../../utils/Debounce/debounce";
import axios from "axios";
import CustomExcelFileDownload from "../../utils/excelDownload/ExcelDownload";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import PreviewQR2 from "../../commonComponent/previewQR/previewQR2";


const CustomInput = React.forwardRef(
  ({ value, onClick, placeholderText }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      placeholder={placeholderText}
    />
  )
);

const CustomDropdownIndicator = (props) => {
  if (props.selectProps.value) {
    return null;
  }
  return <components.DropdownIndicator {...props} />;
};


function IssuedRods2() {
  const apiKey = process.env.REACT_APP_VICTORA_APP_URL;
  const navigate = useNavigate();

  const customStyleFilter = {
    ...customStyleSearchSelect,
    control: (provided, state) => ({
      ...customStyleSearchSelect.control(provided, state), // Copy existing styles
      width: "130px", // Overwrite the width
    }),
  };
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "16px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(5px)", // Adjust the blur level here
      zIndex: 4,
    },
  };
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showRejectionComment, setShowRejectionComment] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [rodsList, setRodsList] = useState([]);
  const [selectedRodId, setSelectedRodId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [rejectionComment, setRejectionComment] = useState("");
  const [viewQRModal, setViewQRModal] = useState(false);
  const [currentQR, setCurrentQR] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rodsCount, setRodsCount] = useState(0);
  const [qrData, setQrData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sendDate, setSendDate] = useState({
    createdOnFrom: null,
    createdOnTo: null,
  });
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [date, setDate] = useState({
    minDate: null,
    maxDate: null,
  });
  const [statusOptions, setStatusOptions] = useState([]);
  const [partGradeOptions, setPartGradeOptions] = useState([]);
  const [rmSizeOptions, setRmSizeOptions] = useState([]);
  const [selectedPartGrade, setSelectedPartGrade] = useState(null);
  const [selectedRmSize, setSelectedRmSize] = useState(null);

  const {
    data: rodsData,
    refetch: refetchRodsData,
    isLoading,
  } = useQuery(
    [
      "rodList",
      searchQuery,
      currentPage,
      sendDate,
      selectedStatus,
      selectedPartGrade,
      selectedRmSize,
    ],
    () =>
      getRodList(
        searchQuery,
        currentPage,
        10,
        selectedPartGrade?.value,
        selectedRmSize?.value,
        selectedStatus?.value,
        sendDate?.createdOnFrom,
        sendDate?.createdOnTo
      ),
      {
        retry:false,
        onError:()=>{
          showErrorToast("Something went wrong.");
        }
      }
  );

  const { data: rodStatuses } = useQuery(["rodStatuses"], () =>
    getRodStatuses()
  );
  const { data: partGradeResponse, status: partGradeStatus } = useQuery(
    "part-grade",
    getPartGrade
  );
  const { data: rmSizeResponse, status: rmSizeStatus } = useQuery(
    "rm-size",
    getRmSize
  );
  function convertResponseToOptions(response) {
    // Split the response string by commas
    const items = response.split(",");

    // Map each item to an object with value and label keys
    const options = items.map((item) => ({
      value: item,
      label: item,
    }));

    return options;
  }
  useEffect(() => {
    if (rodStatuses) {
      setStatusOptions(convertResponseToOptions(rodStatuses.data));
    }
  }, [rodStatuses]);
  useEffect(() => {
    if (partGradeStatus === "success" && partGradeResponse?.data) {
      const transformedOptions = partGradeResponse.data.map((part) => ({
        value: part,
        label: part,
      }));
      setPartGradeOptions(transformedOptions);
    }
  }, [partGradeResponse, partGradeStatus]);
  useEffect(() => {
    if (rmSizeStatus === "success" && rmSizeResponse?.data) {
      const transformedOptions = rmSizeResponse.data.map((rmSize) => ({
        value: rmSize,
        label: rmSize,
      }));
      setRmSizeOptions(transformedOptions);
    }
  }, [rmSizeResponse, rmSizeStatus]);

  useEffect(() => {
    if (rodsData?.data?.records) {
      setRodsList(rodsData.data.records);
      setRodsCount(rodsData.data.recordsCount);
    }
  }, [rodsData]);

  const handleStatusChange = (newStatus, rodId) => {
    setSelectedRodId(rodId);
    if (newStatus === "REJECTED") {
      setShowRejectionModal(true);
    } else if (newStatus === "ACCEPTED") {
      setShowApprovalModal(true);
    }
    else{
      showErrorToast("Invalid Status!");
    }
  };

  const updateRodStatusMutation = useMutation(updateRodStatus, {
    onSuccess: (res) => {
      showSuccessToast(res.message);
      refetchRodsData();
      setShowRejectionModal(false);
      setShowApprovalModal(false);
      setSelectedRodId("");
    },
    onError: (err) => {
      showErrorToast(err?.response?.data?.message || "Something went wrong");
    },
  });

  const handleSearch = (e) => {
    if (e.target.value) {
      const str = e.target.value;
      const values = str.split(";");
      setSearchQuery(values[7]||str);
    } else {
      setSearchQuery("");
    }
  };
  const handleSearchDebounce = debounce(handleSearch, 500);

  const onSubmitComment = (comment) => {
    updateRodStatusMutation.mutate({
      rodId: selectedRodId,
      status: "REJECTED",
      remarks: comment,
    });
  };
  const handleApproval = () => {
    updateRodStatusMutation.mutate({
      rodId: selectedRodId,
      status: "ACCEPTED",
    });
  };
  const handleQR = (rod) => {
    let qrDetails = [
      {
        "Rod ID": rod.rodId,
        "RM. Grade": rod.partGrade,
        "RM. Size": rod.size,
      },
    ];
    setQrData(qrDetails);
    setCurrentQR(rod.qrLink);

    setViewQRModal(true);
  };
  // Handling Date Changes
  const handleFromDateChanges = (date) => {
    setFromDate(date);
    setDate((prev) => ({ ...prev, minDate: date }));
    setCurrentPage(1);
    setSendDate((prev) => ({
      ...prev,
      createdOnFrom: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null,
    }));
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    setDate((prev) => ({ ...prev, maxDate: date }));
    setCurrentPage(1);
    setSendDate((prev) => ({
      ...prev,
      createdOnTo: date
        ? moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
        : null,
    }));
  };
  // Downloading Excel File
  const getSheetData = async () => {
    const token = getToken();
    let url = `/rod-list?searchKeyword=${searchQuery}&page=${1}&limit=${0}&isPaginationRequired=0&partGrade=${
      selectedPartGrade ? selectedPartGrade.value : ""
    }&size=${selectedRmSize ? selectedRmSize.value : ""}&status=${
      selectedStatus ? selectedStatus.value : ""
    }&createdOnFrom=${
      fromDate ? moment(fromDate).format("YYYY-MM-DDTHH:mm:ss") : ""
    }&createdOnTo=${
      toDate ? moment(toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss") : ""
    }`;
    try {
      const response = await axios.get(`${apiKey}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      handle401Error(error);
      throw error;
    }
  };
  const handleIssuedRodsDownload = async () => {
    const data = await getSheetData();
    const records = data?.data?.records;
    const sheetData = records.map((record) => {
      return [
        record.rodId,
        record.gateEntryId,
        formatColumnDate(record.gateEntryDate),
        record.partGrade,
        record.size,
        record.rmCode,
        record.heatNumber,
        record.supplierName,
        record.invoiceNumber,
        formatColumnDate(record.updatedOn),
        record.isConsumed?"Consumed":"Not Consumed",
        record.approvalStatus,
      ];
    });
    const sheetHeader = [
      "Rod Id",
      "GE No.",
      "GE Date",
      "RM Grade",
      "RM Size",
      // "RM Description",
      "RM Code",
      // "Color",
      "Heat No.",
      "Supplier Name",
      "Invoice No.",
      "Updated On",
      "Consumption Status",
      "Status",
    ];
    const fileNameLabel = "Issued Rod Data";
    CustomExcelFileDownload(sheetHeader, sheetData, fileNameLabel);
  };

  return (
    <div className={style.section}>
      <Sidebar />
      <div className={style.rightSection}>
        <div className={style.header}>
          <p className={style.headerHeading}>Issued Rods</p>
          <div className={style.downloadBtn}>
            <ColoredButton
              buttonName={"Download"}
              width={"120px"}
              weight={"400"}
              onClick={() => {
                handleIssuedRodsDownload();
              }}
              img={downloadIcon}
            />
          </div>
        </div>
        <div className={style.body}>
          <div className={style.bodyHeader}>
            <div className={style.filters}>
              <div className={style.inputBox}>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className={style.searchInput}
                  onChange={handleSearchDebounce}
                  placeholder="Scan a rod to get started"
                  // value={searchQuery}
                />
                <span className={style.imgContainer}>
                  <img src={qrSearchIcon} alt="qr-search-icon" />
                  <img src={searchIcon} alt="search-icon" />
                </span>
              </div>
              <Select
                value={selectedPartGrade}
                onChange={(selectedOption) => {
                  setSelectedPartGrade(selectedOption);
                  setCurrentPage(1);
                }}
                options={partGradeOptions}
                placeholder="RM Grade"
                styles={customStyleFilter}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <Select
                value={selectedRmSize}
                onChange={(selectedOption) => {
                  setSelectedRmSize(selectedOption);
                  setCurrentPage(1);
                }}
                options={rmSizeOptions}
                placeholder="RM Size"
                styles={customStyleFilter}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <Select
                value={selectedStatus}
                onChange={(selectedOption) => {
                  setSelectedStatus(selectedOption);
                  setCurrentPage(1);
                }}
                options={statusOptions}
                placeholder="Status"
                styles={customStyleFilter}
                isClearable
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPosition="absolute"
              />
              <div className={style.dateRange}>
                <div className="custom-date-picker">
                  <ReactDatePicker
                    selected={fromDate}
                    onChange={handleFromDateChanges}
                    dateFormat="dd/MM/yyyy"
                    maxDate={date?.maxDate ? date?.maxDate : new Date()}
                    customInput={
                      <CustomInput placeholderText="Select Start Date" />
                    }
                    isClearable
                  />
                </div>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#6c757d",
                  }}
                >
                  To
                </span>
                <div className="custom-date-picker">
                  <ReactDatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    dateFormat="dd/MM/yyyy"
                    minDate={date?.minDate}
                    maxDate={new Date()}
                    customInput={
                      <CustomInput placeholderText="Select End Date" />
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className={style.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <div className={style.bodyMiddleP}>
              <div className={style.bodyMiddle}>
                <div className={style.table}>
                  <div className={style.thead}>
                    <div className={style.thr}>
                      <div className={style.th} style={{ width: "5rem" }}>
                        Rod ID
                      </div>
                      <div className={style.th} style={{ width: "5rem" }}>
                        GE No.
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        Gate Entry Date
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Grade
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Size
                      </div>
                      <div className={style.th} style={{ width: "7rem" }}>
                        RM Code
                      </div>
                      <div className={style.th} style={{ width: "5rem" }}>
                        Heat No.
                      </div>
                      <div className={style.th} style={{ width: "14rem" }}>
                        Supplier Name
                      </div>
                      <div className={style.th} style={{ width: "9rem" }}>
                        Invoice No.
                      </div>
                      <div className={style.th} style={{ width: "2rem" }}>
                        QR
                      </div>
                      <div className={style.th} style={{ width: "6rem" }}>
                        Updated On
                      </div>
                      <div className={style.th} style={{ width: "6rem" }}>
                        Consumption Status
                      </div>
                      <div className={style.th}>Status</div>
                    </div>
                  </div>
                  <div className={style.tbody}>
                    {rodsList?.length > 0 ? (
                      rodsList?.map((rod) => {
                        return (
                          <div className={style.tr} key={rod.rodId}>
                            <div className={style.td} style={{ width: "5rem" }}>
                              {rod.rodId}
                            </div>
                            <div className={style.td} style={{ width: "5rem" }}>
                              {rod.gateEntryId}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {formatColumnDate(rod.gateEntryDate)}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.partGrade}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.size}
                            </div>
                            <div className={style.td} style={{ width: "7rem" }}>
                              {rod.rmCode}
                            </div>
                            <div className={style.td} style={{ width: "5rem" }}>
                              {rod.heatNumber}
                            </div>
                            <div
                              className={style.td}
                              style={{ width: "14rem" }}
                            >
                              {rod.supplierName}
                            </div>
                            <div className={style.td} style={{ width: "9rem" }}>
                              {rod.invoiceNumber}
                            </div>
                            <div
                              className={style.td}
                              style={{ cursor: "pointer", width: "2rem" }}
                              onClick={() => handleQR(rod)}
                              onKeyDown={() => handleQR(rod)}
                            >
                              <img src={qrSearchIcon} alt="qr-search-icon" />
                            </div>
                            <div className={style.td} style={{ width: "6rem" }}>
                              {rod.updatedOn
                                ? moment(rod.updatedOn).format("DD/MM/YYYY")
                                : null}
                            </div>
                            <div
                              className={style.td}
                              style={{ width: "6rem", cursor: "pointer" }}
                              onClick={() => {
                                navigate("/rm-inventory");
                              }}
                              onKeyDown={() => {
                                navigate("/rm-inventory");
                              }}
                            >
                              {rod.isConsumed?"Consumed":"Not Consumed"}
                            </div>
                            <div className={style.td}>
                              <StatusSelect
                                currentStatus={rod.approvalStatus}
                                onStatusChange={(newStatus) =>
                                  handleStatusChange(newStatus, rod.rodId)
                                }
                                setShowRejectionComment={
                                  setShowRejectionComment
                                }
                                comment={rod.reason}
                                setRejectionComment={setRejectionComment}
                                setShowApprovalModal={setShowApprovalModal}
                                rodsCount={rodsCount}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={style.noUser}>No Data Found</div>
                    )}
                  </div>
                  {/* </SimpleBar> */}
                </div>
              </div>
              <div className={style.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(rodsCount / 10)}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={showRejectionModal}
        center
        style={customModalStyles}
        onRequestClose={() => setShowRejectionModal(false)}
      >
        <RejectionComment
          setShowRejectionModal={setShowRejectionModal}
          onSubmit={onSubmitComment}
        />
      </ReactModal>
      <ReactModal
        isOpen={showRejectionComment}
        center
        style={customModalStyles}
        onRequestClose={() => setShowRejectionComment(false)}
      >
        <ViewRejectionComment
          setShowRejectionComment={setShowRejectionComment}
          comment={rejectionComment}
        />
      </ReactModal>
      <ReactModal
        isOpen={showApprovalModal}
        center
        style={customModalStyles}
        onRequestClose={() => setShowApprovalModal(false)}
      >
        <div className={approveStyle.container}>
          <div className={approveStyle.titleContainer}>
            <p className={approveStyle.title}>Accept ?</p>
          </div>
          <div>
            <p className={approveStyle.confirmText}>Do you want to Accept?</p>
          </div>
          <div className={approveStyle.buttons}>
            <button
              className={approveStyle.button1}
              onClick={() => setShowApprovalModal(false)}
            >
              Cancel
            </button>
            <button className={approveStyle.button2} onClick={handleApproval}>
              Confirm
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        // className={style.previewQR}
        isOpen={viewQRModal}
        showCloseIcon={true}
        style={customStyles}
        onRequestClose={() => {
          setViewQRModal(false);
        }}
      >
        <PreviewQR2
          setViewQRModal={setViewQRModal}
          qrLink={currentQR}
          fileName="rodQR.png"
          qrData={qrData}
          qrType={"rod"}
        />
      </ReactModal>
    </div>
  );
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeholderText: PropTypes.string,
};
CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

export default IssuedRods2;
